import {useCallback, useContext, useEffect, useRef, useState} from "react";
import {OrganisationContext, UserContext} from "../../App";
import {Alumni, AlumniConversation, AlumniConvoUser, auth, capitalise, convertDate, executeCallable, getMostRecentAlumniMessage, getUniqueId, InstituteData, PRIMARY_COLOUR, QueryObject, SchoolData, useAlumniPaginator} from "placementt-core";
import Card from "../../Components/Card";
import {Avatar, Breadcrumbs, Button, CardContent, CardHeader, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Typography, Stack, Grid, Box, ListItemButton, Divider, Skeleton, FormGroup, FormControlLabel, Checkbox, Alert} from "@mui/material";
import {Popup} from "../../Components/Popup";
import {ClickableCard} from "../../Util/styledComponents";
import {ArrowForward, Edit} from "@mui/icons-material";
import Page from "../../Components/Page";
import {Link, Route, Routes, useNavigate, useParams} from "react-router-dom";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import Form from "../../Components/Form";
import ControlledSwitch from "../../Components/FormComponents/ControlledSwitch/ControlledSwitch";
import IconButtonPop from "../../Components/IconButtonPop";
import {where} from "firebase/firestore";
import TutorialPage from "../Shared/TutorialPage";
import QRCodeGenerator from "../../Components/QRCode";
import FilterList from "../../Components/FilterList";
import FadeInBox from "../../Components/FadeInBox";
import Preloader from "../../Components/Preloader";
import {signInWithCustomToken} from "firebase/auth";
import chats from "../../Images/chats.png";
import DataViewer from "../../Components/DataViewer";
import {MessageBox} from "../../Components/MessageBox";
import {LoadingButton} from "../../Components/LoadingButton";
import InputGroup from "../../Components/FormComponents/InputGroup";

export default function AlumniConversations() {
    const [editConversationsPopup, setEditConversationsPopup] = useState<boolean>(false);
    const [conversationCount, setConversationCount] = useState<number>();

    const navigate = useNavigate();
    const org = useContext(OrganisationContext);
    const institute = org.details as InstituteData;
    const user = useContext(UserContext);
    const schools = org.schools as {[key: string]: SchoolData}|undefined;
    const firebaseQuery = new FirebaseQuery();

    useEffect(() => {
        if (!institute) return;
        firebaseQuery.getCount(["alumniConversations"], [where("oId", "==", user.oId)]).then(setConversationCount);
    }, []);

    if (!institute || (institute.package === "institutes-two" && !schools)) return null;

    const hasToggledConversationsBefore =
        (institute.package === "institutes-one" && institute.alumniConversations !== undefined) ||
        (institute.package === "institutes-two" && Object.values(schools || {}).filter((s) => s.alumniConversations !== undefined).length);


    return (
        <>
            {
                hasToggledConversationsBefore ? <Card title={"Conversations"} secondaryTitle={<IconButtonPop responsive={false} title="Edit schools" onClick={() => setEditConversationsPopup(true)}><Edit/></IconButtonPop>} grid>
                    <Typography>{conversationCount} conversation{(conversationCount && conversationCount > 1) ? "s are" : " is"} currently in progress.</Typography>
                    <Button sx={{float: "right", mt: 2}} endIcon={<ArrowForward/>} onClick={() => navigate("conversations")}>Go to Conversations</Button>
                </Card> : <ClickableCard onClick={() => setEditConversationsPopup(true)} sx={{"background-image": "linear-gradient( 150deg, hsl(265deg 96% 38%) 0%, hsl(259deg 88% 44%) 17%, hsl(261deg 85% 47%) 34%, hsl(269deg 84% 47%) 50%, hsl(287deg 86% 51%) 67%, hsl(293deg 94% 53%) 83%, hsl(291deg 100% 50%) 100% )"}}>
                    <CardHeader sx={{color: "white !important"}} title={"Alumni Conversations"}/>
                    <CardContent>
                        <Typography sx={{color: "white !important"}}>Let your students ask your alumni questions.</Typography>
                        <Button sx={{float: "right"}} color="white" endIcon={<ArrowForward/>} onClick={() => setEditConversationsPopup(true)}>Get Started</Button>
                    </CardContent>
                </ClickableCard>}
            <ConversationsPopup
                open={editConversationsPopup}
                onClose={() => setEditConversationsPopup(false)}/>
        </>

    );
}

function ConversationsPopup({open, onClose}:{open: boolean, onClose: () => void}) {
    const firebaseQuery = new FirebaseQuery();
    const navigate = useNavigate();

    const user = useContext(UserContext);
    const schools = useContext(OrganisationContext).schools as {[key: string]: SchoolData};
    const institute = useContext(OrganisationContext).details as InstituteData;
    const [awaitingAlumniCounts, setAwaitingAlumniCounts] = useState<{[key: string]: number}|number>();
    const [editSchool, setEditSchool] = useState<string>();

    const schoolsAlumniConvoEnabled = institute.package === "institutes-two" ? Object.entries(schools || {}).filter(([, school]) => school.alumniConversations) : (institute.alumniConversations || false);


    useEffect(() => {
        if (institute.package === "institutes-one") {
            firebaseQuery.getCount("alumni", [where("oId", "==", user.oId), where("enableConversations", "==", true)]).then(setAwaitingAlumniCounts);
            return;
        }
        Promise.all(Object.keys(schools || {}).map(async (schId) => {
            const count = await firebaseQuery.getCount("alumni", [where("oId", "==", user.oId), where("schoolId", "==", schId), where("enableConversations", "==", true)]);
            return [schId, count];
        })).then((e) => setAwaitingAlumniCounts(Object.fromEntries(e)));
    }, [schools, institute]);

    const toggleAlumniConversation = async (e: {institute: boolean}|{[school: string]: boolean}) => {
        if (!editSchool) {
            await firebaseQuery.update(["institutes", user.oId], e as Partial<InstituteData>);
        } else {
            await firebaseQuery.update(["schools", editSchool], e as Partial<SchoolData>);
        }
        setTimeout(() => {
            setEditSchool(undefined);
        }, 500);
    };
    return (
        <>
            <Popup {...{open, onClose}} fullWidth maxWidth={"sm"} title={"Alumni conversations"} key={"alumniConversationsPopup"}>
                <Typography>Select whether new alumni can join your network.</Typography>
                <Form submitText="Save" onSubmit={(e) => toggleAlumniConversation(e as {institute: boolean}|{[school: string]: boolean})}>
                    <List>
                        {typeof schoolsAlumniConvoEnabled === "boolean" ? <ListItem sx={{marginBottom: "20px"}}>
                            <ListItemText
                                primaryTypographyProps={{fontWeight: "bold"}}
                                sx={{marginRight: 2.5}}
                                primary="Allow alumni uploads"
                                secondary={awaitingAlumniCounts+" alumni are ready to answer questions."}/>
                            <ListItemSecondaryAction>
                                <Stack direction={"row"}>
                                    <Button sx={{"color": institute.color, "borderColor": institute.color, "&:hover": {borderColor: institute.color, backgroundColor: `${institute.color}20`}}}>{institute.acceptingAlumni ? "Edit" : "Activate"}</Button>
                                    <ControlledSwitch name="institute" checked={schoolsAlumniConvoEnabled}/>
                                    <IconButtonPop responsive={false} title="Go to conversations" onClick={() => navigate("conversations")}><ArrowForward/></IconButtonPop>
                                </Stack>
                            </ListItemSecondaryAction>
                        </ListItem> : Object.entries(schools).map(([schoolId, school]) =>
                            <ListItem sx={{marginBottom: "20px"}}>
                                {school.image && <ListItemAvatar>
                                    <Avatar sx={{height: "50px", width: "50px", mr: 2}} alt="School picture" src={school.image} />
                                </ListItemAvatar>}
                                <ListItemText
                                    primaryTypographyProps={{fontWeight: "bold", color: school.color}}
                                    sx={{marginRight: 2.5}}
                                    primary={school.name}
                                    secondary={typeof awaitingAlumniCounts === "object" && awaitingAlumniCounts[schoolId]+" alumni are ready to answer questions."}/>
                                <ListItemSecondaryAction>
                                    <Stack direction={"row"}>
                                        <Button onClick={() => setEditSchool(schoolId)} sx={{"color": school.color, "borderColor": school.color, "&:hover": {borderColor: school.color, backgroundColor: `${school.color}20`}}}>{school.alumniConversations ? "Edit" : "Activate"}</Button>
                                        {/* <ControlledSwitch name={schoolId} checked={school.alumniConversations}/> */}
                                        <IconButtonPop responsive={false} title="Go to conversations" onClick={() => navigate(`conversations/${schoolId}`)}><ArrowForward/></IconButtonPop>
                                    </Stack>
                                </ListItemSecondaryAction>
                            </ListItem>)}
                    </List>
                </Form>
            </Popup>
            <Popup title={"Conversations set up"} open={Boolean(editSchool)} onClose={() => setEditSchool(undefined)}>
                <Form onSubmit={async (e) => await toggleAlumniConversation(e as {[key: string]: boolean})} submitText="Save">
                    <FormGroup>
                        <FormControlLabel control={<Checkbox key={"alumniConversations"} name={"alumniConversations"} defaultChecked={(editSchool && schools[editSchool].alumniConversations) as boolean}/>} label={"Enable conversations"}/>
                    </FormGroup>
                    <Divider/>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox key={"approveAlumniMessages"} name={"approveAlumniMessages"} defaultChecked={(editSchool && schools[editSchool].approveAlumniMessages) as boolean}/>} label={"Approve all messages between alumni and students."}/>
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox key={"anonymiseAlumniConvoStudents"} name={"anonymiseAlumniConvoStudents"} defaultChecked={(editSchool && schools[editSchool].anonymiseAlumniConvoStudents) as boolean}/>} label={"Anonymise student names in alumni conversations by default, only forename is shown."}/>
                    </FormGroup>
                </Form>
            </Popup>
        </>
    );
}

export function AlumniConversationsPage() {
    return (
        <Routes>
            <Route path="/" element={<AlumniConversationsPageOverview/>}/>
            <Route path="/:schoolId" element={<AlumniConversationsPageOverview/>} />
        </Routes>
    );
}

function AlumniConversationsPageOverview() {
    const {schoolId} = useParams();
    const [linkCopied, setLinkCopied] = useState<string[]>([]);
    const [pendingRelease, setPendingRelease] = useState<number>();
    const [openConversations, setOpenConversations] = useState<number>();
    const [totalConversations, setTotalConversations] = useState<number>();
    const [reportedConversations, setReportedConversations] = useState<number>();

    const firebaseQuery = new FirebaseQuery();
    const navigate = useNavigate();

    const user = useContext(UserContext);
    const schools = useContext(OrganisationContext).schools as {[key: string]: SchoolData};
    const institute = useContext(OrganisationContext).details as InstituteData;
    const schoolsAlumniConvoEnabled = institute.package === "institutes-two" ? Object.entries(schools || {}).filter(([, school]) => school.alumniConversations) : (institute.alumniConversations || false);
    const convoScrollRef = useRef<HTMLDivElement>(null);

    const [openConvo, setOpenConvo] = useState<{student: AlumniConvoUser, alumni: Alumni, id: string}&AlumniConversation>();

    const school = schoolId ? schools[schoolId] : undefined;

    const [query] = useState([{
        path: ["alumniConversations"],
        where: [["oId", "==", user.oId], ["open", "==", true], ((school && schoolId) ? ["schoolId", "==", schoolId] : undefined)].filter((e) => e),
    } as QueryObject]);

    if (schoolId && !school) {
        navigate("/institutes/network/alumni");
    }

    const constraints = [where("oId", "==", user.oId)];
    if (school && schoolId) {
        constraints.push(where("schoolId", "==", schoolId));
    }

    useEffect(() => {
        if (schoolId && !school) {
            setPendingRelease(0);
            setTotalConversations(0);
            setOpenConversations(0);
            return;
        }


        firebaseQuery.getCount(["alumniConversations"], constraints).then(setTotalConversations);
        firebaseQuery.getCount(["alumniConversations"], [...constraints, where("open", "==", true)]).then(setOpenConversations);
        firebaseQuery.getCount(["alumniConversations"], [...constraints, where("open", "==", true), where("delivered", "==", "pending")]).then(setPendingRelease);
        firebaseQuery.getCount(["alumniConversations"], [...constraints, where("open", "==", true), where("reported", "==", true)]).then(setReportedConversations);
    }, [school]);

    useEffect(() => {
        console.log("Scroll bottom");

        setTimeout(() => {
            if (!convoScrollRef.current) return;

            convoScrollRef.current.scrollTo({
                top: convoScrollRef.current.scrollHeight,
                behavior: "smooth",
            });
        }, 50);
    }, [convoScrollRef.current]);

    const approveMessage = async (conversationId: string, messageId: number) => {
        await firebaseQuery.update(["alumniConversations", conversationId], {
            ["delivered"]: true,
            [`messages.${messageId}.delivered`]: true,
        } as Partial<AlumniConversation>);
        await firebaseQuery.getCount(["alumniConversations"], [...constraints, where("open", "==", true), where("delivered", "==", "pending")]).then(setPendingRelease);
        return;
    };

    const closeReport = async (conversationId: string) => {
        await firebaseQuery.update(["alumniConversations", conversationId], {
            reported: false,
        } as Partial<AlumniConversation>);
        await firebaseQuery.getCount(["alumniConversations"], [...constraints, where("open", "==", true), where("reported", "==", true)]).then(setReportedConversations);
        return;
    };

    const closeConversation = async (id: string) => {
        return await firebaseQuery.update(["alumniConversations", id], {
            open: false,
        } as Partial<AlumniConversation>);
    };

    const schoolLinks = schoolsAlumniConvoEnabled === true ?
        <Card grid title={"Your School"}>
            <Typography>Share this link with your students.</Typography>
            <QRCodeGenerator url={`https://placementt.co.uk/alumniConversations/${user.oId}`}/>
            <Typography><strong>Or simply copy and share a link</strong></Typography>
            <Button onClick={() => {
                navigator.clipboard.writeText(`https://placementt.co.uk/alumniConversations/${user.oId}`);
                setLinkCopied((l) => [...l, user.oId]);
            }}>{linkCopied.includes(user.oId) ? "Link copied!" : "Copy link"}</Button>
        </Card> : !schoolsAlumniConvoEnabled ? null :
            <FilterList title={"Your Schools"} grid data={Object.fromEntries(schoolsAlumniConvoEnabled)}>
                {schoolsAlumniConvoEnabled.map(([schoolId, school]) =>
                    <ListItem id={schoolId} sx={{marginBottom: "20px"}}>
                        {school.image && <ListItemAvatar>
                            <Avatar sx={{height: "50px", width: "50px", mr: 2}} alt="School picture" src={school.image} />
                        </ListItemAvatar>}
                        <ListItemText
                            primaryTypographyProps={{fontWeight: "bold", color: school.color}}
                            sx={{marginRight: 2.5}}
                            primary={school.name}/>
                        <ListItemSecondaryAction>
                            <Button onClick={() => {
                                navigator.clipboard.writeText(`https://placementt.co.uk/alumniConversations/${user.oId}/${schoolId}`);
                                setLinkCopied((l) => [...l, schoolId]);
                            }}>{linkCopied.includes(schoolId) ? "Link copied!" : "Copy link"}</Button>
                            <QRCodeGenerator noImage url={`https://placementt.co.uk/alumniConversations/${user.oId}/${schoolId}`}/>
                        </ListItemSecondaryAction>
                    </ListItem>)}
            </FilterList>;

    return (
        <Page metaTitle="Alumni Conversations | Placementt" grid>
            <TutorialPage pageId="alumniConversations"/>
            <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link
                        style={{"color": "black"}}
                        to="/institutes/network/alumni"
                    >
                        Alumni
                    </Link>
                    {school ? [
                        <Link
                            style={{"color": "black"}}
                            to="/institutes/network/alumni/conversations"
                        >
                        Conversations
                        </Link>,
                        <Typography>{school.name}</Typography>] : <Typography>Conversations</Typography>}
                </Breadcrumbs>
            </Grid>
            <Grid item xs={12} md={4} minHeight={"70.75px !important"}>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} flex={1} sx={{background: PRIMARY_COLOUR, borderRadius: "20px", padding: "10px 20px", color: "white"}}>
                    <Typography sx={{fontWeight: "bold"}} color={"white !important"}>Open conversations</Typography>
                    <Typography sx={{fontWeight: "bold", color: "white !important"}}>{openConversations}</Typography>
                </Stack>
            </Grid>
            <Grid item xs={12} md={4} minHeight={"70.75px !important"}>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} flex={1} sx={{background: PRIMARY_COLOUR, borderRadius: "20px", padding: "10px 20px", color: "white"}}>
                    <Typography sx={{fontWeight: "bold"}} color={"white !important"}>Awaiting approval</Typography>
                    <Typography sx={{fontWeight: "bold", color: "white !important"}}>{pendingRelease}</Typography>
                </Stack>
            </Grid>
            <Grid item xs={12} md={4} minHeight={"70.75px !important"}>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} flex={1} sx={{background: PRIMARY_COLOUR, borderRadius: "20px", padding: "10px 20px", color: "white"}}>
                    <Typography sx={{fontWeight: "bold"}} color={"white !important"}>Total conversations</Typography>
                    <Typography sx={{fontWeight: "bold", color: "white !important"}}>{totalConversations}</Typography>
                </Stack>
            </Grid>
            <Grid xs={12} md={8} item container direction={"column"}>
                {Boolean(reportedConversations && reportedConversations > 0) && <DataViewer
                    sx={{maxHeight: "300px"}}
                    grid
                    title={<Typography color={"error"} variant="h5">Reported conversations</Typography>}
                    noFullscreen
                    data={[{
                        path: ["alumniConversations"],
                        where: [...[["oId", "==", user.oId], ["reported", "==", true], ((school && schoolId) ? ["schoolId", "==", schoolId] : undefined)].filter((e) => e)],
                    } as QueryObject]}
                    additionalEntryProcessing={async (k: string, v: AlumniConversation) => {
                        const alumni = await firebaseQuery.getDocData(
                            ["alumni", v.alumniId]) as Alumni;
                        const student = await firebaseQuery.getDocData(
                            ["conversationStudents", v.externalStudentId]) as AlumniConvoUser;
                        return {
                            alumni: alumni,
                            student: student,
                            ...v,
                        };
                    }}
                    onItemClick={(key, item) => setOpenConvo({id: key, ...(item as AlumniConversation&{alumni: Alumni, student: AlumniConvoUser})})}
                    listViewRenderer={(key:string, value:AlumniConversation&{alumni: Alumni, student: AlumniConvoUser}) =>{
                        const recentMessage = getMostRecentAlumniMessage(value);
                        if (!recentMessage) return <></>;
                        return (
                            <ListItemButton key={key} id={key} onClick={() => setOpenConvo({id: key, ...value})}>
                                <ListItemText
                                    primary={value.student ? `${value.student.forename} ${value.student.surname} - ${value.student.email}` : <Skeleton width={"50%"} height={"20px"}/>}
                                    secondary={
                                        <Box><Stack direction={"column"}>
                                            {value.alumni ? `With ${value.alumni.forename} ${value.alumni.surname}` : <Skeleton width={"30%"} height={"20px"}/>}
                                            {value.alumni ? capitalise(recentMessage?.sentBy)+": "+recentMessage?.message : <Skeleton width={"35%"} height={"20px"}/>}
                                            <Alert severity="error" sx={{marginRight: "130px !important", marginTop: "10px !important"}}>
                                                <Stack spacing={0}>
                                                    <Typography fontSize={"14px"}>Reported by: {value.report?.reportedBy}</Typography>
                                                    <Typography fontSize={"14px"}>Reported reason: {value.report?.message}</Typography>
                                                </Stack>
                                            </Alert>
                                        </Stack></Box>}
                                />
                                <ListItemSecondaryAction>
                                    <LoadingButton text="Close report" onClick={async (e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        closeReport(key);
                                    }}/>
                                </ListItemSecondaryAction>
                            </ListItemButton>);
                    }}/>}
                {Boolean(pendingRelease && pendingRelease > 0) && <DataViewer
                    sx={{maxHeight: "200px"}}
                    grid
                    title={"Messages awaiting release"}
                    noFullscreen
                    data={[{
                        path: ["alumniConversations"],
                        where: [...[["oId", "==", user.oId], ["open", "==", true], ((school && schoolId) ? ["schoolId", "==", schoolId] : undefined)].filter((e) => e), ["delivered", "==", "pending"]],
                    } as QueryObject]}
                    additionalEntryProcessing={async (k: string, v: AlumniConversation) => {
                        const alumni = await firebaseQuery.getDocData(
                            ["alumni", v.alumniId]) as Alumni;
                        const student = await firebaseQuery.getDocData(
                            ["conversationStudents", v.externalStudentId]) as AlumniConvoUser;
                        return {
                            alumni: alumni,
                            student: student,
                            ...v,
                        };
                    }}
                    onItemClick={(key, item) => setOpenConvo({id: key, ...(item as AlumniConversation&{alumni: Alumni, student: AlumniConvoUser})})}
                    listViewRenderer={(key:string, value:AlumniConversation&{alumni: Alumni, student: AlumniConvoUser}) =>{
                        const recentMessage = getMostRecentAlumniMessage(value);
                        if (!recentMessage) return <></>;
                        return (
                            <ListItemButton key={key} id={key} onClick={() => setOpenConvo({id: key, ...value})}>
                                <ListItemText
                                    primary={value.student ? `${value.student.forename} ${value.student.surname} - ${value.student.email}` : <Skeleton width={"50%"} height={"20px"}/>}
                                    secondary={
                                        <Box><Stack direction={"column"}>
                                            {value.alumni ? `With ${value.alumni.forename} ${value.alumni.surname}` : <Skeleton width={"30%"} height={"20px"}/>}
                                            {value.alumni ? capitalise(recentMessage?.sentBy)+": "+recentMessage?.message : <Skeleton width={"35%"} height={"20px"}/>}
                                        </Stack></Box>}
                                />
                                <ListItemSecondaryAction>
                                    <LoadingButton text="Approve" onClick={async (e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        approveMessage(key, parseInt(recentMessage.id));
                                    }}/>
                                </ListItemSecondaryAction>
                            </ListItemButton>);
                    }}/>}
                <DataViewer
                    grid xs={12} md={8}
                    noFullscreen
                    viewType="list"
                    key={"ongoing"}
                    title={"Ongoing conversations"}
                    data={query}
                    additionalEntryProcessing={async (k: string, v: AlumniConversation) => {
                        const alumni = await firebaseQuery.getDocData(
                            ["alumni", v.alumniId]) as Alumni;
                        const student = await firebaseQuery.getDocData(
                            ["conversationStudents", v.externalStudentId]) as AlumniConvoUser;
                        return {
                            alumni: alumni,
                            student: student,
                            ...v,
                        };
                    }}
                    listViewRenderer={(key:string, value:AlumniConversation&{alumni: Alumni, student: AlumniConvoUser}) => {
                        const mostRecentMessage = getMostRecentAlumniMessage(value);
                        return (<ListItemButton key={key} id={key} onClick={() => setOpenConvo({id: key, ...value})}>
                            <ListItemText
                                primary={(value.student && value.alumni) ? <Typography><strong>{value.student.forename} {value.student.surname} - {value.student.email}</strong> with {value.alumni.forename} {value.alumni.surname}</Typography> : <Skeleton width={"50%"} height={"20px"}/>}
                                secondary={
                                    <Stack spacing={0}>
                                        {(!schoolId && value.student?.schoolId) && <Typography color={schools?.[value.student?.schoolId].color}>{schools?.[value.student?.schoolId].name}</Typography>}
                                        <Typography><i>{mostRecentMessage?.delivered ? `${mostRecentMessage.sentAt.split("T")[1].substring(0, 5)} ${convertDate(mostRecentMessage.sentAt, "visual") as string}` : "Undelivered"}</i> - {capitalise(mostRecentMessage?.sentBy)}: {mostRecentMessage?.message}</Typography>
                                    </Stack>}/>
                            <ListItemSecondaryAction>
                                <IconButtonPop title="View" onClick={() => setOpenConvo({id: key, ...value})} responsive={false}><ArrowForward/></IconButtonPop>
                            </ListItemSecondaryAction>
                        </ListItemButton>);
                    }}/>
            </Grid>
            <Popup key={"openConvo"} maxWidth={"lg"} title={"View conversation"} fullWidth open={Boolean(openConvo)} onClose={() => setOpenConvo(undefined)}>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h5">Student</Typography>
                        <Typography>Name: {openConvo?.student.forename} {openConvo?.student.surname}</Typography>
                        <Typography>Email: {openConvo?.student.email}</Typography>
                        <Divider sx={{marginTop: 2, marginBottom: 2}}/>
                        <Typography variant="h5">Alumni</Typography>
                        <Typography>Name: {openConvo?.alumni.forename} {openConvo?.alumni.surname}</Typography>
                        <Typography>Email: {openConvo?.alumni.email}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h5">Conversation</Typography>
                        <Stack height={"500px"} key={"convoScroller"} overflow={"auto"} ref={convoScrollRef} p={2}>
                            <Box flex={1}/>
                            {openConvo?.messages && Object.entries(openConvo?.messages || {}).sort(([, aM], [, bM]) => new Date(aM.sentAt).getTime() - new Date(bM.sentAt).getTime()).map(([key, message], index) => {
                                const prevEntry = index === 0 ? null : Object.entries(openConvo?.messages || {}).sort(([, aM], [, bM]) => new Date(aM.sentAt).getTime() - new Date(bM.sentAt).getTime())[index-1];

                                const isNewMessager = !prevEntry || prevEntry[1].sentBy !== message.sentBy;

                                return (<Stack spacing={0} maxWidth={"80%"} alignItems={message.sentBy === "alumni" ? "start" : "end"} key={key} sx={{alignSelf: message.sentBy === "alumni" ? "start" : "end"}}>
                                    {isNewMessager ? <Typography variant="caption" color={"grey"}>{message.sentBy === "alumni" ? "Alumni" : "Student"}, {convertDate(message.sentAt, "visual") as string}</Typography> : null}
                                    <Box borderRadius={3} sx={{fontStyle: message.delivered === true ? undefined: "italic"}} bgcolor={message.sentBy === "alumni" ? `${(openConvo?.student?.schoolId && schools?.[openConvo?.student?.schoolId].color) || PRIMARY_COLOUR}30` : "#ededed"} p={1}>
                                        <Typography>{message.message}</Typography>
                                    </Box>
                                    {message.delivered === "pending" && <Typography variant="caption" color={(openConvo?.student?.schoolId && schools?.[openConvo?.student?.schoolId].color) || PRIMARY_COLOUR}>Not delivered, waiting on school staff to approve.</Typography>}
                                    {message.delivered === "blocked" && <Typography variant="caption" color={"error"}>Message blocked by school. Please get in touch with them for information.</Typography>}
                                </Stack>);
                            })}
                        </Stack>
                        <Stack alignItems={"center"} mt={2}>
                            {openConvo?.delivered === "pending" && <LoadingButton text="Approve" onClick={async () => openConvo && approveMessage(openConvo?.id, parseInt(getMostRecentAlumniMessage(openConvo)?.id || ""))}/>}
                            {openConvo?.open ? <LoadingButton color="error" onClick={() => closeConversation(openConvo.id)} text="Close conversation"/> : <Typography>Conversation currently closed</Typography>}
                        </Stack>
                    </Grid>
                </Grid>
            </Popup>
            <Grid item xs={12} md={4} container direction={"column"}>
                {schoolLinks}
            </Grid>
        </Page>
    );
}


export function AlumniConversationsStudentPage() {
    const {orgId, uid, key} = useParams();
    const oId = orgId?.split(" ")[0];
    const schoolId = orgId?.split(" ")[1];

    const firebaseQuery = new FirebaseQuery();

    type AlumniConversationsInstituteData = {
        instituteName: string,
        primaryColor?: string,
        primaryImage?: string,
    }

    const [page, setPage] = useState<"loading"|"signedIn"|"expired"|"incorrectKey"|"incorrectDetails">("loading");
    const [instituteData, setInstituteData] = useState<AlumniConversationsInstituteData>();
    const [studentData, setStudentData] = useState<AlumniConvoUser>();
    const [selectedAlumni, setSelectedAlumni] = useState<{id: string, alumni: Alumni, messages?: AlumniConversation}>();
    const [conversations, setConversations] = useState<{[key: string]: AlumniConversation&{alumni: Alumni}}>();
    const [closeConversationPopup, setCloseConversationPopup] = useState<string>();
    const [reportConversationPopup, setReportConversationPopup] = useState<string>();

    const convoScrollRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (auth.currentUser?.uid) {
            if (auth.currentUser.uid !== uid) {
                auth.signOut();
            }
        }
        executeCallable("alumni-signInToAlumniConversations", {...{oId, schoolId, uid, key}}).then(async (data) => {
            const studData = data.data as AlumniConversationsInstituteData&{authToken?: string};

            if (auth.currentUser?.uid !== uid) {
                await signInWithCustomToken(auth, data.data.authToken as string);
            }
            delete studData.authToken;

            setInstituteData(studData);
            setPage("signedIn");
        }).catch((err) => {
            console.error("Error", err);
        });
    }, [oId, schoolId, uid, key]);


    useEffect(() => {
        if (!auth.currentUser?.uid || (auth.currentUser?.uid !== uid) || studentData) return;

        console.log("Getting student data");
        firebaseQuery.getDocData(["conversationStudents", auth.currentUser?.uid]).then((s) => setStudentData(s as AlumniConvoUser));
        firebaseQuery.getDocsWhere(["alumniConversations"], [where("externalStudentId", "==", auth.currentUser.uid), where("open", "==", true)]).then(async (c) => {
            const convWithAlumni = await Promise.all(Object.entries(c as {[key: string]: AlumniConversation}).map(async ([k, conv]) => {
                const alumni = await firebaseQuery.getDocData(["alumni", conv.alumniId]);
                return [k, {...conv, alumni: alumni}];
            })) as [string, AlumniConversation&{alumni: Alumni}][];
            setConversations(Object.fromEntries(convWithAlumni));
        });
    }, [auth.currentUser]);

    useEffect(() => {
        if (!selectedAlumni) {
            setSelectedAlumni(undefined);
            return;
        }

        const getConversation = async () => {
            const conv = await firebaseQuery.getDocData(["alumniConversations", `${uid}-${selectedAlumni.id}`]).catch(() => null) as AlumniConversation|null;
            if (!conv) return;
            setSelectedAlumni((a) => a ? ({...a, messages: conv}) : undefined);
        };

        getConversation();
    }, [selectedAlumni?.id]);

    useEffect(() => {
        if (!convoScrollRef.current) return;

        convoScrollRef.current.scrollTop = convoScrollRef.current.scrollHeight;
    }, [conversations?.[`${uid}-${selectedAlumni?.id}`]?.messages?.messages]);

    const sendMessage = async (message: string) => {
        if (!selectedAlumni) {
            throw new Error("No alumni selected!");
        }
        if (!uid || !studentData) return;


        const studentConfigData = await firebaseQuery.getDocData([studentData.schoolId ?
            "schools" : "institutes", studentData.schoolId || studentData.oId]) as InstituteData|SchoolData;

        const msg = {
            sentBy: "student",
            sentAt: (new Date()).toISOString(),
            message: message,
            delivered: studentConfigData.approveAlumniMessages ? "pending" : true,
        } as AlumniConversation["messages"][0];

        console.log("MESSAGE", msg);

        const msgId = getUniqueId(conversations?.[`${uid}-${selectedAlumni.id}`]?.messages?.messages || {});

        setConversations((c) => (c ? {...c, [`${uid}-${selectedAlumni.id}`]: {...c[`${uid}-${selectedAlumni.id}`], ...selectedAlumni.messages, alumni: selectedAlumni.alumni, messages: {...(c[`${uid}-${selectedAlumni.id}`]?.messages || {}), ...(selectedAlumni.messages?.messages || {}), [msgId]: msg}}} : undefined));

        if (conversations?.[`${uid}-${selectedAlumni.id}`]?.messages || selectedAlumni.messages?.messages) {
            return await firebaseQuery.update(["alumniConversations", `${uid}-${selectedAlumni.id}`], {[`messages.${msgId}`]: msg, ["open"]: true});
        }

        const conversation: AlumniConversation = {
            messages: {[msgId]: msg},
            alumniId: selectedAlumni.id,
            externalStudentId: uid,
            oId: studentData?.oId as string,
            schoolId: studentData?.schoolId,
            delivered: studentConfigData.approveAlumniMessages ? "pending" : true,
            open: true,
        };


        await firebaseQuery.set(["alumniConversations", `${uid}-${selectedAlumni.id}`], conversation);
        setSelectedAlumni((a) => a ? ({...a, messages: conversation}) : undefined);
        return;
    };

    const closeConversation = async () => {
        if (!closeConversationPopup) return;
        await firebaseQuery.update(["alumniConversations", closeConversationPopup], {
            open: false,
        } as Partial<AlumniConversation>);
        setCloseConversationPopup(undefined);
        setConversations((e) => {
            const a = {...e};
            delete a[closeConversationPopup];
            return a;
        });
        setSelectedAlumni((a) => (a ? {...a, messages: a.messages ? {...a.messages, open: false} : undefined} : undefined));
        return;
    };

    const reportConversation = async (reason: string) => {
        if (!reportConversationPopup) return;
        await firebaseQuery.update(["alumniConversations", reportConversationPopup], {
            reported: true,
            report: {
                reportedBy: "student",
                message: reason,
                createdDateTime: (new Date()).toISOString(),
            },
        } as Partial<AlumniConversation>);
        setReportConversationPopup(undefined);

        setSelectedAlumni((a) => (a ? {...a, messages: a.messages ? {...a.messages, reported: true,
            report: {
                reportedBy: "student",
                message: reason,
                createdDateTime: (new Date()).toISOString(),
                resolvedDateTime: "",
            }} : undefined} : undefined));
        return;
    };

    const listItemRow = useCallback((key: string, item: Alumni) => <ListItemButton sx={{borderLeft: (selectedAlumni?.id === key) ? `5px ${instituteData?.primaryColor || PRIMARY_COLOUR} solid` : undefined, transition: "all 100ms ease-in-out"}} key={key} divider id={key} onClick={() => selectedAlumni?.id !== key && setSelectedAlumni({id: key, alumni: item})}>
        <ListItemText
            primary={<Stack spacing={0}>
                <strong>{item?.forename} {item?.surname}</strong>
                <Typography>{item.startYear} - {item.endYear}</Typography>
            </Stack>}/>
    </ListItemButton>, [selectedAlumni?.id]);

    return (
        <Page metaTitle={"Alumni Conversations | "+(instituteData?.instituteName || "Placementt")}>
            <Grid container position={"absolute"} sx={{opacity: page === "signedIn" ? 1 : 0, pointerEvents: page === "signedIn" ? "all" : "none", transition: "250ms all ease-in-out", minHeight: "calc(100% - 16px)"}} width={"calc(100% - 16px)"}>
                <Grid item xs={12} md={5}>
                    <Stack height={"100%"}>
                        <Card>
                            <Stack direction={"row"}>
                                {instituteData?.primaryImage && <Box component={"img"} sx={{width: "100px", height: "100px", mt: 2, mb: 2}} src={instituteData?.primaryImage}/>}
                                <Stack spacing={0}>
                                    <Typography variant='h4' color={instituteData?.primaryColor || PRIMARY_COLOUR}>{instituteData?.instituteName}</Typography>
                                    <Typography>Admin email</Typography>
                                    {studentData?.forename && <Typography>Your name: {studentData?.forename} {studentData?.surname}</Typography>}
                                    <Typography>Your email: {studentData?.email}</Typography>
                                </Stack>
                            </Stack>
                        </Card>
                        {conversations && <DataViewer
                            sx={{maxHeight: "200px"}}
                            title={"Conversations"}
                            noFullscreen
                            data={conversations}
                            listViewRenderer={(key: string, item: AlumniConversation&{alumni: Alumni}) => {
                                const recentMessage = getMostRecentAlumniMessage(item);
                                return (<ListItemButton sx={{borderLeft: (selectedAlumni?.id === item.alumniId) ? `5px ${instituteData?.primaryColor || PRIMARY_COLOUR} solid` : undefined, transition: "all 100ms ease-in-out"}} key={key} divider id={key} onClick={() => setSelectedAlumni({id: item.alumniId, alumni: item.alumni, messages: item})}>
                                    <ListItemText
                                        primary={`${item.alumni?.forename} ${item.alumni?.surname}`}
                                        secondary={<Typography>{recentMessage?.sentBy === "student" ? "You" : item.alumni?.forename}: {recentMessage?.delivered === true ? recentMessage.message : <i>(Undelivered) {recentMessage?.message}</i>}</Typography>}
                                    />
                                </ListItemButton>);
                            }}/>}
                        <Grid sx={{flex: 1}}>
                            {auth?.currentUser?.uid === uid && studentData && <DataViewer
                                sx={{height: "100%"}}
                                filterNames={undefined}
                                noFullscreen
                                title={"Search your Alumni"}
                                disableSelection
                                hook={() => useAlumniPaginator({alumniConvoUser: studentData, view: "list", ql: 10})}
                                onSearch
                                listViewRenderer={listItemRow}/>}
                        </Grid>
                    </Stack>
                </Grid>
                <Card grid xs={0} md={7} sx={{height: "100%"}}>
                    {selectedAlumni ?
                        <Stack height={"100%"}>
                            <Typography>{selectedAlumni.alumni.forename} {selectedAlumni.alumni.surname}</Typography>
                            <Stack flexGrow={1}>

                            </Stack>
                            <Divider/>
                            <Typography variant="h5">Conversation</Typography>
                            <Stack maxHeight={"300px"} overflow={"auto"} p={2} ref={convoScrollRef}>
                                {conversations && Object.entries(selectedAlumni?.messages?.messages || {}).sort(([, aM], [, bM]) => new Date(aM.sentAt).getTime() - new Date(bM.sentAt).getTime()).map(([key, message], index) => {
                                    const prevEntry = index === 0 ? null : Object.entries(conversations[`${uid}-${selectedAlumni.id}`]?.messages || {}).sort(([, aM], [, bM]) => new Date(aM.sentAt).getTime() - new Date(bM.sentAt).getTime())[index-1];

                                    const isNewMessager = !prevEntry || prevEntry[1].sentBy !== message.sentBy;

                                    return (<Stack spacing={0} maxWidth={"80%"} alignItems={message.sentBy === "alumni" ? "start" : "end"} key={key} sx={{alignSelf: message.sentBy === "alumni" ? "start" : "end"}}>
                                        {isNewMessager ? <Typography variant="caption" color={"grey"}>{message.sentBy === "alumni" ? "Alumni" : "You"}, {convertDate(message.sentAt, "visual") as string}</Typography> : null}
                                        <Box borderRadius={3} sx={{fontStyle: message.delivered === true ? undefined: "italic"}} bgcolor={message.sentBy === "alumni" ? `${instituteData?.primaryColor || PRIMARY_COLOUR}30` : "#ededed"} p={1}>
                                            <Typography>{message.message}</Typography>
                                        </Box>
                                        {message.delivered === "pending" && <Typography variant="caption" color={instituteData?.primaryColor || PRIMARY_COLOUR}>Not delivered, waiting on school staff to approve.</Typography>}
                                        {message.delivered === "blocked" && <Typography variant="caption" color={"error"}>Message blocked by school. Please get in touch with them for information.</Typography>}
                                    </Stack>);
                                })}
                            </Stack>
                            {selectedAlumni?.messages?.reported ||<MessageBox placeholder="Start typing..." onMessage={sendMessage}/>}
                            <Box>
                                {selectedAlumni?.messages?.open ? <Button sx={{display: "inline-block"}} onClick={() => setCloseConversationPopup(`${uid}-${selectedAlumni.id}`)}>Close conversation</Button> : <Divider>Conversation closed.</Divider>}
                                {!selectedAlumni?.messages?.reported ? <Button sx={{display: "inline-block"}} color={"error"} onClick={() => setReportConversationPopup(`${uid}-${selectedAlumni.id}`)}>Report conversation</Button> : <Divider>Conversation reported.</Divider>}
                            </Box>
                        </Stack> :
                        <Stack alignItems={"center"} height={"100%"} justifyContent={"center"} flex={1}>
                            <img src={chats} style={{maxHeight: 150}}/>
                            <Typography variant="h5" maxWidth={"400px"} textAlign={"center"} mb={5}>Click an alumni to view their profile and send them a question.</Typography>
                        </Stack>}
                    <Popup key={"closeConversationPopup"} open={Boolean(closeConversationPopup)} onClose={() => setCloseConversationPopup(undefined)} title={"Close conversation?"}>
                        <Typography mb={2}>Closing this conversation will mark remove it from your active conversations. You will still be able to message them in the future.</Typography>
                        <LoadingButton text="Close conversation" onClick={closeConversation}/>
                    </Popup>
                    <Popup key={"reportConversationPopup"} open={Boolean(reportConversationPopup)} onClose={() => setReportConversationPopup(undefined)} title={"Report conversation?"}>
                        <Typography mb={2}>Are you sure you want to report this conversation? Doing so will notify your school and you will no longer be able to send messages to this alumni.</Typography>
                        <Form onSubmit={(e) => reportConversation(e.reason as string)}>
                            <InputGroup required label={"Reason for reporting"} name={"reason"}/>
                        </Form>
                    </Popup>
                </Card>
            </Grid>
            <FadeInBox card visible={page === "loading"}>
                <Typography variant="h4">Fetching school details</Typography>
                <Typography variant='h6'>This won't take long...</Typography>
                <Preloader visible/>
            </FadeInBox>
            <FadeInBox card visible={page === "expired"}>
                <Typography>Link expired.</Typography>
            </FadeInBox>
            <FadeInBox card visible={page === "incorrectDetails"}>
                <Typography>Incorrect details</Typography>
            </FadeInBox>
            <FadeInBox card visible={page === "incorrectKey"}>
                <Typography>Incorrect key</Typography>
            </FadeInBox>
        </Page>
    );
}


export function AlumniConversationsAlumniPage() {
    const {alumniId, key} = useParams();

    // const firebaseQuery = new FirebaseQuery();

    type AlumniData = {
        instituteName: string,
        primaryColor: string,
        primaryImage: string,
        alumni: Alumni,
        conversations: {[key: string]: {
            student: AlumniConvoUser,
            conversation: AlumniConversation,
            disabled: boolean,
        }},
    }

    const [page, setPage] = useState<"loading"|"signedIn"|"expired"|"incorrectKey"|"incorrectDetails">("loading");
    const [alumniData, setAlumniData] = useState<AlumniData>();
    const [selectedChat, setSelectedChat] = useState<string>();
    const convoScrollRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!(alumniId && key)) return;
        executeCallable("alumni-alumniSignIn", {alumniId: alumniId, linkCode: key}).then(async (data) => {
            setAlumniData(data.data as AlumniData);
            setPage("signedIn");
        }).catch((err) => {
            console.error("Error", err);
        });
    }, [alumniId, key]);

    useEffect(() => {
        if (!convoScrollRef.current) return;

        convoScrollRef.current.scrollTop = convoScrollRef.current.scrollHeight;
    }, [alumniData?.conversations[selectedChat || ""]?.conversation.messages]);

    const sendMessage = async (message: string) => {
        if (!selectedChat) return;
        setAlumniData((a) => (a ? {...a, conversations: {...a.conversations, [selectedChat]: {...a.conversations[selectedChat], conversation: {...a.conversations[selectedChat].conversation, messages: {...a.conversations[selectedChat].conversation.messages, [getUniqueId(a.conversations[selectedChat].conversation.messages)]: {
            message: message,
            sentAt: (new Date()).toISOString(),
            sentBy: "alumni",
            delivered: true,
        }}}}}} : undefined));

        await executeCallable("alumni-alumniSendMessage", {alumniId: alumniId, linkCode: key, conversationId: selectedChat, message: message});
    };

    console.log(alumniData?.conversations);

    return (
        <Page metaTitle="Alumni | Placementt">
            <Grid container position={"absolute"} sx={{opacity: page === "signedIn" ? 1 : 0, pointerEvents: page === "signedIn" ? "all" : "none", transition: "250ms all ease-in-out", minHeight: "calc(100% - 16px)"}} width={"calc(100% - 16px)"}>
                <Card grid xs={12} sx={{height: "100%"}}>
                    <Stack direction={"row"}>
                        {alumniData?.primaryImage && <Box component={"img"} sx={{width: "100px", height: "100px", mt: 2, mb: 2}} src={alumniData?.primaryImage}/>}
                        <Stack spacing={0}>
                            <Typography variant='h4' color={alumniData?.primaryColor || PRIMARY_COLOUR}>{alumniData?.instituteName}</Typography>
                            <Typography>Admin email</Typography>
                            {alumniData?.alumni?.forename && <Typography>Your name: {alumniData.alumni?.forename} {alumniData.alumni?.surname}</Typography>}
                            <Typography>Your email: {alumniData?.alumni?.email}</Typography>
                        </Stack>
                    </Stack>
                </Card>
                <Grid sx={{flex: 1}} item xs={12} md={5}>
                    <FilterList data={alumniData?.conversations} title={"Student messages"} hideNoResultsText loadMoreIcon={"loaded"}>
                        {Object.entries(alumniData?.conversations || {}).map(([key, conv]) =>
                            <ListItemButton sx={{borderLeft: (selectedChat === key) ? `5px ${alumniData?.primaryColor || PRIMARY_COLOUR} solid` : undefined, transition: "all 100ms ease-in-out"}} key={key} divider id={key} onClick={() => setSelectedChat(key)}>
                                <ListItemText
                                    primary={<strong>{conv.student.forename} {conv.student.surname}</strong>}
                                    secondary={getMostRecentAlumniMessage(conv.conversation)?.message}/>
                            </ListItemButton>
                        )}
                    </FilterList>
                </Grid>
                <Card grid xs={0} md={7} sx={{height: "100%"}}>
                    {selectedChat ?
                        <Stack height={"100%"}>
                            <Typography>{alumniData?.conversations[selectedChat].student.forename} {alumniData?.conversations[selectedChat].student.surname}</Typography>
                            <Stack flexGrow={1}>

                            </Stack>
                            <Divider/>
                            <Typography variant="h5">Conversation</Typography>
                            <Stack maxHeight={"300px"} overflow={"auto"} p={2} ref={convoScrollRef}>
                                {Object.entries(alumniData?.conversations[selectedChat].conversation.messages || {}).sort(([, aM], [, bM]) => new Date(aM.sentAt).getTime() - new Date(bM.sentAt).getTime()).map(([key, message], index) => {
                                    const prevEntry = index === 0 ? null : Object.entries(alumniData?.conversations[selectedChat].conversation?.messages || {}).sort(([, aM], [, bM]) => new Date(aM.sentAt).getTime() - new Date(bM.sentAt).getTime())[index-1];

                                    const isNewMessager = !prevEntry || prevEntry[1].sentBy !== message.sentBy;

                                    return (<Stack spacing={0} maxWidth={"80%"} alignItems={message.sentBy === "student" ? "start" : "end"} key={key} sx={{alignSelf: message.sentBy === "student" ? "start" : "end"}}>
                                        {isNewMessager ? <Typography variant="caption" color={"grey"}>{message.sentBy === "student" ? "Student" : "You"}, {convertDate(message.sentAt, "visual") as string}</Typography> : null}
                                        <Box borderRadius={3} sx={{fontStyle: message.delivered === true ? undefined: "italic"}} bgcolor={message.sentBy === "student" ? `${alumniData?.primaryColor || PRIMARY_COLOUR}30` : "#ededed"} p={1}>
                                            {message.message}
                                        </Box>
                                        {message.delivered === "pending" && <Typography variant="caption" color={alumniData?.primaryColor || PRIMARY_COLOUR}>Not delivered, waiting on school staff to approve.</Typography>}
                                        {message.delivered === "blocked" && <Typography variant="caption" color={"error"}>Message blocked by school. Please get in touch with them for information.</Typography>}
                                    </Stack>);
                                })}
                            </Stack>
                            <MessageBox placeholder="Start typing..." onMessage={sendMessage}/>

                        </Stack> :
                        <Stack alignItems={"center"} height={"100%"} justifyContent={"center"} flex={1}>
                            <img src={chats} style={{maxHeight: 150}}/>
                            <Typography variant="h5" maxWidth={"400px"} textAlign={"center"} mb={5}>Select a chat to view and reply to messages.</Typography>
                        </Stack>}
                </Card>
            </Grid>


            <FadeInBox card visible={page === "loading"}>
                <Typography variant="h4">Fetching school details</Typography>
                <Typography variant='h6'>This won't take long...</Typography>
                <Preloader visible/>
            </FadeInBox>
            <FadeInBox card visible={page === "expired"}>
                <Typography>Link expired.</Typography>
            </FadeInBox>
            <FadeInBox card visible={page === "incorrectDetails"}>
                <Typography>Incorrect details</Typography>
            </FadeInBox>
            <FadeInBox card visible={page === "incorrectKey"}>
                <Typography>Incorrect key</Typography>
            </FadeInBox>
        </Page>
    );
}
