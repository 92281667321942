import {TextField} from "@mui/material";
import {validateEmail} from "placementt-core";
import {useEffect, useState} from "react";

export default function InputGroup(props) {
    const [valid, setValid] = useState(true);
    const [errorText, setErrorText] = useState("");
    const [value, setValue] = useState(props.value || "");

    useEffect(() => {
        const getMissingPasswordItems = () => {
            if (value.length < 8) {
                return "Password too short";
            }
            const missingPasswordItems = [];
            if (!(/[a-z]/.test(value))) {
                missingPasswordItems.push("lower case character");
            }
            if (!(/[A-Z]/.test(value))) {
                missingPasswordItems.push("upper case character");
            }
            if (!(/[0-9]/.test(value))) {
                missingPasswordItems.push("number");
            }
            if (!/[^a-zA-Z0-9]/.test(value)) {
                missingPasswordItems.push("special character");
            }
            if (missingPasswordItems.length) {
                return `Missing ${missingPasswordItems.join(", ")}`;
            }
            return;
        };

        setErrorText(props.errorText || (props.type === "password" ? getMissingPasswordItems() : "Required"));
    }, [props.errorText, value]);

    useEffect(() => {
        setValue(props.value || "");
    }, [props.value, props.defaultValue]);

    const isValid = (e) => {
        setValid(true);

        props.onInputEnd && props.onInputEnd(e);

        if (props.required && e.target.value === "") {
            setValid(false);
            return;
        } else {
            if (e.target.value === "") {
                setValid(true);
                return;
            }
            if (props.pattern && !(new RegExp(props.pattern)).test(e.target.value)) {
                setErrorText(props.patternError || "Please use the required format.");
                setValid(false);
                e.target.setCustomValidity(false);
                return;
            }
            setValid(true);
        }

        let match = true;
        if (props.type === "password") {
            match = e.target.value.match(/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?~-]).{8,}$/);
        }
        if (props.type === "email") {
            match = e.target.value.length === 0 || validateEmail(e.target.value);
        }

        if (match) {
            setValid(true);
            e.target.setCustomValidity("");
        } else {
            setValid(false);
            e.target.setCustomValidity(false);
        }

        props.onChange && props.onChange(e);
    };

    return (
        <TextField error={!valid || props.error} helperText={(!valid || props.error) ? errorText : ""} autoComplete={props.autocomplete || props.name} {...props} onBlur={(e) => {
            isValid(e);
            props.onBlur && props.onBlur();
        }} fullWidth value={value} onChange={(e) => {
            if (props.readOnly) return;
            setValue(e.target.value); props.onChange && props.onChange(e);
        }} inputProps={{min: props?.min, max: props?.max}} onFocus={(e) => props.readOnly ? e.currentTarget.blur() : undefined} InputLabelProps={(props.type==="date" || props.type==="time") ? {shrink: true} : undefined}/>
    );
}
