import {Domain, Analytics as AnalyticsIcon, Home as HomeIcon, Person, Today, Add} from "@mui/icons-material";
import SearchPlacements from "../Shared/SearchPlacements";
import ProposePlacement from "../Shared/ProposePlacement";
import Placements from "./Placements";
import Notifications from "../Shared/Notifications";
import Profile from "./Profile";
import Notes from "../Shared/Notes";
// import {PermissionRequestPopup} from "../../Components/PermissionRequestPopup";
import Analytics from "../Shared/Analytics";
import SideNavBar, {SideNavBarItems} from "../../Components/Navigation/SideNavBar";
import CustomFormPage from "../../Components/CustomFormPage";
import Home from "./Home";
import Calendar from "./Calendar";
import {Contact, StudentPlacementData} from "placementt-core";
import {createContext, useContext, useEffect, useState} from "react";
import {UserContext} from "../../App";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {where} from "firebase/firestore";
import ViewPlacement from "../Shared/ViewPlacement";

export const StudentContext = createContext<{placements?: {[key: string] : StudentPlacementData}, contacts?: {[key: string] : Contact}}>({placements: {}});

export default function Students() {
    const user = useContext(UserContext);
    const [placements, setPlacements] = useState<{[key:string]: StudentPlacementData}>(); // {}
    const [contacts, setContacts] = useState<{[key:string]: Contact}>(); // {}

    const firebaseQuery = new FirebaseQuery();

    useEffect(() => {
        firebaseQuery.collectionSnapshot(setPlacements, "placements", [where("uid", "==", user.id)]);
        firebaseQuery.collectionSnapshot(setContacts, "contacts", [where("uid", "==", user.id)]);
    }, []);


    const routes:SideNavBarItems = [
        {
            label: "Profile",
            route: "profile",
            element: <Profile/>,
            icon: <Person/>,
        },
        {
            label: "Home",
            route: "home",
            element: <Home/>,
            icon: <HomeIcon/>,
        },
        {
            label: "Placements",
            route: "allPlacements",
            element: <Placements/>,
            icon: <Domain/>,
        },
        // {
        //     label: applications ? "Applications" : undefined,
        //     route: "applications",
        //     icon: <Approval/>,
        //     element: <StudentApplications/>,
        // },
        {
            label: "Calendar",
            route: "calendar",
            element: <Calendar/>,
            icon: <Today/>,
        },
        {
            label: "Analytics",
            route: "analytics",
            element: <Analytics/>,
            icon: <AnalyticsIcon/>,
        },
        {
            route: "search",
            element: <SearchPlacements/>,
        },
        {
            label: "Create",
            route: "create",
            icon: <Add/>,
            element: <ProposePlacement/>,
        },
        {
            route: "profile",
            element: <Profile/>,
        },
        {
            route: "analytics/:params",
            element: <Analytics/>,
        },
        {
            route: "notes",
            element: <Notes standalone/>,
        },
        {
            route: "notes/:id",
            element: <Notes standalone/>,
        },
        {
            route: "notifications",
            element: <Notifications/>,
        },
        {
            route: "notifications/:id",
            element: <Notifications/>,
        },
        {
            route: "form",
            element: <CustomFormPage/>,
        },
        {
            route: "propose",
            element: <ProposePlacement/>,
        },
        {
            route: "placements/:pid",
            element: <ViewPlacement/>,
        },
    ];
    /*
    return (
        <>
            <SideNavBar items={routes}/>
            {user.cohortRequests && Object.entries(user.cohortRequests)?.[0]?.[0] && <PermissionRequestPopup oId={Object.entries(user.cohortRequests)?.[0]?.[0]} cohortId={Object.entries(user.cohortRequests)?.[0]?.[1]?.[0]}/>}
        </>);
        */
    return (
        <StudentContext.Provider value={{placements: placements, contacts: contacts}}>
            <SideNavBar items={routes}/>
        </StudentContext.Provider>);
}
