import SubNavBar, {SubNavBarItems} from "../../../../Components/Navigation/SubNavBar";
import Cohort from "./Cohort";
import {useContext, useEffect, useState} from "react";
import FadeInBox from "../../../../Components/FadeInBox";
import Form from "../../../../Components/Form";
import InputGroup from "../../../../Components/FormComponents/InputGroup";
import Page from "../../../../Components/Page";
import {Checkbox, Divider, FormControlLabel, Icon, List, ListItem, ListItemIcon, ListItemText, MenuItem, Stack, Tab, Tabs, Typography} from "@mui/material";
import DateRangePicker from "../../../../Components/FormComponents/DateRangePicker";
import {CohortData, getAccess, InstituteData, OrganisationAddress, PRIMARY_COLOUR, useCreateCohortRenderer} from "placementt-core";
import {OrganisationContext, UserContext} from "../../../../App";
import {useNavigate} from "react-router-dom";
import IconButtonPop from "../../../../Components/IconButtonPop";
import {AccountTree, CalendarMonth, Delete, Edit, PowerSettingsNew, School} from "@mui/icons-material";
import DeletePopup from "../../../../Components/DeletePopup";
import {UserSearch} from "../../../Shared/ProposePlacement";
import ArchivedCohorts from "./ArchivedCohorts";
import TutorialPage from "../../../Shared/TutorialPage";
import Dropdown from "../../../../Components/FormComponents/Dropdown";


export default function Cohorts({schoolId}:{schoolId?: string}) {
    const cohorts = Object.entries(useContext(OrganisationContext).cohorts as {[key:string]: CohortData}).filter(([, cohort]) => (schoolId ? cohort.schoolId === schoolId : true));
    const schools = useContext(OrganisationContext).schools as {[key:string]: OrganisationAddress};

    const archived = Object.fromEntries(cohorts.filter(([, c]) => c.stage === "archived"));
    const user = useContext(UserContext);
    const institute = useContext(OrganisationContext).details as InstituteData;

    const navigate = useNavigate();

    useEffect(() => {
        const locationArray = location.pathname.split("/");
        if (institute.package === "institutes-two" && !schoolId && Object.keys(Object.fromEntries(cohorts)).includes(locationArray[3])) {
            const targetCohort = Object.fromEntries(cohorts)[locationArray[3]];
            navigate(`/institutes/cohorts/${targetCohort.schoolId}/${locationArray.slice(3).join("/")}`);
        }
        console.log("location", locationArray);
    }, [location.pathname]);

    const items:SubNavBarItems = [
        (!schoolId && user.userGroup === "admin" && cohorts.filter(([key, c]) => c.stage === "created" && (user.userGroup === "admin" || user.viewCohorts === "all" || (user.viewCohorts === "some") && (user.visibleCohorts?.includes(key)))).length > 1) && {
            label: "All students",
            route: "all",
            element: <Cohort cohort={"all"} schoolId={schoolId}/>,
            divider: true,
        },
    ].filter((i) => i) as SubNavBarItems;

    // eslint-disable-next-line no-constant-condition
    if (institute.package === "institutes-two" && !schoolId) {
        items.push(
            ...Object.entries(schools || {}).filter(([, school]) => school.stage === "complete").map(([k, school], i, a) => ({
                label: school.name,
                route: k,
                element: <Cohorts key={"nestedCohorts"+k} schoolId={k}/>,
                divider: i === a.length-1,
            })),
        );
    } else {
        items.push(
            ...[
                ...cohorts.filter(([, c]) => getAccess(user, "createCohorts") && c.stage !== "created" && c.stage !== "archived").map(([k, cohort], i, a) => ({
                    label: <div style={{display: "flex", alignItems: "center"}}><Icon sx={{height: "max-content"}}><Edit sx={{fontSize: "18px"}}/></Icon>{cohort.name}</div>,
                    route: k,
                    element: <NewCohort key={k} initialData={cohort}/>,
                    divider: i === a.length-1,
                })),
                ...cohorts.filter(([key, c]) => c.stage === "created" && (user.userGroup === "admin" || user.viewCohorts === "all" || (user.viewCohorts === "some") && (user.visibleCohorts?.includes(key)))).map(([k, cohort], i, a) => ({
                    label: cohort.name,
                    route: k,
                    element: <Cohort key={k} cohort={cohort} schoolId={schoolId}/>,
                    divider: i === a.length-1,
                })),
                getAccess(user, "createCohorts") && {
                    label: "New cohort",
                    route: "new",
                    element: <NewCohort key={"new"}/>,
                    divider: true,
                },
                user.userGroup === "admin" && {
                    label: Object.entries(archived).length ? "Archive" : "",
                    route: "archive",
                    element: <ArchivedCohorts cohorts={archived}/>,
                },
            ].filter((i) => i)
        );
    }

    return (
        <>
            <TutorialPage pageId="instituteStaffCohorts"/>
            <SubNavBar key={"subnavbar"+schoolId ? 4 : 3} pathLevel={schoolId ? 4 : 3} items={items.length ? items : [{
                label: "",
                route: "noAccess",
                element: <Typography textAlign={"center"} pt={10}>You do not have access to content on this page. Request access from your account administrator</Typography>,
            }]}/>
        </>
    );
}

function NewCohort({initialData}:{initialData?:CohortData}) {
    const [deleteCohortPopup, setDeleteCohortPopup] = useState(false);
    const institute = useContext(OrganisationContext);
    const user = useContext(UserContext);
    const {submitCohort, submitSection, back, setCohortData, cohortData, cohortId, deleteCohort} = useCreateCohortRenderer({oId: user.oId, product: user.product, initialData: initialData});
    const navigate = useNavigate();

    const schools = institute.schools as {[key: string]: OrganisationAddress};

    useEffect(() => {
        if (!cohortId) return;
        navigate("../"+cohortId);
    }, [cohortId]);

    return (
        <Page
            metaTitle="Placementt | Create cohort"
            metaDesc="Create a new Placementt student cohort">
            <FadeInBox visible={cohortData.stage === "info"} cardSx={{overflow: "visible !important"}} cardTitle={<Typography pt={2} textAlign={"center"} variant="h4">Create a <span style={{color: PRIMARY_COLOUR}}>New Cohort</span></Typography>}>
                <Form onSubmit={() => {
                    submitSection({type: "info"});
                }} functionType="sync" submitText="Get started">
                    <Typography textAlign={"center"}>Students are arranged in groups called Cohorts. In your cohorts, you can manage sign-off, daily logs, feedback and analytics related to your placements.</Typography>
                    <Typography variant="h5" pt={2} mb={-2}>Set-up steps</Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon><CalendarMonth color="primary"/></ListItemIcon>
                            <ListItemText
                                primary={"Set up Basic Details"}
                                secondary={"Submission dates, work experience dates."}/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><School color="primary"/></ListItemIcon>
                            <ListItemText
                                primary={"Upload Your Students"}
                                secondary={"Upload students one-time from a CSV file."}/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><AccountTree color="primary"/></ListItemIcon>
                            <ListItemText
                                primary={"Configure Your Workflow"}
                                secondary={"Define sign-off and documentation requirements."}/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><PowerSettingsNew color="primary"/></ListItemIcon>
                            <ListItemText
                                primary={"Activate Students"}
                                secondary={"Once activated, your students can access their accounts and add placements!"}/>
                        </ListItem>
                    </List>
                </Form>
            </FadeInBox>
            <FadeInBox visible={cohortData.stage === "name"} cardSx={{overflow: "visible !important"}} cardTitle={"Cohort name"}>
                <Form onSubmit={(e) => {
                    submitSection(e as CohortData);
                }} functionType="sync" submitText="Continue">
                    <InputGroup name={"name"} required label={"Cohort name"} value={cohortData.name}/>
                    <UserSearch userType="Staff" label={"Designated staff"} name={"designatedStaff"} required defaultUid={institute.details.admin}/>
                    <Typography variant="caption">The designated staff member for this cohort will be the reply contact for each email correspondence.</Typography>
                    <Divider/>
                    <InputGroup name={"autoArchiveDate"} label={"Archive date"} value={cohortData.autoArchiveDate} type={"date"}/>
                    <Typography variant="caption">Tip: Students are billed yearly. Set your archive date before the end of the academic year to auto-disable accounts and prevent unexpected billing.</Typography>
                    <Divider/>
                    {institute.details.package === "institutes-two" && <Dropdown required name="school" label="School" value={cohortData?.schoolId} onChange={(e) => setCohortData((p) => ({...p, schoolId: e.target.value} as CohortData))}>
                        {Object.entries(schools).map(([key, school]) => <MenuItem value={key}>
                            <Stack spacing={0}>
                                <Typography>{school.name}</Typography>
                                <Typography variant="caption">{school?.["address-line1"]} | {school?.postal_code}</Typography>
                            </Stack>
                        </MenuItem>)}
                    </Dropdown>}
                </Form>
            </FadeInBox>
            <FadeInBox visible={cohortData.stage === "placementType"} cardTitle={["Placement Dates", <IconButtonPop title="Delete cohort" onClick={() => setDeleteCohortPopup(true)}><Delete/></IconButtonPop>]}>
                <Form onSubmit={(e) => submitSection(e as CohortData)} functionType="sync" onBackPress={back} submitText="Continue">
                    <Tabs
                        value={["defined", "continuous"].indexOf(cohortData?.placementType || "defined")}
                        onChange={(_, n) => setCohortData((p) => ({...p, placementType: (["defined", "continuous"][n])} as CohortData))}
                        centered
                        variant="standard">
                        <Tab label="Defined"/>
                        <Tab label="Continuous"/>
                    </Tabs>
                    {
                        cohortData?.placementType === "defined" ?
                            <>
                                <Typography color={"rgba(0, 0, 0, 0.75)"} textAlign={"center"}>Placements can only be submitted and attended within a defined period of time.</Typography>
                                <DateRangePicker name={"submission"} label="Placement submission dates" required start={cohortData?.startSubmission} end={cohortData?.endSubmission} onChange={(date, type) => setCohortData((p) => ({...p, [`${type}Submission`]: date} as CohortData))}/>
                                <DateRangePicker name={"placements"} label="Available placement dates" required start={cohortData?.startPlacements} end={cohortData?.endPlacements} onChange={(date, type) => setCohortData((p) => ({...p, [`${type}Placements`]: date} as CohortData))}/>
                            </> :
                            <>
                                <Typography color={"rgba(0, 0, 0, 0.75)"} textAlign={"center"}>Placements can be created and attended at any point from a student's account activation.</Typography>
                            </>
                    }
                </Form>
            </FadeInBox>
            <FadeInBox visible={cohortData.stage === "database"} cardSx={{overflow: "visible !important"}} cardTitle={"Placement Database"}>
                <Form onSubmit={(e) => {
                    submitSection(e as CohortData);
                }} functionType="sync" submitText="Continue" onBackPress={back}>
                    <Typography>List placements your employer contacts have uploaded to your students. Schedule when to release them to avoid students taking placements early.</Typography>
                    <FormControlLabel control={<Checkbox name={"enableListings"} checked={cohortData.enableListings}/>} label={"Enable Placement Listings"}/>
                    <InputGroup type={"date"} label={"Release date"} value={cohortData.listingReleaseDate} name={"listingReleaseDate"}/>
                </Form>
            </FadeInBox>
            <FadeInBox visible={cohortData.stage === "review"} cardTitle={["Setup finished", <IconButtonPop title="Delete cohort" onClick={() => setDeleteCohortPopup(true)}><Delete/></IconButtonPop>]}>
                <Typography>Your cohort is ready to go. Click finish to view your cohort. From there, you can activate users, manage student placements and edit cohort details.</Typography>
                <Form onSubmit={submitCohort} onBackPress={back} submitText="Finish"/>
            </FadeInBox>
            <DeletePopup title="draft cohort" onClose={() => setDeleteCohortPopup(false)} open={deleteCohortPopup} onDelete={() => deleteCohort().then(() => navigate("../staff"))}/>
        </Page>
    );
}
