import {Timestamp, where} from "firebase/firestore";
import {UserContext} from "../../App";
import FilterList from "../../Components/FilterList";
import Form from "../../Components/Form";
import InputGroup from "../../Components/FormComponents/InputGroup";
import {Popup} from "../../Components/Popup";
import {ChromePicker} from "react-color";
import {useContext, useEffect, useState} from "react";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {getAccess, getRandomNumber, PRIMARY_COLOUR, ExternalActivity} from "placementt-core";
import DeletePopup from "../../Components/DeletePopup";
import Card from "../../Components/Card";
import {Button, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, CardHeader, CardContent, Typography, Stack, Grid, Divider, Checkbox, FormGroup, FormControlLabel, Popover, Collapse, Chip} from "@mui/material";
import IconButtonPop from "../../Components/IconButtonPop";
import {AddCircle, ArrowForward, Delete, Edit, SquareRounded} from "@mui/icons-material";
import {LoadingButton} from "../../Components/LoadingButton";
import {ClickableCard} from "../../Util/styledComponents";
import Alert from "../../Components/Alert";

export default function ExternalActivities({xs, md, type}:{xs?: number, md?: number, type?: "provider"|"alumni"}) {
    const user = useContext(UserContext);


    const [addActivityPopupOpen, setAddActivityPopupOpen] = useState<{id?: string, activity: Partial<ExternalActivity>}|boolean>(false);

    const [activities, setActivities] = useState<{[key: string]: ExternalActivity}>();

    const {addActivity, editActivity} = getAccess(user, "addActivity", "editActivity");

    const firebaseQuery = new FirebaseQuery();

    useEffect(() => {
        if (location.hash.includes("addActivity")) {
            setAddActivityPopupOpen(true);
        }
    }, [location.hash]);

    useEffect(() => {
        const constraints = user.product === "admin" ? [where("public", "==", true)] : [where("oId", "==", user.oId)];

        firebaseQuery.collectionSnapshot(setActivities, ["externalActivities"], constraints);
    }, []);

    if (!activities) return null;

    if (activities && !Object.keys(activities).length) return <ActivitySetup xs={xs} md={md}/>;

    return (
        <Card title={type ? `${type === "alumni" ? "Alumni" : "Employer"} activities` : "All activities"} secondaryTitle={(addActivity && <Button onClick={() => setAddActivityPopupOpen(true)}>New</Button>)} grid xs={xs || 12} md={md || 4}>
            <FilterList sx={{maxHeight: "300px"}} noSearch noResultsText={`No ${type === "alumni" ? "alumni" : "employer"} activities`} loadMoreIcon={"loaded"} card={false} data={Object.fromEntries(Object.entries(activities).filter(([, i]) => type ? i[type] : true))}>
                {Object.entries(activities).filter(([, i]) => type ? i[type] : true).map(([key, activity]) =>
                    <ListItemButton sx={{borderRadius: 2, borderColor: activity.color, borderWidth: 2, borderStyle: "solid", mb: 1}} key={key} id={key} onClick={() => setAddActivityPopupOpen({id: key, activity: activity})}>
                        <ListItemText
                            primary={<Stack spacing={1} mb={1}>
                                <Typography flexGrow={1}>{activity.title}</Typography>
                                <Chip label={(activity.alumni && activity.provider) ? "Employer and Alumni" : activity.alumni ? "Alumni only" : activity.provider ? "Employer only" : "Private"}/>
                            </Stack>}
                            primaryTypographyProps={{color: activity.color}}
                            secondary={<Stack spacing={0}>
                                <Typography>{activity.description}</Typography>
                                {editActivity && <ListItemIcon sx={{alignSelf: "end"}}><IconButtonPop responsive={false} title={"Edit"} onClick={(e) => {
                                    e.stopPropagation(); setAddActivityPopupOpen({id: key, activity: activity});
                                }}><Edit sx={{color: activity.color}}/></IconButtonPop></ListItemIcon>}
                            </Stack>}
                        />
                    </ListItemButton>
                )}
            </FilterList>
            <ActivityPopup
                open={Boolean(addActivityPopupOpen)}
                onClose={() => setAddActivityPopupOpen(false)}
                itemId={typeof addActivityPopupOpen === "object" ? addActivityPopupOpen.id : undefined}
                item={typeof addActivityPopupOpen === "object" ? addActivityPopupOpen.activity : undefined}/>
        </Card>
    );
}


function ActivityPopup({open, onClose, item, itemId, onSubmit}:{open: boolean, onClose: () => void, item?: Partial<ExternalActivity>, onSubmit?: (id: string, activity: ExternalActivity) => void, itemId?: string}) {
    const firebaseQuery = new FirebaseQuery();
    const user = useContext(UserContext);
    const [color, setColor] = useState<string>(item?.color || PRIMARY_COLOUR);
    const [deleteActivityPopup, setDeleteActivityPopup] = useState<boolean>(false);

    const deleteActivity = getAccess(user, "deleteActivity");

    const [colorAnchorEl, setColorAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setColorAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setColorAnchorEl(null);
    };

    const colorPickerOpen = Boolean(colorAnchorEl);

    const submitActivity = async (e: ExternalActivity) => {
        console.log("ITEM", itemId, e);
        if (!itemId || item?.public) {
            const uploadData = {...e, color: color, created: Timestamp.fromDate(new Date())};

            if (user.product === "admin") {
                uploadData.public = true;
                uploadData.permanent = true;
            } else {
                uploadData.oId = user.oId;
                uploadData.public = false;
                uploadData.permanent = item?.permanent;
                if (item?.permanent) {
                    uploadData.title = item.title;
                }
            }

            onSubmit ? onSubmit(itemId || getRandomNumber(0, 1000000).toString(), uploadData as ExternalActivity) : await firebaseQuery.add(["externalActivities"], uploadData);
        } else {
            onSubmit ? onSubmit(itemId, {...e, color: color}) : await firebaseQuery.update(["externalActivities", itemId], {...e, color: color});
        }
        setTimeout(() => {
            onClose();
        }, 500);
    };

    useEffect(() => {
        if (!open) {
            setTimeout(() => {
                setColor(PRIMARY_COLOUR);
            }, 500);
        } else {
            setColor(item?.color|| PRIMARY_COLOUR);
        }
    }, [open]);

    return (
        <>
            <Popup title={(itemId ? "Edit" : "New") + " activity"} fullWidth maxWidth={"sm"} open={open} onClose={onClose}>
                <Form onSubmit={async (e) => await submitActivity(e as ExternalActivity)}>
                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                        {(user.product !== "admin" && item?.permanent) ? <Typography flex={1} variant="h5" color={item?.color || PRIMARY_COLOUR}>{item?.title}</Typography> : <InputGroup sx={{flex: 1}} value={item?.title} name={"title"} required label={"Title"} placeholder={"Name your activity"}/>}
                        <Button onClick={handleClick} startIcon={<SquareRounded sx={{color: color}}/>}>Color</Button>
                        <Popover
                            id={"activity_color"}
                            open={colorPickerOpen}
                            anchorEl={colorAnchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}>
                            <ChromePicker onChange={(e) => setColor(e.hex)} color={color || PRIMARY_COLOUR}/>
                        </Popover>
                        {itemId && deleteActivity && <IconButtonPop title="Delete" responsive={false} onClick={() => setDeleteActivityPopup(true)} color="error"><Delete/></IconButtonPop>}
                    </Stack>
                    <InputGroup value={item?.description} name={"description"} required label={"Description"} placeholder={"Describe your activity in 1-3 sentences."} multiline minRows={3}/>
                    <Grid container>
                        <Grid item xs={12} md={6} pl={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox key={"alumni"} name={"alumni"} defaultChecked={item?.alumni}/>} label={"Available to alumni"} />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} md={6} pl={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox key={"employers"} name={"provider"} defaultChecked={item?.provider}/>} label={"Available to employers"} />
                            </FormGroup>
                        </Grid>
                    </Grid>
                </Form>

            </Popup>
            {deleteActivity && itemId && <DeletePopup
                title="activity"
                itemName={item?.title}
                open={deleteActivityPopup}
                onClose={() => setDeleteActivityPopup(false)}
                onDelete={async () => deleteActivityPopup ? await firebaseQuery.delete(["externalActivities", itemId]).then(() => {
                    setDeleteActivityPopup(false);
                    onClose();
                }) : null}/>}
        </>
    );
}

function ActivitySetup({xs, md}:{md?: number, xs?: number}) {
    const [activitySetupOpen, setActivitySetupOpen] = useState<boolean>(false);
    const [activities, setActivities] = useState<{[key: string]: ExternalActivity}>();
    const [addActivityPopupOpen, setAddActivityPopupOpen] = useState<{id?: string, activity: Partial<ExternalActivity>}|boolean>(false);
    const [chosenActivities, setChosenActivities] = useState<{[key: string]: ExternalActivity}>();
    const [closeAreYouSurePopup, setCloseAreYouSurePopup] = useState<boolean>(false);

    const hash = location.hash;
    useEffect(() => {
        if (hash === "#activities") {
            alert("Set activities open");
            setActivitySetupOpen(true);
        }
    }, []);

    const firebaseQuery = new FirebaseQuery();
    const user = useContext(UserContext);


    useEffect(() => {
        firebaseQuery.getDocsWhere(["externalActivities"], where("public", "==", true)).then((e: any) => {
            setActivities((a) => ({...e, ...a}));
            setChosenActivities((a) => ({...e, ...a}));
        });
    }, []);

    const submitActivities = async () => {
        console.log("chosenActivities", chosenActivities);
        Object.values(chosenActivities || {}).map(async (activity) => {
            await firebaseQuery.add(["externalActivities"], {...activity, oId: user.oId, public: user.product === "admin"});
        });
        setActivitySetupOpen(false);
    };

    return (<><Grid item {...{xs, md}}><ClickableCard onClick={() => setActivitySetupOpen(true)} sx={{"background-image": "linear-gradient( 150deg, hsl(265deg 96% 38%) 0%, hsl(259deg 88% 44%) 17%, hsl(261deg 85% 47%) 34%, hsl(269deg 84% 47%) 50%, hsl(287deg 86% 51%) 67%, hsl(293deg 94% 53%) 83%, hsl(291deg 100% 50%) 100% )"}}>
        <CardHeader sx={{color: "white !important"}} title={"Set up activities"}/>
        <CardContent>
            <Stack>
                <Typography sx={{color: "white !important"}}>We'll ask employers and alumni tied to your Placementt account what activities they are interested in providing, so you have the information later.</Typography>
                <Button color="white" onClick={() => setActivitySetupOpen(true)}>Get started</Button>
            </Stack>
        </CardContent>
    </ClickableCard>
    </Grid>
    <Popup title={"Close activity setup?"} open={closeAreYouSurePopup} onClose={() => setCloseAreYouSurePopup(false)}>
        <Typography>Closing this setup window will mean your activities are not saved. Would you like to save them?</Typography>
        <Stack direction={"row"} mt={3} width={"100%"} justifyContent={"space-around"}>
            <Button color="error" onClick={() => {
                setActivitySetupOpen(false);
                setCloseAreYouSurePopup(false);
            }}>Close setup</Button>
            <Button variant="contained" onClick={submitActivities}>Save and close</Button>
        </Stack>
    </Popup>
    <Popup
        open={activitySetupOpen}
        onClose={() => JSON.stringify(chosenActivities) !== JSON.stringify(activities) ? setCloseAreYouSurePopup(true) : setActivitySetupOpen(false)}
        title={"Set up your activities"}
        maxWidth={"md"}
        fullWidth
        actions={<Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} width={"100%"}>
            <Collapse in={JSON.stringify(chosenActivities) !== JSON.stringify(activities)}><Alert severity="info">Your changes have not yet been saved. Click "Submit" to save them</Alert></Collapse>
            <LoadingButton variant="contained" noFlexGrow onClick={submitActivities} endIcon={<ArrowForward sx={{fontSize: "20px"}}/>} text="Submit"/>
        </Stack>}>
        <Stack>
            <Typography variant="h6">We will ask all alumni and employers who interact with your school through Placementt what events they may be interested in engaging in.</Typography>
            <Typography sx={{opacity: 0.7}}>We are working on tools for events management through Placementt, but right now, we're just collecting this information for you.</Typography>            <Alert severity="primary">We haven't yet released our alumni engagement tool, but we will very soon. If you are interested and we haven't discussed this with you, please get in touch with Tom at tom@placementt.co.uk.</Alert>

            <Divider/>
            <Typography variant="h5">Here is what we ask alumni and employers:</Typography>
            <Grid container>
                {Object.entries({...(activities || {}), ...(chosenActivities || {})}).map((([key, activity]) => <Grid item md={6} xs={12}><ListItemButton sx={{borderRadius: 2, borderColor: chosenActivities?.[key]?.color || "rgba(0, 0, 0, 0.54)", borderWidth: 2, borderStyle: "solid", mb: 1}} key={key} id={key} onClick={() => setAddActivityPopupOpen({id: key, activity: activity})}>
                    <ListItemText
                        sx={{pr: 12}}
                        primary={(chosenActivities?.[key] || activity).title}
                        primaryTypographyProps={{color: chosenActivities?.[key] ? chosenActivities?.[key]?.color : "rgba(0, 0, 0, 0.54)"}}
                        secondary={(chosenActivities?.[key] || activity).description}
                    />
                    <ListItemSecondaryAction sx={{pr: 0, right: 0}}>
                        {[
                            chosenActivities?.[key] ? <ListItemIcon><IconButtonPop responsive={false} title={"Edit"} onClick={(e) => {
                                e.stopPropagation(); setAddActivityPopupOpen({id: key, activity: (chosenActivities?.[key] || activity)});
                            }}><Edit sx={{color: (chosenActivities?.[key]?.color || undefined)}}/></IconButtonPop></ListItemIcon> : null,
                            <ListItemIcon><IconButtonPop title={chosenActivities?.[key] ? "Remove" : "Add"} responsive={false} onClick={(e) => {
                                e.stopPropagation();
                                setChosenActivities((a) => {
                                    if (!a) return {[key]: activity};
                                    const newA = {...a};
                                    if (newA[key]) {
                                        delete newA[key];
                                        return newA;
                                    }
                                    return {...newA, [key]: activity};
                                });
                            }}>{chosenActivities?.[key] ? <Delete sx={{color: (chosenActivities?.[key]?.color || undefined)}}/> : <AddCircle/>}</IconButtonPop></ListItemIcon>,
                        ]}
                    </ListItemSecondaryAction>
                </ListItemButton></Grid>))}
                <Grid item md={6} xs={12}><ListItemButton sx={{borderRadius: 2, borderColor: PRIMARY_COLOUR, borderWidth: 2, borderStyle: "solid", mb: 1}} key={"new"} id={"new"} onClick={() => setAddActivityPopupOpen(true)}>
                    <ListItemText
                        sx={{pr: 12}}
                        primary={"New activity"}
                        primaryTypographyProps={{color: PRIMARY_COLOUR}}
                        secondary={"Create a new activity that your alumni and employers could sign up for."}
                    />
                    <ListItemSecondaryAction sx={{pr: 0, right: 0}}>
                        {[
                            <ListItemIcon><IconButtonPop responsive={false} title={"Add"} onClick={(e) => {
                                e.stopPropagation(); setAddActivityPopupOpen(true);
                            }}><AddCircle sx={{color: PRIMARY_COLOUR}}/></IconButtonPop></ListItemIcon>,
                        ]}
                    </ListItemSecondaryAction>
                </ListItemButton></Grid>
            </Grid>
        </Stack>
    </Popup>
    <ActivityPopup open={Boolean(addActivityPopupOpen)} itemId={typeof addActivityPopupOpen === "object" ? addActivityPopupOpen.id : undefined}
        item={typeof addActivityPopupOpen === "object" ? addActivityPopupOpen.activity : undefined} onClose={() => setAddActivityPopupOpen(false)} onSubmit={(id, activity) => setChosenActivities((a) => ({...a, [id]: {...(a?.[id] || {}), ...activity}}))}/>
    </>);
}
