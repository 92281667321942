import {ReactNode, useContext, useEffect, useState} from "react";
import {OrganisationContext, UserContext} from "../../App";
import {Box, Button, css, Divider, IconButton, keyframes, Stack, Typography} from "@mui/material";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {arrayUnion} from "@firebase/firestore";
import {INSTITUTE_COLOUR, InstituteData, PRIMARY_COLOUR, SECONDARY_COLOUR} from "placementt-core";
import {ArrowForward, ChevronLeftRounded, ChevronRightRounded, Close} from "@mui/icons-material";
import formBuilderVideo from "../../Images/Form builder tutorial.mp4";
import IconButtonPop from "../../Components/IconButtonPop";
import styled from "styled-components";
import {Popup} from "../../Components/Popup";
import {useNavigate} from "react-router-dom";
import Card from "../../Components/Card";
import {ProfilePic} from "../Providers/Home";
import CustomColourPicker from "../../Components/CustomColourPicker";


const scaleAnimation = keyframes`
0%, 100% {
    transform: scale(1);
}
50% {
    transform: scale(1.5);
}
`;

export const AnimatedArrow = styled(ArrowForward)`
${() => css`
    animation: ${scaleAnimation} 2s infinite;
`}
`;

export const AnimatedRightChevron = styled(ChevronRightRounded)`
${() => css`
    animation: ${scaleAnimation} 2s infinite;
`}
`;

const popups = ["instituteStaffActivities"];

export default function TutorialPage({pageId, card}: {pageId: "profilePicture"|"alumniConversations"|"instituteStaffActivities"|"instituteStaffHome"|"instituteStudentHome"|"instituteStaffPlacements"|"instituteStaffCohorts"|"formBuilder"|"providerStaff"|"providerRegistrationRequests", card?: boolean}) {
    const user = useContext(UserContext);
    const firebaseQuery = new FirebaseQuery();
    const navigate = useNavigate();

    const pages:{[key: string]: {title: ReactNode, message: ReactNode, type?: string, skippable?: boolean, button?: string, zIndex?: number}[]} = {
        instituteStudentHome: [{
            title: <>Welcome to <span style={{color: PRIMARY_COLOUR}}>Placementt</span></>,
            message: "You have been added to Placementt so you can log your work experience and track your progress.",
        }, {
            title: <>Welcome to <span style={{color: PRIMARY_COLOUR}}>Placementt</span></>,
            message: "Through Placementt, you can enter details of any placement you attend, keep a record of personal notes on placements, and view any guidance documents uploaded by your school.",
        }, {
            title: <>Welcome to <span style={{color: PRIMARY_COLOUR}}>Placementt</span></>,
            message: "Click the green tick below to view your dashboard. You can add placements by clicking the \"Create\" button on the left. Only do this once you have consent from an employer.",
        }],
        instituteStaffHome: [{
            title: <>Welcome to <span style={{color: PRIMARY_COLOUR}}>Placementt</span></>,
            message: "With placementt, you can seamlessly manage your work experience programme and build a comprehensive database of opportunities for students to browse.",
        },
        {
            title: <>Create <span style={{color: INSTITUTE_COLOUR}}>student cohorts</span></>,
            message: <>Manage staff and students through the <span style={{fontWeight: "bold", color: INSTITUTE_COLOUR}}>Cohorts</span> tab on the left. This is where you will view upcoming student placements, analyse progress and manage your staff.</>,
        },
        {
            title: <>Build your <span style={{color: INSTITUTE_COLOUR}}>placement database</span></>,
            message: <>The <span style={{fontWeight: "bold", color: INSTITUTE_COLOUR}}>Placements</span> tab on the left contains your personal database, where we have multiple options for you to build your contacts.</>,
        },
        {
            title: <>Your <span style={{color: PRIMARY_COLOUR}}>homepage</span></>,
            message: "We keep your homepage simple. Our tasks box tells you everything you need to know. If that's empty, you can relax. You can also view active placements and an interactive map.",
        }],
        instituteStaffPlacements: [{
            title: <>Your <span style={{color: INSTITUTE_COLOUR}}>placement database</span></>,
            message: "Build a vast database of opportunities for your students. Placementt provides tools to build up your database of businesses year on year, with comprehensive, up to date placement information.",
        },
        {
            title: <>Manually add placements</>,
            message: "Kick start your placement database by upload a list of businesses you currently know provide placements. Once you upload these, we will contact them for you to confirm and ask for placement details.",
        },
        {
            title: <>External business uploads</>,
            message: "Click 'Allow external business uploads' on the right hand side to view a sharable link through which any business can advertise their placement. You will of course have final say in which placemments are approved.",
        },
        {
            title: <>Yearly database growth</>,
            message: "Year on year, we will ask every placement a student attends if they will take students in the future. This means you can build up your placement database year on year to hundreds of placements.",
        },
        {
            title: <>We'll manage the admin</>,
            message: "We will automatically ask every business in your database for comprehensive placement information that we can show your students. This will increase placement quality as students know exactly what to expect.",
        }],
        instituteStaffCohorts: [{
            title: <>Your <span style={{color: INSTITUTE_COLOUR}}>Cohorts</span> page</>,
            message: "Manage staff and students through the cohorts page. Create a cohort for any group of students to easily manage your users and delegate students between staff.",
        },
        {
            title: <>Manage your <span style={{color: INSTITUTE_COLOUR}}>staff</span></>,
            message: <>View and upload staff in the <strong>Staff</strong> tab along the top. Uploaded staaff and activate them by selecting the user and clicking the tick icon above the table. Assign staff to specific cohorts the same way, so they can only see and manage certain students.</>,
        },
        {
            title: <>Creating <span style={{color: INSTITUTE_COLOUR}}>cohorts</span></>,
            message: <>Click the <strong>New cohort</strong> tab along the top to create a cohort. Upload your users and create a custom workflow to process your placements.</>,
        },
        {
            title: <>Manage your <span style={{color: INSTITUTE_COLOUR}}>placements</span></>,
            message: <>View each cohort by clicking the name at the top. Each cohort has a <strong>Tasks</strong>, and different tabs to view students, their scheduled placements, and analytics. Click the settings page to edit the workflow and other details.</>,
        }],
        providerStaff: [
            {
                title: "Add staff to spread your workload.",
                message: "Staff can be added manually or can request to join.",
            },
        ],
        formBuilder: [{
            title: <>Welcome to our <span style={{color: INSTITUTE_COLOUR}}>Form Builder</span></>,
            type: "video",
            message:
                <VideoTutorial
                    description="Build custom forms to include in your workflow. Forms are automatically sent out to required groups, for you to review later."
                    video={formBuilderVideo}/>,
        }],
        providerRegistrationRequests: [
            {
                title: "Enable registration requests",
                message: "Ease the burden of adding your staff by enabling registration requests",
            },
            {
                title: "Securely and scalably add users",
                message: "Share a custom link with your staff, allowing them to request access. You can then review and activate each user from here.",
            },
        ],
        alumniConversations: [
            {
                title: <span style={{color: INSTITUTE_COLOUR}}>Alumni Conversations</span>,
                message: "Connect your students to your alumni, allowing them to ask careers-related questions.",
            },
            {
                title: <span style={{color: INSTITUTE_COLOUR}}>Simple set up</span>,
                message: <ol>
                    <li>We will give you a <strong style={{color: INSTITUTE_COLOUR}}>custom link</strong> to share with students.</li>
                    <li>Students view the link and input their <strong style={{color: INSTITUTE_COLOUR}}>school email address.</strong></li>
                    <li>Each student will receive a <strong style={{color: INSTITUTE_COLOUR}}>secure link</strong> to the alumni page.</li>
                    <li>Students can <strong style={{color: INSTITUTE_COLOUR}}>send messages to alumni</strong> through Placementt.</li>
                </ol>,
            },
        ],
        profilePicture: [{
            title: <><span style={{color: PRIMARY_COLOUR}}>Customise</span> Your Account</>,
            message: <AddInstituteBrandingTutorial/>,
            skippable: true,
            button: "Save",
            zIndex: 1000,
        }],
        instituteStaffActivities: [
            {
                title: <span>Get More Out of Your Employers with <span style={{color: INSTITUTE_COLOUR}}>Activities</span></span>,
                message: "",
            },
            {
                title: <span>Add Activities for Employers to Sign Up to</span>,
                message: "Set up activities using our examples or add your own.",
            },
            {
                title: <span>Automated Employer Sign Ups to Your Activities</span>,
                message: "We will show your activities to every employer and ask them to which they could do.",
            },
            {
                title: <span>No Hard Committments</span>,
                message: "Don't worry, we are not asking employers to commit, we instead build a picture of what events employers may be interested in.",
            },
            {
                title: <span>Set Up Activities Now</span>,
                message: <Stack alignItems={"center"}>
                    <Typography>(It only takes 2 minutes)</Typography>
                    <Button onClick={() => {
                        nextStep();
                        navigate("/institutes/network/employers#activities");
                    }}>Get Started</Button>
                </Stack>,
            },
        ],
    };

    const page = pages[pageId];


    const [step, setStep] = useState(0);

    const isPopup = popups.includes(pageId);

    if (user?.dismissedTutorials?.includes(pageId) && !isPopup) {
        return null;
    }

    const prevStep = () => {
        setStep((s) => s-1 >= 0 ? s-1 : 0);
    };

    const nextStep = () => {
        if (step === page.length - 1) {
            return firebaseQuery.update(["users", user.id], {dismissedTutorials: arrayUnion(pageId)});
        }
        setStep((s) => s+1);
    };

    if (card) {
        return (
            <Stack position={"absolute"} display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} sx={{background: "white"}} height={"100%"} width={"100%"} left={0} top={0} zIndex={page[step].zIndex || 10}>
                <Card>
                    <Stack spacing={5} pl={1} pr={1}>
                        <Typography variant="h4" fontWeight={"bold"} textAlign={"center"} mb={5}>{page[step].title}</Typography>
                        {!page[step]?.type ? <Typography variant="subtitle1" textAlign={"center"} width={"500px"} maxWidth={"95%"}>{page[step].message}</Typography> : page[step].message}
                        <Stack direction={"row"} alignItems={"center"} justifyContent={page[step].skippable ?"space-between" : "center"} width={"500px"} maxWidth={"95%"}>
                            {page[step].skippable && <Button variant="text" onClick={() => firebaseQuery.update(["users", user.id], {dismissedTutorials: arrayUnion(pageId)})}>Skip</Button>}
                            <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                <IconButton disabled={step === 0} onClick={prevStep} sx={{marginRight: "1px !important", transition: "all 250ms ease-in-out", opacity: step === 0 ? 0 : 1}}><ChevronLeftRounded/></IconButton>
                                {page.length > 1 && page.map((_, i) => <Box height={10} width={10} borderRadius={10} sx={{transition: "all 250ms ease-in-out"}} bgcolor={step === i ? SECONDARY_COLOUR : step > i ? PRIMARY_COLOUR : "lightgray"}/>)}
                                <IconButtonPop variant="contained" responsive={Boolean(page[step].button)} title={page[step].button || "Continue"} disabled={step === page.length} onClick={nextStep}>{step === page.length - 1 ? <AnimatedArrow color="primary"/> : <AnimatedRightChevron/>}</IconButtonPop>
                            </Stack>
                        </Stack>
                    </Stack>
                </Card>
            </Stack>
        );
    }

    const item = <Stack spacing={5} margin={"auto"} position={isPopup ? undefined : "absolute"} display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} sx={{background: "white"}} height={"100%"} minHeight={isPopup ? "500px" : undefined} width={"100%"} maxWidth={isPopup ? "500px" : undefined} left={0} top={0} zIndex={10}>
        <Stack flex={isPopup ? 1 : 0} justifyContent={"center"}>
            <Typography variant="h4" fontWeight={"bold"} textAlign={"center"} mb={5}>{page[step].title}</Typography>
            {!page[step]?.type ? <Typography variant="subtitle1" textAlign={"center"} width={"500px"} maxWidth={"95%"}>{page[step].message}</Typography> : page[step].message}
        </Stack>
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
            <IconButton disabled={step === 0} onClick={prevStep} sx={{marginRight: "1px !important", transition: "all 250ms ease-in-out", opacity: step === 0 ? 0 : 1}}><ChevronLeftRounded/></IconButton>
            {page.map((_, i) => <Box height={10} width={10} borderRadius={10} sx={{transition: "all 250ms ease-in-out"}} bgcolor={step === i ? SECONDARY_COLOUR : step > i ? PRIMARY_COLOUR : "lightgray"}/>)}
            <IconButton sx={{color: `${PRIMARY_COLOUR} !important`}} disabled={step === page.length} onClick={nextStep}>{step === page.length - 1 ? <AnimatedArrow/> : <AnimatedRightChevron/>}</IconButton>
        </Stack>
    </Stack>;

    if (isPopup) {
        return (
            <Popup open={!user?.dismissedTutorials?.includes(pageId)} onClose={() => firebaseQuery.update(["users", user.id], {dismissedTutorials: arrayUnion(pageId)})} maxWidth={"md"} fullWidth>
                {item}
            </Popup>
        );
    }
    return item;
}


function VideoTutorial({description, buttonText="View tutorial", video}:{description: string, buttonText?: string, video: any}) {
    const [showVideo, setShowVideo] = useState(false);

    if (showVideo) {
        return (
            <Box textAlign={"right"} width={"800px"} maxWidth={"95%"}>
                <IconButtonPop title="Close" responsive={false} onClick={() => setShowVideo(false)}><Close/></IconButtonPop>
                <video className='videoTag' controls width={"100%"}>
                    <source src={video} type='video/mp4' />
                </video>
            </Box>
        );
    }

    return (
        <Stack width={"500px"} maxWidth={"95%"} alignItems={"center"} spacing={5}>
            <Typography textAlign={"center"}>{description}</Typography>
            <Button variant="contained" color="info" onClick={() => setShowVideo(true)}>{buttonText}</Button>
        </Stack>
    );
}

function AddInstituteBrandingTutorial() {
    const user = useContext(UserContext);
    const institute = useContext(OrganisationContext).details as InstituteData;
    const firebaseQuery = new FirebaseQuery();

    const [color, setColor] = useState(institute.color || PRIMARY_COLOUR); // Initial color
    const [debouncedColor, setDebouncedColor] = useState(color);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedColor(color);
        }, 2000);

        return () => clearTimeout(handler); // Clear timeout if color changes
    }, [color]);

    useEffect(() => {
        if (debouncedColor !== color) return; // Ensure it's the latest stable value
        firebaseQuery.update(["institutes", user.oId], {color: debouncedColor});
    }, [debouncedColor]);

    const handleColorChange = (newColor: string) => {
        setColor(newColor);
    };


    return (
        <Stack mt={-2}>
            <Typography>We will customise all emails to students, employers and parents with your branding.</Typography>
            <Divider/>
            <Stack direction={"row"}>
                <Stack spacing={1}>
                    <ProfilePic size="150px" text={"Upload school logo"}/>
                </Stack>
                <Stack alignItems={"start"} textAlign={"start"} pl={2}>
                    <Typography variant="h5" color={color}>{institute.name}</Typography>
                    <Stack flex={1} spacing={1} alignItems={"center"}>
                        <Typography>Choose a colour for our emails to users.</Typography>
                        <CustomColourPicker color={color} onColorChange={handleColorChange}/>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
}
