import {List} from "@mui/material";
import Page from "../../../Components/Page";
import {useState} from "react";
import Card from "../../../Components/Card";
import {Popup} from "../../../Components/Popup";


export default function BusinessUsers() {
    const [rejectELIPopup, setRejectELIPopup] = useState<string>();


    // useEffect(() => {
    //     firebaseQuery.getDocsWhere("providers", [where("insurance", "==", "awaitingReview"), orderBy("admin")]).then(async (docs) => {
    //         const finalDocs = Object.fromEntries(await Promise.all(
    //             Object.entries(docs as {[key: string]: ProviderData}).map(async ([key, data]) => {
    //                 const downloadURL = await getDownloadURL(ref(storage, `insurance/${key}.pdf`));
    //                 return [key, {...data, downloadURL: downloadURL}];
    //             })
    //         ));

    //         setCheckInsurance(finalDocs);
    //     });
    // }, []);

    // const submitInsuranceReview = async (providerContactId: string, outcome: "accept"|"reject", reason?: string) => {
    //     if (outcome === "accept") {
    //         await executeCallable("insurance-approve", {providerContactId: providerContactId});
    //         return;
    //     }

    //     if (outcome === "reject") {
    //         await executeCallable("insurance-reject", {reason: reason, providerContactId: providerContactId});
    //         setRejectELIPopup(undefined);
    //     }
    // };

    return (
        <Page title="Business Users" grid>
            <Card title={"Insurance to review"} grid xs={12} md={4}>
                <List>
                    {/* {checkInsurance ? Object.entries(checkInsurance).map(([providerContactId, providerContact]) =>
                        <ListItem id={providerContactId}>
                            <ListItemText
                                primary={provider.name}
                                secondary={<Link target={"_blank"} href={provider.downloadURL}>View insurance doc</Link>}
                            />
                            <ListItemSecondaryAction>
                                <LoadingButton>
                                    <Button onClick={() => submitInsuranceReview(providerContactId, "accept")} variant="contained">Accept</Button>
                                    <Button onClick={() => setRejectELIPopup(providerContactId)} color="error">Reject</Button>
                                </LoadingButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ) : <Preloader visible/>} */}
                </List>
            </Card>
            <Popup title={"Reject insurance"} open={Boolean(rejectELIPopup)} onClose={() => setRejectELIPopup(undefined)}>
                a
                {/* <Typography>Rejecting this employers liability insurance will require them to reupload. You can check back here once it is checked.</Typography>
                <Form onSubmit={async (e) => submitInsuranceReview(rejectELIPopup as string, "reject", (e as {reason:string}).reason)}>
                    <InputGroup label="Rejection reason" name={"reason"} required/>
                </Form> */}
            </Popup>
        </Page>
    );
}
