import {useContext, useEffect, useState} from "react";
import {UserContext} from "../../App";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {where} from "firebase/firestore";
import {convertDate, PRIMARY_COLOUR, SECONDARY_COLOUR, storage, Update} from "placementt-core";
import {Popup} from "../../Components/Popup";
import {Box, Button, IconButton, Stack, Typography} from "@mui/material";
import {ChevronLeftRounded} from "@mui/icons-material";
import {serialiseSlate} from "../../Util/util";
import {getDownloadURL, ref} from "firebase/storage";
import {useNavigate} from "react-router-dom";
import {AnimatedArrow, AnimatedRightChevron} from "./TutorialPage";


export default function GetUpdates() {
    const [updates, setUpdates] = useState<{[key: string]: Update&{image?: string}}>();
    const [step, setStep] = useState(0);
    const navigate = useNavigate();
    const user = useContext(UserContext);
    const firebaseQuery = new FirebaseQuery();

    const prevStep = () => {
        setStep((s) => s-1 >= 0 ? s-1 : 0);
    };

    const nextStep = () => {
        if (!updates) return;

        if (!user.dismissedUpdateDate || (user.dismissedUpdateDate < Object.values(updates)[step].date)) {
            firebaseQuery.update(["users", user.id], {dismissedUpdateDate: Object.values(updates)[step].date});
        }
        setStep((s) => s+1);
        if (step === Object.keys(updates).length - 1) {
            setUpdates(undefined);
        }
    };


    useEffect(() => {
        firebaseQuery.getDocsWhere(["updates"], [
            where("product", "==", user.product),
            where("userType", "==", user.userType),
            where("date", "<=", convertDate(new Date(), "dbstring")),
            where("date", ">", user.dismissedUpdateDate || "2025-01-01")])
            .then(async (e) => {
                const updatesWithImages = await Promise.all(Object.entries(e as {[key: string]: Update}).map(async ([k, u]) => {
                    if (!u.imageData) {
                        return [k, u];
                    }
                    const imageURL = await getDownloadURL(ref(storage, `updates/${k}.png`));
                    return [k, {...u, image: imageURL}];
                }));
                setUpdates(Object.fromEntries(updatesWithImages));
            });
    }, []);

    const selectedUpdate = updates && Object.values(updates)[step];

    return (
        <Popup maxWidth={"md"} title={`Update${Object.keys(updates || {}).length > 1 ? "s" : ""}: ${convertDate(new Date(), "visual")}`} fullWidth open={Boolean(updates && Object.keys(updates).length)} onClose={() => {
            updates && firebaseQuery.update(["users", user.id], {dismissedUpdateDate: selectedUpdate?.date});
            setUpdates(undefined);
        }}>
            {selectedUpdate && <Stack alignItems={"center"}>
                <Typography variant="h4" color={PRIMARY_COLOUR} fontWeight={"500"}>{selectedUpdate?.title}</Typography>
                <Typography fontSize={"1.2em"}>{serialiseSlate(selectedUpdate?.description)}</Typography>
                {selectedUpdate.image && <Box maxHeight={"300px"} maxWidth={"90%"} sx={{boxShadow: "2px 2px 10px 0px #0000007f"}} borderRadius={2} component={"img"} src={selectedUpdate?.image}/>}
                {selectedUpdate.buttonText && selectedUpdate.buttonLink && <Button sx={{marginTop: "28px !important"}} variant="contained" size="large" onClick={() => {
                    updates && firebaseQuery.update(["users", user.id], {dismissedUpdateDate: selectedUpdate?.date});
                    setUpdates(undefined);
                    navigate(selectedUpdate.buttonLink as string);
                }}>{selectedUpdate.buttonText}</Button>}
                {Object.keys(updates).length > 1 ? <Stack direction={"row"} spacing={2} alignItems={"center"}>
                    <IconButton disabled={step === 0} onClick={prevStep} sx={{marginRight: "1px !important", transition: "all 250ms ease-in-out", opacity: step === 0 ? 0 : 1, borderRadius: 2, cursor: "pointer"}}><ChevronLeftRounded/><Typography>Prev</Typography></IconButton>
                    {updates && Object.keys(updates).length > 1 && Object.keys(updates).map((_, i) => <Box height={10} width={10} borderRadius={10} sx={{transition: "all 250ms ease-in-out"}} bgcolor={step === i ? SECONDARY_COLOUR : step > i ? PRIMARY_COLOUR : "lightgray"}/>)}
                    <IconButton disabled={step === Object.keys(updates).length} onClick={nextStep} sx={{borderRadius: 2}}><Typography>Next</Typography> {step === Object.keys(updates).length - 1 ? <AnimatedArrow color="primary"/> : <AnimatedRightChevron/>}</IconButton>
                </Stack> : <Button variant="text" onClick={nextStep}>Dismiss</Button>}
            </Stack>}
        </Popup>
    );
}
