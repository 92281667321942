import {Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, MenuItem, Stack, Typography} from "@mui/material";
import Page from "../../Components/Page";
import {useParams} from "react-router-dom";
import {ChangeEvent, useEffect, useState} from "react";
import {Alumni, executeCallable, ExternalActivity, PRIMARY_COLOUR} from "placementt-core";
import FadeInBox from "../../Components/FadeInBox";
import Preloader from "../../Components/Preloader";
import Form, {FormButton} from "../../Components/Form";
import InputGroup from "../../Components/FormComponents/InputGroup";
import {Popup} from "../../Components/Popup";
import {SectorSelector} from "../../Components/SectorSelector";
import Dropdown from "../../Components/FormComponents/Dropdown";


export default function AlumniRequest() {
    const {oId, schoolId} = useParams();

    type AlumniInstituteData = {
        instituteName: string,
        primaryColor?: string,
        primaryImage?: string,
        activities: {[key: string]: ExternalActivity}
    }

    const [page, setPage] = useState<"loading"|"basicDetails"|"activities"|"profile"|"submitting"|"complete">("loading");
    const [instituteData, setInstituteData] = useState<AlumniInstituteData>();
    const [alumniDetails, setAlumniDetails] = useState<Partial<Alumni>>();
    const [skipProfilePopup, setSkipProfilePopup] = useState(false);


    useEffect(() => {
        executeCallable("alumni-getAlumniRequestData", {oId: oId, schoolId: schoolId}).then((data) => {
            setInstituteData(data.data as AlumniInstituteData);
            setPage("basicDetails");
        });
    }, [oId, schoolId]);

    useEffect(() => {
        console.log(alumniDetails);
    }, [alumniDetails]);


    const submitAlumniRequest = async () => {
        setPage("submitting");
        const res = await executeCallable("alumni-submitAlumniRequest", {oId: oId, schoolId: schoolId, alumni: alumniDetails});
        console.log("RES", res);
        setPage("complete");
    };

    return (
        <Page metaTitle={"Join Alumni | "+(instituteData?.instituteName || "Placementt")}>
            <FadeInBox card visible={page === "loading"}>
                <Typography variant="h4">Fetching school details</Typography>
                <Typography variant='h6'>This won't take long...</Typography>
                <Preloader visible/>
            </FadeInBox>
            <FadeInBox card visible={Boolean(page === "basicDetails" && instituteData)}>
                <Stack alignItems={"center"}>
                    {instituteData?.primaryImage && <Box component={"img"} sx={{width: "100px", height: "100px", mt: 2, mb: 2}} src={instituteData?.primaryImage}/>}
                    <Typography variant='h4' color={instituteData?.primaryColor || PRIMARY_COLOUR}>{instituteData?.instituteName}</Typography>
                    <Typography variant="h5">Join our alumni network</Typography>
                    <Typography variant="h6" textAlign={"center"}>Join us to stay connected: we'll track your career progress, invite you to events, and share opportunities to engage with our students.</Typography>
                    <Form submitText="Continue" onSubmit={() => setPage("activities")}>
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <InputGroup name={"forename"} value={alumniDetails?.forename} onChange={(e: ChangeEvent<HTMLInputElement>) => setAlumniDetails((a) => ({...a, forename: e.target.value}))} required label={"Forename"} placeholder={"John"}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputGroup name={"surname"} value={alumniDetails?.surname} onChange={(e: ChangeEvent<HTMLInputElement>) => setAlumniDetails((a) => ({...a, surname: e.target.value}))} required label={"Surname"} placeholder={"Smith"}/>
                            </Grid>
                            <Grid item xs={12}>
                                <InputGroup name={"email"} value={alumniDetails?.email} onChange={(e: ChangeEvent<HTMLInputElement>) => setAlumniDetails((a) => ({...a, email: e.target.value}))} required label={"Email"} type={"email"} placeholder={"john.smith@gmail.com"}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputGroup name={"startYear"} value={alumniDetails?.startYear} onChange={(e: ChangeEvent<HTMLInputElement>) => setAlumniDetails((a) => ({...a, startYear: e.target.value}))} required type={"number"} min={1900} max={2100} label={"Year started school"} placeholder={"2011"}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputGroup name={"endYear"} value={alumniDetails?.endYear} onChange={(e: ChangeEvent<HTMLInputElement>) => setAlumniDetails((a) => ({...a, endYear: e.target.value}))} required type={"number"} min={1900} max={2100} label={"Year left school"} placeholder={"2018"}/>
                            </Grid>
                        </Grid>
                    </Form>
                </Stack>
            </FadeInBox>
            <FadeInBox card visible={Boolean(page === "activities" && instituteData)}>
                <Stack alignItems={"center"}>
                    {instituteData?.primaryImage && <Box component={"img"} sx={{width: "100px", height: "100px", mt: 2, mb: 2}} src={instituteData?.primaryImage}/>}
                    <Typography variant='h4' color={instituteData?.primaryColor || PRIMARY_COLOUR}>{instituteData?.instituteName}</Typography>
                    <Typography variant="h5">Thanks for joining, {alumniDetails?.forename}</Typography>
                    <Typography textAlign={"center"}>We aim to provide lots of opportunities for students to engage with alumni and employers, to support their careers.</Typography>
                    <Typography>Please select what activities you might be interested to engage in. <strong style={{color: instituteData?.primaryColor}}>This is no committment now</strong>, we will contact you later when we arrange events.</Typography>
                    <Form submitText="Next" onSubmit={() => setPage("profile")}>
                        <Grid container>
                            {Object.entries(instituteData?.activities || {}).map(([activityId, activity]) =>
                                <Grid item xs={12} md={6} key={activityId}>
                                    <Stack direction={"row"} spacing={1} alignItems={"center"} borderRadius={2} p={2} borderColor={activity.color} sx={{"borderWidth": 2, "borderStyle": "solid", "cursor": "pointer", "transition": "150ms ease-in-out all", "&:hover": {backgroundColor: `${activity.color}10`}}}
                                        onClick={() => setAlumniDetails((a) => {
                                            if (!a?.activities?.includes(activityId)) {
                                                return {...a, activities: [...(a?.activities || []), activityId]};
                                            }
                                            return {...a, activities: [...(a?.activities || [])].filter((mA) => mA !== activityId)};
                                        })}>
                                        <Stack alignItems={"center"}>
                                            <Checkbox sx={{color: activity.color}} checked={alumniDetails?.activities?.includes(activityId)}/>
                                        </Stack>
                                        <Stack spacing={0}>
                                            <Typography color={activity.color} fontWeight={"bold"}>{activity.title}</Typography>
                                            <Typography>{activity.description}</Typography>
                                        </Stack>
                                    </Stack>
                                </Grid>)}
                        </Grid>
                    </Form>
                </Stack>
            </FadeInBox>
            <FadeInBox card visible={Boolean(page === "profile" && instituteData)}>
                <Stack alignItems={"center"}>
                    {instituteData?.primaryImage && <Box component={"img"} sx={{width: "100px", height: "100px", mt: 2, mb: 2}} src={instituteData?.primaryImage}/>}
                    <Typography variant='h4' color={instituteData?.primaryColor || PRIMARY_COLOUR}>{instituteData?.instituteName}</Typography>
                    <Typography variant="h5">Share your journey with our students</Typography>
                    <Typography textAlign={"center"}>Lorum ipsum</Typography>
                    <Typography>Hello.</Typography>
                    <Form submitText="Next" onSubmit={() => setPage("profile")} button={false}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Stack direction={"row"}>
                                    <Typography>What type of education/employment are you enrolled in?</Typography>
                                    <Dropdown>
                                        <MenuItem value={"aLevels"}>A-levels</MenuItem>
                                        <MenuItem value={"tLevels"}>T-levels</MenuItem>
                                        <MenuItem value={"university"}>University</MenuItem>
                                        <MenuItem value={"apprenticeship"}>Apprenticeship</MenuItem>
                                        <MenuItem value={"earlyEmployment"}>Employment - Early Career (0-3 Years)</MenuItem>
                                        <MenuItem value={"midEmployment"}>Employment - Mid Career (3-10 Years)</MenuItem>
                                        <MenuItem value={"establishedEmployment"}>Established Career (10+ Years)</MenuItem>
                                        <MenuItem value={"freelance"}>Freelance</MenuItem>
                                        <MenuItem value={"entreprensur"}>Entrepreneur</MenuItem>
                                    </Dropdown>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <SectorSelector onSectorChange={(s) => setAlumniDetails((a) => ({...a, sector: s}))} onSubsectorChange={(s) => setAlumniDetails((a) => ({...a, subsector: s}))}/>
                            </Grid>
                            <Grid item xs={12}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox onChange={(e) => setAlumniDetails((a) => ({...a, visibleToStudents: e.target.checked}))}/>} label={"I am happy for you to share my profile with students"}/>
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox onChange={(e) => setAlumniDetails((a) => ({...a, enableConversations: e.target.checked}))}/>} label={"I am happy for students to message me through Placementt with questions related to my career."}/>
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="caption">By submitting your details, you are consenting to us contacting you in the future to update or remove your information. This ensures we have up to date information.</Typography>
                            </Grid>
                        </Grid>
                        <Stack direction={"row"}>
                            <Button onClick={() => setSkipProfilePopup(true)}>Skip</Button>
                            <FormButton variant="contained" onClick={submitAlumniRequest} text="Submit"/>
                        </Stack>
                    </Form>
                </Stack>
            </FadeInBox>
            <FadeInBox card visible={Boolean(page === "submitting" && instituteData)}>
                <Typography>Submitting</Typography>
            </FadeInBox>
            <FadeInBox card visible={Boolean(page === "complete" && instituteData)}>
                <Typography>Submitted</Typography>
            </FadeInBox>
            <Popup key={"skipProfilePopup"} open={skipProfilePopup} onClose={() => setSkipProfilePopup(false)} actions={<><Button>Go back</Button><Button>Yes, skip</Button></>}>
                <Typography>Please?</Typography>
            </Popup>
        </Page>
    );
}
