import {useParams} from "react-router-dom";
import Page from "../../Components/Page";
import {Box, Button, Checkbox, Grid, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography} from "@mui/material";
import {ExternalActivity, PRIMARY_COLOUR, ProviderContactData, executeCallable} from "placementt-core";
import {useEffect, useState} from "react";
import FadeInBox from "../../Components/FadeInBox";
import Preloader from "../../Components/Preloader";
import {Handshake, Lightbulb, Task} from "@mui/icons-material";
import UploadProviderDetails from "./ProviderDetails/UploadProviderDetails";
import {LoadingButton} from "../../Components/LoadingButton";


export default function ExternalProviderSubmission() {
    let {oId, providerContactId, schoolId} = useParams();
    console.log({oId, providerContactId, schoolId});
    const [cardPos, setCardPos] = useState<"loading"|"details"|"activities"|"review"|"complete"|"notAccepting">("loading");
    const [instituteBranding, setInstituteBranding] = useState<{
        image?: string;
        color?: string;
        name?: string;
        email?: string;
    }>();
    const [fProviderContactId, setFProviderContactId] = useState(providerContactId);
    const [activities, setActivities] = useState<[string, ExternalActivity][]>();
    const [providerContactDetails, setProviderContactDetails] = useState<Partial<ProviderContactData>>({});
    const [selectedActivities, setSelectedActivities] = useState<string[]>([]);

    // If there is an institute, check they are accepting placements. If not, allow them to advertise publicly
    // If not institute, allow public submission

    useEffect(() => {
        // Check if placement exists
        if (!oId) return;
        executeCallable("providerContacts-getDetailsForUpload", {...{oId, providerContactId, schoolId}}).then((res) => {
            const data = res.data as {code: 0|1|2|3|4|5, msg?: string, details?: {institute: {
                image?: string;
                color?: string;
                name?: string;
                email?: string;
            }, providerContact: ProviderContactData, activities?: [string, ExternalActivity][]}};
            if (data.code === 2) {
                setInstituteBranding(data.details?.institute);
                data.details?.activities && setActivities(data.details.activities);
                setCardPos("review");
            } else if (data.code === 3) {
                setInstituteBranding(data.details?.institute);
                setCardPos("review");
                data?.details?.providerContact && setProviderContactDetails(data?.details?.providerContact);
                data.details?.activities && setActivities(data.details.activities);
            } else if (data.code === 4) {
                data?.details?.providerContact && setProviderContactDetails(data?.details?.providerContact);
                data.details?.activities && setActivities(data.details.activities);
                setInstituteBranding(data.details?.institute);
                setCardPos("complete");
            } else if (data.code === 5) {
                setInstituteBranding(data.details?.institute);
                setCardPos("activities");
                data?.details?.providerContact && setProviderContactDetails(data?.details?.providerContact);
                data.details?.activities && setActivities(data.details.activities);
            } else {
                setCardPos("notAccepting");
                oId = undefined;
            }
        });
    }, []);

    // const submitToWaitlist = async () => {
    //     const firebaseQuery = new FirebaseQuery();
    //     await firebaseQuery.add(["businessInterest"], {
    //         forename: placementDetails.contactForename,
    //         surname: placementDetails.contactSurname,
    //         email: placementDetails.providerEmail,
    //         provider: placementDetails.name,
    //         providerId: placementDetails.providerId,
    //         sector: placementDetails.sector,
    //         subsector: placementDetails.subsector,
    //     });
    //     cookies.set("businessInterest", true);
    //     setSignedUp(true);
    // };

    return (
        <Page
            metaTitle="Placementt | Submit placement"
            metaDesc="Advertise your available placements to Placementt students"
            fullHeight>
            <FadeInBox card visible={cardPos === "loading"} cardTitle={"Loading School"}>
                <Preloader visible/>
            </FadeInBox>
            <FadeInBox card visible={cardPos === "notAccepting"} cardTitle={"You cannot upload placements at this time."}>
                <Typography>If you think this is a mistake, contact the institute you received this link from, or contact <Link href="mailto:tom@placementt.co.uk">Tom@placementt.co.uk</Link></Typography>
            </FadeInBox>
            <FadeInBox card visible={cardPos === "review"}>
                {instituteBranding?.image && <Box margin={"auto"} display={"flex"} component={"img"} sx={{width: "100px", height: "100px", mt: 2, mb: 2}} src={instituteBranding?.image}/>}
                <Typography variant="h6" textAlign={"center"}>Join <Typography variant="inherit" display={"inline-flex"} color={instituteBranding?.color || PRIMARY_COLOUR}>{instituteBranding?.name}'s</Typography> employer database, and connect with their students.</Typography>
                <List sx={{alignSelf: "start"}}>
                    <ListItem>
                        <ListItemIcon><Lightbulb sx={{color: instituteBranding?.color || PRIMARY_COLOUR}}/></ListItemIcon>
                        <ListItemText primary={"Students find placements that suit them."}/>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><Handshake sx={{color: instituteBranding?.color || PRIMARY_COLOUR}}/></ListItemIcon>
                        <ListItemText primary={"You get more enthusiastic, motivated students."}/>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><Task sx={{color: instituteBranding?.color || PRIMARY_COLOUR}}/></ListItemIcon>
                        <ListItemText primary={"We manage all admin for you, only notifying you when you are needed."}/>
                    </ListItem>
                </List>
                <Stack direction={"row"} justifyContent={"center"} pt={5} alignItems={"center"}>
                    <Button onClick={() => setCardPos("details")}>{providerContactId ? "Review Details" : "Add Your Details"}</Button>
                </Stack>
                {false && providerContactId && <Typography color={"gray"} sx={{cursor: "pointer"}}>Withdraw</Typography>}
            </FadeInBox>

            <UploadProviderDetails type="providerContact" oId={oId} schoolId={schoolId} pId={providerContactId} visible={cardPos === "details"} providerContact={providerContactDetails} back={() => setCardPos("review")} onComplete={async (details) => {
                setProviderContactDetails((prev) => ({...prev, ...details}));
                setFProviderContactId(details.providerContactId as string);
                setCardPos(Object.keys(activities || {}).length > 0 ? "activities" : "complete");
            }}/>
            {/* <UploadProviderInsurance uploaded={placementDetails.insurance as boolean|undefined} expiry={placementDetails.insuranceExpiry as string|undefined} onComplete={() => setCardPos("questions")} providerId={placementDetails.providerId as string} visible={cardPos === "insurance"} back={() => setCardPos("details")}/>
            <UploadPlacementQuestions visible={cardPos === "questions"} instituteName={instituteName} back={() => setCardPos("insurance")} onComplete={async (questions) => {
                await uploadPlacement({...placementDetails, ...questions});
                setCardPos("complete");
            }}/> */}
            {activities && <FadeInBox card visible={cardPos === "activities"} cardTitle={"Our Activities"} centerTitle width="800px">
                <Typography mb={2}>We organise various events throughout the year to enrich our student's careers support. Please select what types of activity you may be interested to take part in.</Typography>
                <Typography mb={2}>By selecting your preferences, we will email you invites when we organise relevant events.</Typography>
                <Grid container>
                    {activities.map(([key, activity]) =>
                        <Grid item xs={12} sm={6} key={key}>
                            <ListItemButton onClick={() => setSelectedActivities((a) => {
                                if (a.includes(key)) {
                                    return [...a].filter((k) => k !== key);
                                } else {
                                    return [...a, key];
                                }
                            })} sx={{borderRadius: 2, borderColor: activity.color, borderWidth: 2, borderStyle: "solid", mb: 1}}>
                                <ListItemIcon><Checkbox checked={selectedActivities.includes(key)} style={{color: activity?.color}}/></ListItemIcon>
                                <ListItemText
                                    primary={activity.title}
                                    primaryTypographyProps={{color: activity.color}}
                                    secondary={<Stack spacing={0}>
                                        <Typography>{activity.description}</Typography>
                                    </Stack>}
                                />
                            </ListItemButton>
                        </Grid>
                    )}
                </Grid>
                <LoadingButton text="Submit selection" onClick={async () => executeCallable("providerContacts-addActivities", {...{providerContactId: fProviderContactId, selectedActivities, oId, schoolId}}).then(() => setCardPos("complete"))}/>
            </FadeInBox>}
            <FadeInBox card visible={cardPos === "complete"} cardTitle={"Thanks for joining our database"} centerTitle>
                <Typography>{instituteBranding?.name} will be in touch to organise specific events depending on their calendar. If you would like to suggest events, please reach out to the school directly.</Typography>
            </FadeInBox>
        </Page>
    );
}
