import {Alert, Collapse, Grid, Stack, Typography} from "@mui/material";
import FadeInBox from "../../../Components/FadeInBox";
import {PlacementActionPanel} from "../../Shared/ViewPlacement";
import IconButtonPop from "../../../Components/IconButtonPop";
import {ArrowBack} from "@mui/icons-material";
import {useEffect, useState} from "react";
import {PRIMARY_COLOUR, PlacementReviewDetails, executeCallable} from "placementt-core";
import CustomFormPage from "../../../Components/CustomFormPage";


type Params = {
    visible: boolean,
    back: () => void,
    onComplete?: (e: PlacementReviewDetails) => any,
    uid: string,
    pId: string,
    placementKey: string,
    placement: PlacementReviewDetails,
    error?: {
        code: number,
        msg: string
    }
}

export default function UploadInstituteForms({visible, back, onComplete, error, uid, pId, placementKey, placement}: Params) {
    const [form, setForm] = useState<{name: string, [key:string]: unknown}>();
    const [formPageOpen, setFormPageOpen] = useState(false);
    const [formData, setFormData] = useState(placement);

    useEffect(() => {
        setFormData((prev) => ({...prev, ...placement}));
    }, [placement]);

    const setFormsComplete = async () => {
        await executeCallable("placement-providerFormsComplete", {
            uid: uid,
            pId: pId});
        onComplete && onComplete(formData);
    };

    const setFormComplete = async (e: {instituteName: string} & PlacementReviewDetails) => {
        await executeCallable("placement-submitExternalForm", {uid: uid, pId: pId, key: placementKey, formData: e});
        setFormData((p) => ({...p, ...e}));
        setFormPageOpen(false);
    };

    return (
        <>
            <FadeInBox card visible={!formPageOpen && visible} cardTitle={[
                back && <IconButtonPop sx={{position: "absolute"}} onClick={back} responsive={false} title={"Back"}>
                    <ArrowBack />
                </IconButtonPop>, <Typography color={placement.primaryColor || PRIMARY_COLOUR} margin={"auto"} variant={"h4"}>Institute verification</Typography>]
            }>
                <Stack spacing={2} alignItems={"center"} mt={"10px"}>
                    <Typography variant='subtitle1'>{placement.instituteName} has requested you review and complete the following.</Typography>
                    <Grid item sx={{width: "100%"}}>
                        <PlacementActionPanel toggleForm={(e) => {
                            setFormPageOpen(true); setForm(e as {name: string});
                        }} external placement={formData} {...{pId, uid}} stage={placement.currentStage} editPlacementStage={setFormsComplete}/>
                    </Grid>
                </Stack>
                <Collapse in={Boolean(error) && Object.prototype.hasOwnProperty.call(error, "code")}>
                    <Alert severity={error?.code === 1 ? "success" : "error"}>{error?.msg}</Alert>
                </Collapse>
            </FadeInBox>
            <FadeInBox card sx={{background: "transparent", boxShadow: "none"}} visible={formPageOpen} cardTitle={[
                <IconButtonPop sx={{position: "absolute"}} onClick={() => setFormPageOpen(false)} responsive={false} title={"Back"}>
                    <ArrowBack />
                </IconButtonPop>, <Typography color={placement.primaryColor || PRIMARY_COLOUR} margin={"auto"} variant={"h4"}>{(form as any)?.schema?.name}</Typography>]
            }>
                <CustomFormPage key={form?.name} embedded {...form} noNavigate noUpdate onComplete={async (e) => await setFormComplete(e as {instituteName: string} & PlacementReviewDetails)}/>
            </FadeInBox>
        </>
    );
}
