import {CustomFormSchema, PRIMARY_COLOUR, PlacementReviewDetails, camelCaseToNormal, convertDate, editPlacementStage, executeCallable} from "placementt-core";
import {useEffect, useState} from "react";
import FadeInBox from "../../../Components/FadeInBox";
import {Box, Button, Grid, Icon, List, ListItem, ListItemSecondaryAction, ListItemText, Snackbar, Stack, Table, TableBody, TableRow, Typography} from "@mui/material";
import {ArrowBack, ArrowForward, CheckCircleOutline, Close, Edit, RemoveRedEye} from "@mui/icons-material";
import IconButtonPop from "../../../Components/IconButtonPop";
import CustomFormPage from "../../../Components/CustomFormPage";
import {DecorativeText, GradientText, InfoTableCell} from "../../../Util/styledComponents";
import {PlacementActionPanel, ProviderGuidance} from "../../Shared/ViewPlacement";
import UploadProviderInsurance from "./UploadProviderInsurance";
import Card from "../../../Components/Card";
import DailyPlacementLog from "../../../Components/DailyPlacementLog";
import UploadProviderRiskAssessment from "./UploadProviderRiskAssessment/UploadProviderRiskAssessment";

import {Descendant} from "slate";
import {LoadingButton} from "../../../Components/LoadingButton";
import UploadProviderDbsCheck from "./UploadProviderDbsCheck/UploadProviderDbsCheck";

type Params = {
    visible: boolean
    uid: string,
    pId: string,
    placementKey: string,
    onComplete: (outcome: "submitted"|"reject") => any,
    placement: PlacementReviewDetails
}
type IncompleteItemsObj = {eli?: boolean, riskAssessment?: boolean, dbsCheck?: boolean, files?: {[fileId: string]: {name: string, url: string, stage: number}}, forms?: {[key:string]: {form: {[key: string]: unknown}, imported: boolean, stage: number}}}

export default function ReviewStudentPlacement({placement, uid, pId, placementKey, onComplete, visible}: Params) {
    const [snackbar, setSnackbar] = useState({open: false, msg: ""});
    const [form, setForm] = useState<{schema: CustomFormSchema, destination?: string[], name?: string, onComplete?:(e: {[key: string]: unknown}) => Promise<any>|any}>();
    const [formPageOpen, setFormPageOpen] = useState(false);
    const [formData, setFormData] = useState(placement);
    const [incompleteItems, setIncompleteItems] = useState<IncompleteItemsObj|undefined>();
    const [page, setPage] = useState<"overview"|"insurance"|"forms">("overview");


    useEffect(() => {
        setFormData((prev) => ({...prev, ...placement}));
        console.log(placement.incompleteItems);
        setIncompleteItems(Object.entries(placement.incompleteItems).reduce((acc, [stageId, itemList]) => {
            for (let i = 0; i < itemList.length; i++) {
                const item = itemList[i] as string|{[key:string]: unknown};
                if (item === "eli") {
                    acc.eli = true;
                }
                if (item === "riskAssessment") {
                    acc.riskAssessment = true;
                }
                if (item === "dbsCheck") {
                    acc.dbsCheck = true;
                }
                if (typeof item === "object" && Object.entries(item)[0][0].includes("form")) {
                    if (!acc.forms) {
                        acc.forms = {};
                    }
                    acc.forms[Object.keys(item)[0] as string] = {...(Object.values(item)[0] as {form: {[key: string]: unknown}, imported: boolean}), stage: parseInt(stageId)};
                }
                if (typeof item === "object" && !Object.entries(item)[0][0].includes("form")) {
                    if (!acc.files) {
                        acc.files = {};
                    }
                    acc.files[Object.keys(item)[0] as string] = {...(Object.values(item)[0] as {name: string, url: string}), stage: parseInt(stageId)};
                }
            }
            return acc;
        }, {} as IncompleteItemsObj));
    }, [placement]);

    const editStage = async (nextStageId: number) => {
        await editPlacementStage(placement.id, placement.currentStage.id, nextStageId);
        onComplete("submitted");
    };

    const setFormComplete = async (e: {instituteName: string} & PlacementReviewDetails, feedback?: boolean) => {
        console.log("e", {uid: uid, pId: pId, key: placementKey, formData: e});
        await executeCallable("placement-submitExternalForm", {uid: uid, pId: pId, key: placementKey, formData: e, feedback: feedback});

        if (feedback) {
            setFormData((p) => ({...p, providerFeedback: e}));
        } else {
            setFormData((p) => ({...p, ...e}));
        }
        setFormPageOpen(false);
    };

    const viewFile = async (fileId: string, file:{name: string, url: string, stage: number}) => {
        if (!placement) return;
        window.open(file.url);
        if (!(`file_${file.stage}_${fileId}` in formData)) {
            await executeCallable("placement-updateFileView", {pId: pId, stage: file.stage, id: fileId});
            setFormData((p) => ({...p, [`file_${file.stage}_${fileId}`]: true}));
        }
    };

    const renderSlate = (text: Descendant[]) => {
        return (text.map((element: any) => {
            if (element.type === "paragraph") {
                return <p>{element.children.map((child: {text: string}) => <span>{child.text}</span>)}</p>;
            }
            if (element.type === "link") {
                return <a href={element.href} target={"_blank"}>{element.children.map((child: {text: string}) => <span>{child.text}</span>)}</a>;
            }
        }));
    };

    console.log("form", form);
    return (
        <>
            <FadeInBox card visible={!formPageOpen && page === "overview" && visible} sx={{textAlign: "center"}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h4'>Review details</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {placement.primaryImage && <Box component={"img"} sx={{width: "100px", height: "100px", mt: 2, mb: 2}} src={placement.primaryImage}/>}
                        <Typography variant='h5'><DecorativeText color={placement.primaryColor || "#2b66bf"}>{placement.studentForename} {placement.studentSurname}</DecorativeText>{placement.instituteName && <> from <DecorativeText color={placement.primaryColor || "#2fbbab"}>{placement.instituteName}</DecorativeText></>}</Typography>                        <Typography variant='h6'>{convertDate(placement.startDate, "visual") as string} - {convertDate(placement.endDate, "visual") as string}</Typography>
                    </Grid>
                    {placement && placement.userType === "provider" && new Date() > new Date(placement.startDate) && <DailyPlacementLog {...{placement}} userType={"provider"} access={["provider"]}/>}
                </Grid>
                {(placement.feedback || placement.feedbackText) && <Box textAlign={"start"} borderColor={PRIMARY_COLOUR} pt={2} pb={2} mt={2} mb={2} borderTop={"2px solid "+PRIMARY_COLOUR} borderBottom={"2px solid "+PRIMARY_COLOUR}>
                    <Typography variant="h5" color={PRIMARY_COLOUR}>Placement feedback</Typography>
                    {placement.feedbackText &&
                    <Stack spacing={1}>
                        <Typography variant="subtitle2" fontWeight={"bold"} fontSize={"1.1em"}>Message from your institute:</Typography>
                        <Box border={"1px solid lightgrey"} borderRadius={2} p={1} pl={2} pr={2}>
                            {renderSlate(placement.feedbackText)}
                        </Box>
                        {placement?.feedback?.form?.length ?
                            <List>
                                <ListItem
                                    sx={{borderTop: "1px solid lightgrey"}}
                                    divider
                                    secondaryAction={
                                        formData.providerFeedback ?
                                            [<IconButtonPop responsive={false} title={"Form completed"} disabled>
                                                <CheckCircleOutline color={"success"} />
                                            </IconButtonPop>,
                                            <IconButtonPop responsive={false} title={"View form"} onClick={() => {
                                                setForm({schema: placement.feedback, name: placement.feedback.name, onComplete: async (e) => await setFormComplete(e as { instituteName: string; } & PlacementReviewDetails, true)});
                                                setFormPageOpen(true);
                                            }}><Edit/></IconButtonPop>] :
                                            <Button onClick={() => {
                                                setForm({schema: placement.feedback, name: placement.feedback.name, onComplete: async (e) => await setFormComplete(e as { instituteName: string; } & PlacementReviewDetails, true)});
                                                setFormPageOpen(true);
                                            }}>Complete</Button>}>
                                    <ListItemText primary={"Complete feedback form"} sx={{marginRight: "35px"}} />
                                </ListItem>
                            </List> : (Boolean(formData.providerFeedback) ||
                            <>
                                <LoadingButton text="Mark as read" onClick={async () => await setFormComplete({} as { instituteName: string; } & PlacementReviewDetails, true)}/>
                                <Typography variant="caption" textAlign={"center"}>Mark as read to prevent any other feedback email reminders from us.</Typography>
                            </>)}
                    </Stack>}
                </Box>}
                <Table size="small">
                    <TableBody>
                        <TableRow sx={{height: "50px"}}>
                            <InfoTableCell>Job title</InfoTableCell>
                            <InfoTableCell>{placement.title}</InfoTableCell>
                            <InfoTableCell></InfoTableCell>
                        </TableRow>
                        <TableRow>
                            <InfoTableCell>Provider name</InfoTableCell>
                            <InfoTableCell>{placement.name}</InfoTableCell>
                        </TableRow>
                        <TableRow>
                            <InfoTableCell>Provider email</InfoTableCell>
                            <InfoTableCell>{placement.providerEmail}</InfoTableCell>
                        </TableRow>
                        <TableRow>
                            <InfoTableCell>Provider phone</InfoTableCell>
                            <InfoTableCell>{placement.providerPhone || "Not provided"}</InfoTableCell>
                        </TableRow>
                        <TableRow>
                            <InfoTableCell>Provider address</InfoTableCell>
                            <InfoTableCell>
                                {placement["address-line1"]}<br />
                                {placement["address-line2"]}<br />
                                {placement.locality}<br />
                                {placement.postal_code}&nbsp;{camelCaseToNormal(placement.country)}
                            </InfoTableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                {incompleteItems && Object.values(incompleteItems).filter((e) => e).length > 0 && <Card sx={{borderLeft: "10px #7920F5 solid"}} title={"Action required"}>
                    <List>
                        {incompleteItems.eli !== undefined && <ListItem divider>
                            <ListItemText primary={"Submit employer's liability insurance"} />
                            <ListItemSecondaryAction>
                                {incompleteItems?.eli === false ? <Icon color='success' sx={{marginRight: "8px"}}><CheckCircleOutline/></Icon> :
                                    <IconButtonPop responsive={false} onClick={() => setPage("insurance")} title={"Complete step"}>
                                        <ArrowForward />
                                    </IconButtonPop>}
                            </ListItemSecondaryAction>
                        </ListItem>}
                        {incompleteItems?.riskAssessment && <><UploadProviderRiskAssessment {...{pId, placement}} placementListingId={formData.placementId} providerContactId={formData.providerContactId} onComplete={() => {
                            setIncompleteItems((i) => ({...i, riskAssessment: false}));
                        }}/><br/></>}
                        {incompleteItems?.dbsCheck && <><UploadProviderDbsCheck {...{pId, placement}} placementListingId={formData.placementId} providerContactId={formData.providerContactId} onComplete={() => {
                            setIncompleteItems((i) => ({...i, dbsCheck: false}));
                        }}/><br/></>}
                        {incompleteItems.files !== undefined && Object.entries(incompleteItems.files)?.map(([fileId, file]) => <ListItem>
                            <ListItemText primary={file.name} sx={{marginRight: "35px"}} />
                            <ListItemSecondaryAction>
                                {(`file_${file.stage}_${fileId}` in formData) ? [<IconButtonPop responsive={false} title={"File viewed"} disabled><CheckCircleOutline color={"success"} /></IconButtonPop>, <IconButtonPop responsive={false} title={"View file"} onClick={() => {
                                    viewFile(fileId, file);
                                }}><RemoveRedEye /></IconButtonPop>] : <Button onClick={() => {
                                    viewFile(fileId, file);
                                }}>View</Button>}
                            </ListItemSecondaryAction>
                        </ListItem>)}
                        {incompleteItems?.forms && <List sx={{width: "100%"}}>
                            {Object.entries(incompleteItems.forms).map(([formId, element]) => {
                                return (
                                    <ListItem
                                        secondaryAction={
                                            (formId in formData) ?
                                                [<IconButtonPop responsive={false} title={"Form completed"} disabled>
                                                    <CheckCircleOutline color={"success"} />
                                                </IconButtonPop>,
                                                <IconButtonPop responsive={false} title={"View form"} onClick={() => {
                                                    setForm({schema: element.form, destination: ["placements", placement.id], name: formId});
                                                    setFormPageOpen(true);
                                                }}><Edit/></IconButtonPop>] :
                                                <Button onClick={() => {
                                                    setForm({schema: element.form, destination: ["placements", placement.id], name: formId});
                                                    setFormPageOpen(true);
                                                }}>Complete</Button>}>
                                        <ListItemText primary={element.form.name as string} sx={{marginRight: "35px"}} />
                                    </ListItem>
                                );
                            })}
                        </List>}
                    </List>
                </Card>}
                {placement.currentStage.userType?.toLowerCase() === placement.userType && <PlacementActionPanel external toggleForm={(e) => {
                    setForm(e as {schema: CustomFormSchema, destination: string[], name?: string}); setFormPageOpen(true);
                }} editPlacementStage={editStage} placement={formData} pId={placement.id} stage={placement.currentStage}/>}
                {placement.questions && <Box textAlign={"start"}>
                    <ProviderGuidance guidance={placement.questions}/>
                </Box>}
                <Typography color={"gray"} sx={{cursor: "pointer"}} onClick={() => onComplete("reject")}>Not your placement?</Typography>
            </FadeInBox>
            <FadeInBox card sx={{background: "transparent", boxShadow: "none"}} visible={visible && formPageOpen} cardTitle={[
                <IconButtonPop responsive={false} onClick={() => setFormPageOpen(false)} title={"Back"}>
                    <ArrowBack/>
                </IconButtonPop>,
                <GradientText margin={"auto"} variant={"h4"}>{(form as any)?.schema?.name}</GradientText>]}>
                <CustomFormPage key={form?.name} embedded {...form} noNavigate noUpdate onComplete={async (e) => form?.onComplete ? await form?.onComplete(e) : await setFormComplete(e as { instituteName: string; } & PlacementReviewDetails)}/>
            </FadeInBox>
            <Snackbar
                open={snackbar.open}
                onClose={() => setSnackbar((s) => ({...s, open: false}))}
                message={snackbar.msg}
                autoHideDuration={6000}
                action={<IconButtonPop
                    size="small"
                    color="inherit"
                    title={"Close"}
                    responsive={false}
                    onClick={() => setSnackbar((s) => ({...s, open: false}))}
                >
                    <Close fontSize="small" />
                </IconButtonPop>}
            />
            {incompleteItems?.eli && <UploadProviderInsurance {...{pId}} providerContactId={formData.providerContactId} visible={page === "insurance"} back={() => setPage("overview")} onComplete={() => {
                setIncompleteItems((i) => ({...i, eli: false}));
                setPage("overview");
            }}/>}
        </>
    );
}
