import Page from "../../Components/Page";
import Card from "../../Components/Card";
// import {LoadingButton} from "../../Components/LoadingButton";
// import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
// import InputGroup from "../../Components/FormComponents/InputGroup";
import Form from "../../Components/Form";
// import {where} from "firebase/firestore";
import {Button, List, ListItem, ListItemText, MenuItem} from "@mui/material";
import {useEffect, useState} from "react";
import {Popup} from "../../Components/Popup";
import Dropdown from "../../Components/FormComponents/Dropdown";
import InputGroup from "../../Components/FormComponents/InputGroup";
import SlateEditor from "../../Components/Slate";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {Update, uploadFiles} from "placementt-core";
import FileDropzone from "../../Components/FileDropzone/FileDropzone";

export default function Home() {
    // const firebaseQuery = new FirebaseQuery();

    // async function fetchAboutUsItems() {
    //     // Instantiate your FirebaseQuery class with the desired collection

    //     // Use getDocsWhere method to fetch documents where "about" exists
    //     const result = await firebaseQuery.getDocsWhere(["emailTemplates"], where("public", "==", true));

    //     // Copy the result as a JSON string to the clipboard
    //     navigator.clipboard.writeText(JSON.stringify(result))
    //         .then(() => {
    //             console.log("Data copied to clipboard");
    //         })
    //         .catch((err) => {
    //             console.error("Failed to copy data to clipboard: ", err);
    //         });
    // }

    // const submitAbout = async (e: {about: {[key: string]: {[key: string]: unknown}}}) => {
    //     const aboutObj = Object.entries(e.about);

    //     aboutObj.forEach(([key, v]) => {
    //         firebaseQuery.set(["emailTemplates", key], v);
    //     });
    // };

    return (
        <Page title="Home" grid>
            {/* <Card grid>
                <LoadingButton text="Add active" onClick={fetchAboutUsItems}/>
                <Form onSubmit={(e) => submitAbout(e as {about: {[key: string]: {[key: string]: unknown}}})}>
                    <InputGroup name={"about"}/>
                </Form>
            </Card> */}
            <PlacementUpdates/>
        </Page>
    );
}

function PlacementUpdates() {
    const [updates, setUpdates] = useState<{[key: string]: Update}>();
    const [addUpdateOpen, setAddUpdateOpen] = useState(false);
    const firebaseQuery = new FirebaseQuery();

    useEffect(() => {
        firebaseQuery.collectionSnapshot((items:{[key: string]: Update}) => setUpdates(Object.fromEntries(Object.entries(items || {}).sort((a, b) => new Date(b[1].date).getTime() - new Date(a[1].date).getTime()))), ["updates"]);
    }, []);

    const uploadUpdate = async (e: Update&{image?: string}) => {
        const newUpdate = {...e};
        delete newUpdate.image;
        const updateId = await firebaseQuery.add(["updates"], {...newUpdate, imageData: Boolean(e.image)});

        if (e?.image) {
            await uploadFiles([e.image[0]], ["updates", `${updateId.id}.png`]);
        }
        setAddUpdateOpen(false);
    };

    return (
        <Card grid xs={12} md={6} title={"Platform Updates"} secondaryTitle={<Button onClick={() => setAddUpdateOpen(true)}>Add Update</Button>}>
            <List>
                {updates && Object.entries(updates).map(([k, update]) => <ListItem key={k}>
                    <ListItemText primary={update.title} secondary={update.date}/>
                </ListItem>)}
            </List>
            <Popup maxWidth={"md"} fullWidth title={"Create update"} open={addUpdateOpen} onClose={() => setAddUpdateOpen(false)}>
                <Form onSubmit={async (e) => await uploadUpdate(e as Update)}>
                    <Dropdown required name="product" label={"Product"}>
                        <MenuItem value={"institutes"}>Institutes</MenuItem>
                        <MenuItem value={"students"}>Students</MenuItem>
                    </Dropdown>
                    <Dropdown required name="userType" label={"User Type"}>
                        <MenuItem value={"Staff"}>Staff</MenuItem>
                        <MenuItem value={"Students"}>students</MenuItem>
                    </Dropdown>
                    <InputGroup required name={"date"} label={"Date"} type={"date"}/>
                    <FileDropzone name="image"/>
                    <InputGroup required name={"title"} label={"Title"}/>
                    <SlateEditor name={"description"}/>
                    <InputGroup name={"buttonText"} label={"Button Text"}/>
                    <InputGroup name={"buttonLink"} label={"Button Link"}/>
                </Form>
            </Popup>
        </Card>
    );
}
