import {Button, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, Stack, Typography} from "@mui/material";
import FadeInBox from "../../../Components/FadeInBox";
import Form from "../../../Components/Form";
import InputGroup from "../../../Components/FormComponents/InputGroup";
import {SectorSelector} from "../../../Components/SectorSelector";
import IconButtonPop from "../../../Components/IconButtonPop";
import {camelCaseToNormal, capitaliseWords, executeCallable, PlacementReviewDetails, PRIMARY_COLOUR, ProviderData} from "placementt-core";
import {ChangeEvent, useState} from "react";
import {Popup} from "../../../Components/Popup";
import {LoadingButton} from "../../../Components/LoadingButton";
import {ArrowBack} from "@mui/icons-material";
import Dropdown from "../../../Components/FormComponents/Dropdown";

type Params = {
    visible: boolean,
    providerContact?: {[key:string]: unknown}
    onComplete?: (e: {[key:string]: unknown}) => any,
    back?: () => void,
    type: "providerContact"|"placement",
    oId?: string,
    schoolId?: string,
    placement?: PlacementReviewDetails
    pId?: string,}

type ProviderMatch = {
    name: string,
    insurance?: boolean,
    placements: number
    savedBy?: number,
    providerId?: string,
    type: "providerContact"|"provider"
}&ProviderData;

export default function UploadProviderDetails({visible, back, oId, placement, schoolId, onComplete, type, pId, providerContact}: Params) {
    const [providerContactMatches, setProviderContactMatches] = useState<[string, ProviderMatch][]>();
    const [providerContactMatchPopup, setProviderContactMatchPopup] = useState(false);
    const [inputtedDetails, setInputtedDetails] = useState<{[key:string]: unknown}>(providerContact || {});

    const onSubmit = async (additionalDetails?:{providerId?: string, providerContactId?: string}, skipSearch=false) => {
        // if (!uid) {
        //     onComplete && await onComplete({...inputtedDetails, ...additionalDetails});
        //     return;
        // }
        const callableData = type === "placement" ? {
            placement: {
                id: pId,
                data: {...providerContact, ...inputtedDetails, ...additionalDetails},
                stage: providerContact?.currentStage,
            },
            skipSearch: skipSearch,
        } : {
            providerContact: {
                id: additionalDetails?.providerContactId || pId,
                data: {...providerContact, ...inputtedDetails, providerId: additionalDetails?.providerId},
                activities: null,
                oId: oId,
                schoolId: schoolId,
            },
            skipSearch: skipSearch,
        };
        const res = await executeCallable("providerContacts-uploadProviderDetails", callableData).catch((e) => {
            throw e;
        });

        const resData = res.data as {match?: boolean, matches: [string, ProviderMatch][]};

        console.log("RESDATA", resData);

        if (resData?.match) {
            setProviderContactMatches(resData.matches);
            setProviderContactMatchPopup(true);
            return;
        }
        setProviderContactMatches(undefined);
        setProviderContactMatchPopup(false);
        onComplete && onComplete((res.data as {[key:string]: unknown}));
    };
    console.log(providerContact);

    return (
        <>
            <FadeInBox card visible={visible} cardTitle={[<IconButtonPop sx={{position: "absolute"}} responsive={false} onClick={back} title={"Back"}><ArrowBack/></IconButtonPop>, <Typography color={placement?.primaryColor || PRIMARY_COLOUR} variant={"h4"} sx={{margin: "auto"}}>{providerContact ? "Add details" : "Employer details"}</Typography>]}>
                <Form onSubmit={onSubmit} submitText={"Next"}>
                    <Grid item>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <InputGroup required name='name' onChange={(e: ChangeEvent<HTMLInputElement>) => setInputtedDetails((i) => ({...i, name: e.target.value}))} value={providerContact?.name} label='Provider name' />
                            </Grid>
                            <Grid item xs={12}>
                                <SectorSelector required sector={providerContact?.sector as string} subsector={providerContact?.subsector as string} onSectorChange={(v) => setInputtedDetails((i) => ({...i, sector: v}))} onSubsectorChange={(v) => setInputtedDetails((i) => ({...i, subsector: v}))}/>
                            </Grid>
                            <Grid item xs={12}>
                                <InputGroup label="Website" name="website" onChange={(e: ChangeEvent<HTMLInputElement>) => setInputtedDetails((i) => ({...i, website: e.target.value}))} value={providerContact?.website} placeholder="myBusiness.com"/>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='h6'>Contact details</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputGroup required id="forename" value={providerContact?.contactForename} onChange={(e: ChangeEvent<HTMLInputElement>) => setInputtedDetails((i) => ({...i, contactForename: e.target.value}))} label="Contact forename" name="contactForename" />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputGroup required id="surname" value={providerContact?.contactSurname} onChange={(e: ChangeEvent<HTMLInputElement>) => setInputtedDetails((i) => ({...i, contactSurname: e.target.value}))} label="Contact surname" name="contactSurname" />
                            </Grid>
                            <Grid item xs={12}>
                                <InputGroup required name='providerEmail' value={providerContact?.providerEmail} onChange={(e: ChangeEvent<HTMLInputElement>) => setInputtedDetails((i) => ({...i, providerEmail: e.target.value}))} type='email' label='Contact email' />
                            </Grid>
                            <Grid item xs={12}>
                                <InputGroup name='providerPhone' type='tel' pattern="^[0-9]*$" label='Provider phone' onChange={(e: ChangeEvent<HTMLInputElement>) => setInputtedDetails((i) => ({...i, providerPhone: e.target.value}))} value={providerContact?.providerPhone}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='h6'>Your address</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputGroup required id="address-line1" value={providerContact?.["address-line1"]} label="Address line one" onChange={(e: ChangeEvent<HTMLInputElement>) => setInputtedDetails((i) => ({...i, ["address-line1"]: e.target.value}))} name="address-line1" />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputGroup id="address-line2" value={providerContact?.["address-line2"]} label="Address line two" onChange={(e: ChangeEvent<HTMLInputElement>) => setInputtedDetails((i) => ({...i, ["address-line2"]: e.target.value}))} name="address-line2" />
                            </Grid>
                            <Grid item xs={12}>
                                <InputGroup required id="locality" value={providerContact?.locality} label="City" name="locality" onChange={(e: ChangeEvent<HTMLInputElement>) => setInputtedDetails((i) => ({...i, locality: e.target.value}))} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <InputGroup required id="postcode" value={providerContact?.postal_code} label="Postcode" name="postal_code" onChange={(e: ChangeEvent<HTMLInputElement>) => setInputtedDetails((i) => ({...i, postal_code: e.target.value}))} />
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <Dropdown required id="country" value={providerContact?.country as string} label="Country" name="country" onChange={(e) => setInputtedDetails((i) => ({...i, country: e.target.value}))} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Form>
            </FadeInBox>
            <Popup open={providerContactMatchPopup} onClose={(event, reason) => {
                if (reason && reason === "backdropClick") {
                    return;
                }
                setProviderContactMatchPopup(false);
                return;
            }}
            noCloseButton
            title={"Provider match found"}>
                <Typography>We've found these providers in our placementt database that match your email. Is this you?</Typography>
                <List>
                    {(providerContactMatches || []).map(([id, match]) =>
                        <ListItem divider>
                            <ListItemText
                                primary={<strong>{match?.name}</strong>}
                                secondary={match.type === "providerContact" ? `Placements: ${match.placements}` : `${match?.["address-line1"]} | ${match?.postal_code?.toUpperCase()} | ${capitaliseWords(camelCaseToNormal(match?.country as string))}`} />
                            <ListItemSecondaryAction>
                                <LoadingButton onClick={async () => {
                                    await onSubmit(match.type === "providerContact" ? {providerContactId: id, providerId: match.providerId} : {providerId: id}).then(() => setProviderContactMatchPopup(false));
                                }} text="Select"/>
                            </ListItemSecondaryAction>
                        </ListItem>
                    )}
                </List>
                <Stack spacing={2} direction={"row"} sx={{float: "right"}}>
                    <LoadingButton>
                        <Button variant="text" onClick={() => onSubmit({}, true)}>No matches</Button>
                    </LoadingButton>
                </Stack>
            </Popup>
        </>
    );
}
