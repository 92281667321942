import {Avatar, Box, Chip, Divider, Grid, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Stack, Typography} from "@mui/material";
import {InstituteData, SchoolData} from "placementt-core";
import {ChangeEvent, useContext, useEffect, useState} from "react";
import InputGroup from "../../../../../Components/FormComponents/InputGroup";
import Form from "../../../../../Components/Form";
import Dropdown from "../../../../../Components/FormComponents/Dropdown";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {Popup} from "../../../../../Components/Popup";
import {OrganisationContext, UserContext} from "../../../../../App";
import {ProfilePic} from "../../../../Providers/Home";
import CustomColourPicker from "../../../../../Components/CustomColourPicker";

type PopupParams = {
    onToggle: (open: boolean) => void,
    active: boolean,
    fAddressData?: SchoolData,
    onSubmit?: (e: SchoolData) => void,
    initialSchool?: SchoolData;
    schoolId?: string
}

export default function AddSchoolPopup({onToggle, active, onSubmit, initialSchool, schoolId}: PopupParams) {
    const [inputKey, setInputKey] = useState((Math.random() + 1).toString(36).substring(7));
    const [fSchoolData, setFSchoolData] = useState<SchoolData|undefined>(initialSchool);

    const firebaseQuery = new FirebaseQuery();

    useEffect(() => {
        setFSchoolData(initialSchool);
    }, [initialSchool]);

    const user = useContext(UserContext);
    const institute = useContext(OrganisationContext).details as InstituteData;

    if (user.product !== "providers" && user.product !== "institutes") throw new Error("AddAddressPopup for organisations only");

    useEffect(() => {
        setFSchoolData(fSchoolData);
    }, [fSchoolData]);
    // Remove all users already active from list.
    const onSubmitAddress = async () => {
        if (!fSchoolData) return;

        const schoolUploadData = {
            ...fSchoolData,
            country: fSchoolData.country || "unitedKingdom",
            stage: "complete",
            oId: user.oId,
            product: user.product,
            created: (new Date()).toISOString(),
        } as SchoolData;

        let fSchoolId:string;
        if (schoolId) {
            await firebaseQuery.update(["schools", schoolId], schoolUploadData);
            fSchoolId = schoolId;
        } else {
            fSchoolId = (await firebaseQuery.add("schools", {...schoolUploadData, monthAdded: (new Date()).getMonth()})).id;
        }

        onSubmit && onSubmit({...(schoolUploadData), id: fSchoolId});

        setInputKey((Math.random() + 1).toString(36).substring(7));
    };

    return (
        <Popup title={(initialSchool ? "Edit" : "Add")+" school"} open={active} key={inputKey} onClose={() => {
            onToggle(false); setFSchoolData(undefined);
        }}>
            <Form onSubmit={onSubmitAddress}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h5">Personalisation</Typography>
                        <Typography>Get higher response rates from businesses with personalisation to our emails.</Typography>
                    </Grid>
                    {schoolId && <Grid item xs={12} md={6}>
                        <Stack direction={"row"}>
                            <Box width={"max-content"}>
                                <ProfilePic path={schoolId}/>
                            </Box>
                            <Typography>Upload a profile image for this school to display on emails.</Typography>
                        </Stack>
                    </Grid>}
                    <Grid item xs={12} md={6}>
                        <Typography>Set a primary colour for our emails to users.</Typography>
                        <CustomColourPicker color={fSchoolData?.color} onColorChange={(e) => setFSchoolData((a) => ({...(a || {}), color: e} as SchoolData))}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider sx={{mb: 2, mt: 2}}/>
                        <Typography variant="h5">School address</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <InputGroup required id="name" label="Name (public)" name="name" onChange={(e: ChangeEvent<HTMLInputElement>) => setFSchoolData((a) => ({...(a || {}), name: e.target.value}) as SchoolData)} value={fSchoolData?.name}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputGroup required id="address-line1" label="Address line one" name="address-line1" onChange={(e: ChangeEvent<HTMLInputElement>) => setFSchoolData((a) => ({...(a || {}), ["address-line1"]: e.target.value}) as SchoolData)} value={fSchoolData?.["address-line1"]}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputGroup id="address-line2" label="Address line two" name="address-line2" onChange={(e: ChangeEvent<HTMLInputElement>) => setFSchoolData((a) => ({...(a || {}), ["address-line2"]: e.target.value}) as SchoolData)} value={fSchoolData?.["address-line2"]}/>
                    </Grid>
                    <Grid item xs={12}>
                        <InputGroup required id="locality" label="City" name="locality" onChange={(e: ChangeEvent<HTMLInputElement>) => setFSchoolData((a) => ({...(a || {}), locality: e.target.value}) as SchoolData)} value={fSchoolData?.locality}/>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputGroup required id="postcode" label="Postcode" name="postal_code" onChange={(e: ChangeEvent<HTMLInputElement>) => setFSchoolData((a) => ({...(a || {}), postal_code: e.target.value}) as SchoolData)} value={fSchoolData?.postal_code}/>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Dropdown required id="country" label="Country" name="country" onChange={(e) => setFSchoolData((a) => ({...(a || {}), country: e.target.value}) as SchoolData)} value={fSchoolData?.country}/>
                    </Grid>
                    {institute.splitBilling && <Grid item xs={12}>
                        <Divider sx={{mb: 2, mt: 2}}/>
                        <Typography variant="h5">Split billing information</Typography>
                    </Grid>}
                    <Grid item xs={12}>
                        <Typography>What email address shall we send invoices for this school to?</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <InputGroup required id="billingEmail" label="Billing email" name="billingEmail" onChange={(e: ChangeEvent<HTMLInputElement>) => setFSchoolData((a) => ({...(a || {}), billingEmail: e.target.value}) as SchoolData)} type={"email"} value={fSchoolData?.billingEmail}/>
                    </Grid>
                    {fSchoolData?.authorisedMISSystem && <Grid item xs={12}>
                        <Divider sx={{mb: 2, mt: 2}}/>
                        <Typography variant="h5">MIS Integration</Typography>
                    </Grid>}
                    {fSchoolData?.authorisedMISSystem?.name === "arbor" && <Grid item xs={12}>
                        <List>
                            <ListItem>
                                <ListItemAvatar><Avatar src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRrtS5I9mvhgqe0jPme8V1YRz05ldzFp2Jj9Q&s"/></ListItemAvatar>
                                <ListItemText
                                    sx={{pr: 8}}
                                    primary={"Arbor MIS system"}
                                    secondary={"Read-only access to student forename, surname, email and parent email."}/>
                                <ListItemSecondaryAction>
                                    <Chip color="primary" label={fSchoolData.authorisedMISSystem.data.url}/>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </List>
                    </Grid>}
                </Grid>
            </Form>
        </Popup>
    );
}
