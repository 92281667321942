import {SendRounded} from "@mui/icons-material";
import {ChangeEvent, useState} from "react";
import InputGroup from "./FormComponents/InputGroup";
import IconButtonPop from "./IconButtonPop";


type Params = {
    placeholder: string,
    onMessage: (message:string) => void
}

export function MessageBox({placeholder, onMessage}:Params) {
    const [message, setMessage] = useState<string>();


    return (
        <form onSubmit={(e) => {
            e.preventDefault();
            message && onMessage(message);
            setMessage("");
        }}>
            <div style={{position: "relative"}}>
                <InputGroup {...{placeholder}} value={message} multiline onChange={(e:ChangeEvent<HTMLInputElement>) => setMessage(e.target.value)}/>
                <IconButtonPop responsive={false} onClick={() => {
                    message && onMessage(message);
                    setMessage("");
                }} sx={{position: "absolute", right: "0", top: "50%", transform: "translateY(-50%)"}} title={"Search"}><SendRounded/></IconButtonPop>
            </div>
        </form>
    );
}
