import {SquareRounded} from "@mui/icons-material";
import {Button, Popover} from "@mui/material";
import {PRIMARY_COLOUR} from "placementt-core";
import {useState} from "react";
import {ChromePicker} from "react-color";


export default function CustomColourPicker({color, onColorChange}:{color?: string, onColorChange: (hex: string) => void | Promise<void>}) {
    const [colorAnchorEl, setColorAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setColorAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setColorAnchorEl(null);
    };

    const open = Boolean(colorAnchorEl);
    const colorPopupId = open ? "color-popover" : undefined;

    return (
        <>
            <Button onClick={handleClick} sx={{width: "100%", minWidth: "max-content", lineHeight: "19px", pl: 1, pr: 1}} startIcon={<SquareRounded sx={{color: color}}/>}>Select colour</Button>
            <Popover
                id={colorPopupId}
                open={open}
                anchorEl={colorAnchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}>
                <ChromePicker onChange={(e) => onColorChange(e.hex)} color={color || PRIMARY_COLOUR}/>
            </Popover>
        </>
    );
}

