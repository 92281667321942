import {Avatar, Box, Button, Checkbox, Chip, Divider, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Stack, Table, TableBody, TableRow, Typography} from "@mui/material";
import {capitaliseWords, camelCaseToNormal, InstituteData, CohortData, SUCCESSTEXTCOLOR, ERRORTEXTCOLOR, INSTITUTE_COLOUR, BillingPackage, SchoolData, getAccess} from "placementt-core";
import SubNavBar, {SubNavBarItems} from "../../../Components/Navigation/SubNavBar";
import Page from "../../../Components/Page";
import {InfoTableCell} from "../../../Util/styledComponents";
import {useContext, useEffect, useState} from "react";
import {OrganisationContext, UserContext} from "../../../App";
import Card from "../../../Components/Card";
import Profile from "../../Shared/Profile";
import Resources from "../../Shared/Resources";
import {Popup} from "../../../Components/Popup";
import {LoadingButton} from "../../../Components/LoadingButton";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import FilterList from "../../../Components/FilterList";
import IconButtonPop from "../../../Components/IconButtonPop";
import {Delete, Edit} from "@mui/icons-material";
import DeletePopup from "../../../Components/DeletePopup";
import {ProfilePic} from "../../Providers/Home";
import BillingTable from "../../../Components/BillingTable";
import AddSchoolPopup from "./Cohorts/Popups/AddSchoolPopup";
import CustomColourPicker from "../../../Components/CustomColourPicker";
import Staff from "./Staff";


export default function Organisation() {
    const user = useContext(UserContext);
    const items:SubNavBarItems = [
        {
            label: "Overview",
            route: "overview",
            element: <Overview/>,
        },
        (getAccess(user, "viewStaff") && {
            label: "Staff",
            route: "staff",
            element: <Staff/>,
        }),
        {
            label: "Profile",
            route: "profile",
            element: <Profile/>,
        },
        {
            label: "Resources",
            route: "resources",
            element: <Resources/>,
        },
        {
            label: "Billing",
            route: "billing",
            element: <Billing/>,
        },
    ].filter((i) => i);
    return (<SubNavBar items={items}/>);
}

function Overview() {
    const [delPopupActive, setDelPopupActive] = useState(false);
    const [delPopupError, setDelPopupError] = useState("");
    const [deleteSchool, setDeleteSchool] = useState<{id: string, school: SchoolData}>();

    const [schoolPopup, setSchoolPopup] = useState<{id: string, school: SchoolData}|boolean>(false);

    const orgContext = useContext(OrganisationContext);
    const institute = orgContext.details as InstituteData;
    const user = useContext(UserContext);
    const cohorts = orgContext.cohorts as {[key: string]: CohortData};
    const schools = orgContext.schools as {[key: string]: SchoolData};


    const firebaseQuery = new FirebaseQuery();

    const onDeleteAddress = async () => {
        if (!deleteSchool) return;

        if (Object.values(cohorts).filter((cohort) => cohort.schoolId === deleteSchool.id).length > 0) {
            setDelPopupError("Cannot delete a school with active cohorts.");
        }
        await firebaseQuery.delete(["schools", deleteSchool.id]);

        setDelPopupActive(false);
        setDeleteSchool(undefined);
    };

    return (
        <Page
            title="Overview"
            metaTitle="Setup | Overview"
            grid>
            <Card grid lg={5} md={6} sm={12} title={"Organisation details"} contentSx={{overflow: "hidden"}}>
                <Stack direction={"row"} spacing={2}>
                    <ProfilePic/>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <InfoTableCell>Institute</InfoTableCell>
                                <InfoTableCell>{institute.name}</InfoTableCell>
                            </TableRow>
                            <TableRow>
                                <InfoTableCell>Primary color</InfoTableCell>
                                <InfoTableCell><CustomColourPicker color={institute.color} onColorChange={(e) => firebaseQuery.update(["institutes", user.oId], {color: e})}/></InfoTableCell>
                            </TableRow>
                            <TableRow>
                                <InfoTableCell colSpan={2}><Button color="error" onClick={() => alert("If you would like to delete your account, please get in touch by emailing tom@placementt.co.uk.")}>Delete account</Button></InfoTableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Stack>
            </Card>
            {institute.package === "institutes-two" && <FilterList loadMoreIcon={"loaded"} title="Schools" actionButton={institute.package === "institutes-two" && <Button onClick={() => setSchoolPopup(true)}>Add school</Button>} data={schools || {}} grid xs={12} md={7}>
                {Object.entries(schools || {}).map(([id, school]) =>
                    <ListItemButton key={id} divider id={id} onClick={() => setSchoolPopup({id: id, school: school})}>
                        <Stack direction={"row"} justifyContent={"space-between"} width={"100%"}>
                            {school.image && <ListItemAvatar>
                                <Avatar sx={{height: "80px", width: "80px"}} alt="School picture" src={school.image} />
                            </ListItemAvatar>}
                            <ListItemText
                                primary={<Stack spacing={0}>
                                    <Typography fontSize={"1.2em"} fontWeight={"bold"} color={school.color || undefined} pb={1}>{school.name}</Typography>
                                    <Typography color={"grey"}>{school["address-line1"]} | {school.postal_code} | {capitaliseWords(camelCaseToNormal(school.country))}</Typography>
                                    <Typography><strong>Associated cohorts:</strong> {Object.values(cohorts).filter((cohort) => cohort.schoolId === id).length}</Typography>
                                    <Typography><strong>Billing email:</strong> {school.billingEmail || "Unset: Admin email"}</Typography>
                                    {school.authorisedMISSystem && <Typography><strong>MIS integration:</strong> {capitaliseWords(school.authorisedMISSystem.name)}</Typography>}
                                </Stack>}/>
                            <Stack spacing={1}>
                                <Stack direction={"row"}>
                                    <Chip sx={{width: "max-content", alignSelf: "end"}} color={school.stage !== "complete" ? "warning" : "primary"} label={school.stage !== "complete" ? "Complete set up" : "Active"}/>
                                    {Boolean(school?.flags?.length) && school.flags?.map((flag) => <Chip sx={{width: "max-content", alignSelf: "end"}} color="warning" label={capitaliseWords(camelCaseToNormal(flag))}/>)}
                                    {school.default && <Chip sx={{width: "max-content", alignSelf: "end"}} color="primary" label={"Default"}/>}
                                </Stack>
                                <Stack direction={"row"} sx={{justifyContent: "flex-end"}}>
                                    <IconButtonPop responsive={false} title="Edit school" onClick={() => setSchoolPopup({id: id, school: school})}><Edit/></IconButtonPop>
                                    {(Object.values(cohorts).filter((cohort) => cohort.schoolId === id).length > 0) || <IconButtonPop responsive={false} onClick={async (e) => {
                                        e.stopPropagation();
                                        setDeleteSchool({id: id, school: school});
                                        setDelPopupActive(true);
                                    }} title="Delete school"><Delete/></IconButtonPop>}
                                </Stack>
                            </Stack>
                        </Stack>
                    </ListItemButton>)}
            </FilterList>}
            <DeletePopup
                open={delPopupActive}
                onClose={() => {
                    setDelPopupActive(false); setDeleteSchool(undefined); setDelPopupError("");
                }}
                error={delPopupError}
                title={"school"}
                itemName={deleteSchool?.school?.name}
                // For deleting workflow form or attachment, need to make sure no workflow is using hte attachment. Just add a popup for this as nonessential.
                onDelete={async () => await onDeleteAddress()}/>
            {institute.package === "institutes-two" && <AddSchoolPopup key={schoolPopup.toString()} onSubmit={() => setSchoolPopup(false)} onToggle={() => setSchoolPopup(false)} active={Boolean(schoolPopup)} schoolId={typeof schoolPopup === "object" ? schoolPopup.id : undefined} initialSchool={typeof schoolPopup === "object" ? schoolPopup.school : undefined}/>}
        </Page>);
}


function Billing() {
    const [updatePlanPopup, setUpdatePlanPopup] = useState(false);
    const [currentPackage, setCurrentPackage] = useState<BillingPackage>();


    const [addOnPopup, setAddOnPopup] = useState(false);

    const orgDetails = useContext(OrganisationContext).details as InstituteData;
    const user = useContext(UserContext);

    const [selectedPackage, setSelectedPackage] = useState(orgDetails.package);
    const [selectedAddOnPackages, setSelectedAddOnPackages] = useState(orgDetails.addOnPackages);

    const firebaseQuery = new FirebaseQuery();

    useEffect(() => {
        (firebaseQuery.getDocData(["billing", orgDetails.package as string]) as Promise<BillingPackage>).then(setCurrentPackage);
    }, [orgDetails.package]);


    return (
        <Page
            title="Billing"
            metaTitle="Setup | Billing"
            grid>
            <Card grid sm={12} md={6} title={"Your Placementt plan"} secondaryTitle={<Button onClick={() => setUpdatePlanPopup(true)}>Update plan</Button>}>
                <Divider sx={{borderColor: INSTITUTE_COLOUR, mb: 2}}/>
                <Typography variant="h6" color={INSTITUTE_COLOUR}>{currentPackage?.name}</Typography>
                <Typography variant="subtitle2" sx={{opacity: 0.7}}>{currentPackage?.description}</Typography>
                <Table size="small">
                    <TableRow>
                        <InfoTableCell>Yearly platform fee</InfoTableCell>
                        <InfoTableCell>£{currentPackage?.platformFee}</InfoTableCell>
                    </TableRow>
                    <TableRow>
                        <InfoTableCell>Price / student / year</InfoTableCell>
                        <InfoTableCell>£{currentPackage?.studentUnitCost}</InfoTableCell>
                    </TableRow>
                    <TableRow>
                        <InfoTableCell>Price / placement / month</InfoTableCell>
                        <InfoTableCell>£{currentPackage?.placementUnitCost}</InfoTableCell>
                    </TableRow>
                </Table>
                {orgDetails.package === "institutes-two" && <Stack>
                    <Divider/>
                    <Typography variant="h6">Split billing</Typography>
                    <Typography sx={{opacity: 0.7}}>Split billing across your cohorts, so we invoice them all individually. This can be useful if managing multiple schools. You can still see the invoices, but you can designate staff members in each cohort.</Typography>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography>Split billing: <strong style={{color: orgDetails.splitBilling ? SUCCESSTEXTCOLOR : ERRORTEXTCOLOR}}>{orgDetails.splitBilling ? "Active" : "Inactive"}</strong></Typography>
                        <Box sx={{flex: 1, borderBottom: "1px solid lightgrey"}}/>
                        <LoadingButton noFlexGrow onClick={async () => firebaseQuery.update(["institutes", user.oId], {splitBilling: !orgDetails.splitBilling})} text={orgDetails.splitBilling ? "Disable" : "Enable"}/>
                    </Stack>
                </Stack>}
            </Card>
            <Card grid sm={12} md={6} title={"Add-Ons"} secondaryTitle={<Button onClick={() => setAddOnPopup(true)}>Update add-ons</Button>}>
                <Typography>Add-on packages to help you support your students.</Typography>
                <List>
                    {orgDetails.addOnPackages?.map((addOn) =>
                        <ListItem divider>
                            <ListItemText primary={addOn.toUpperCase()} secondary={"Manage work experience evidencing as part of your work experience process and automate collection of placement details and signoff from employers."}/>
                        </ListItem>)}
                </List>
            </Card>
            <Card grid sm={12} md={6} title={"Invoices"}>

            </Card>

            <Popup title={"Update your plan"} fullWidth open={updatePlanPopup} onClose={() => setUpdatePlanPopup(false)}>
                <Stack>
                    <BillingTable product={user.product} {...{selectedPackage, setSelectedPackage}}/>
                    <LoadingButton variant="contained" onClick={async () => user.oId ? firebaseQuery.update(["institutes", user.oId], {package: selectedPackage}) : new Error("NO ID")} text="Update package"/>
                </Stack>
            </Popup>
            <Popup title={"Configure add-ons"} fullWidth open={addOnPopup} onClose={() => setAddOnPopup(false)}>
                <Stack>
                    <List>
                        <ListItem divider>
                            <ListItemText primary={<Stack direction={"row"} alignItems={"center"}>
                                <Checkbox
                                    checked={selectedAddOnPackages?.includes("admissions")}
                                    onChange={() => setSelectedAddOnPackages((oldPackages) => {
                                        if (!oldPackages) return ["admissions"];
                                        if (!oldPackages?.includes("admissions")) {
                                            return [...oldPackages, "admissions"];
                                        }
                                        return oldPackages.filter((i) => i !== "admissions");
                                    })}
                                    name="radio-buttons"
                                    inputProps={{"aria-label": "admissions"}}
                                />
                                <Typography>Admissions add-on</Typography>
                            </Stack>} secondary={"Manage work experience evidencing as part of your work experience process and automate collection of placement details and signoff from employers."}/>
                        </ListItem>
                    </List>
                    <LoadingButton variant="contained" onClick={async () => orgDetails.id ? firebaseQuery.update(["institutes", orgDetails.id], {addOnPackages: selectedAddOnPackages}) : new Error("NO ID")} text="Update add-ons"/>
                </Stack>
            </Popup>
        </Page>);
}
