import {Chip, Grid} from "@mui/material";
import Card from "../../Components/Card";
import SubNavBar, {SubNavBarItems} from "../../Components/Navigation/SubNavBar";
import Page from "../../Components/Page";
import {Address, PlacementListing, QueryObject, camelCaseToNormal, capitaliseWords, useProviderContactPaginator} from "placementt-core";
import DeletePopup from "../../Components/DeletePopup";
import {useContext, useState} from "react";
import {UserContext} from "../../App";
import PlacementMap from "../Shared/PlacementMap";
import Form from "../../Components/Form";
import InputGroup from "../../Components/FormComponents/InputGroup";
import Dropdown from "../../Components/FormComponents/Dropdown";
import DataViewer from "../../Components/DataViewer";


export default function Placements() {
    const items:SubNavBarItems = [
        {
            label: "Outreach",
            route: "outreach",
            element: <PlacementOutreach/>,
        },
        {
            label: "List view",
            route: "list",
            element: <PlacementList/>,
        },
        {
            label: "Map view",
            route: "map",
            element: <PlacementsMap/>,
        }];

    return (
        <SubNavBar items={items}/>
    );
}

function PlacementOutreach() {
    const scrapePlacements = async (e: Address) => {
        console.log("address", JSON.stringify({
            query: Object.values(e).map((v) => v).join(","),
        }));
        return await fetch("https://placementt-api.vercel.app/api/potentialproviders/getproviders", {
            method: "POST",
            body: JSON.stringify({
                query: Object.values(e).map((v) => v).join(","),
            }),
            headers: {"Content-Type": "application/json"}});
    };

    return (
        <Page title="Student Users" grid>
            <Card grid xs={12} md={4} title={"Scrape businesses in area"}>
                <Form onSubmit={(e) => scrapePlacements(e as Address)}>
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <InputGroup required id="address-line1" label="Address line one" name="address-line1"/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputGroup id="address-line2" label="Address line two" name="address-line2"/>
                        </Grid>
                        <Grid item xs={12}>
                            <InputGroup required id="locality" label="City" name="locality"/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <InputGroup required id="postcode" label="Postcode" name="postal_code"/>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Dropdown required id="country" label="Country" name="country"/>
                        </Grid>
                    </Grid>
                </Form>
            </Card>
        </Page>
    );
}

function PlacementList() {
//     const [viewPlacementPopup, setViewPlacementPopup] = useState<PlacementListing|false>(false);
    // const [viewPlacementContent, setViewPlacementContent] = useState<PlacementListing>();
    const [deletePlacementPopup, setDeletePlacementPopup] = useState<PlacementListing|false>(false);
    const user = useContext(UserContext);

    // useEffect(() => {
    //     if (viewPlacementPopup) {
    //         setViewPlacementContent(viewPlacementPopup);
    //         return;
    //     }
    // }, [viewPlacementPopup]);

    const placementListingQuery: QueryObject[] = [{
        path: ["placementListings"],
        where: [["uploadedBy", "==", "admin"]],
        orderBy: "documentId",
    },
    {
        path: ["placementListings"],
        where: [["uploadedBy", "==", "provider"]],
        orderBy: "documentId",
    }];

    // const approvePlacement = async () => {
    //     console.log("Approve function not built yet.");
    // };

    const rejectPlacement = async () => {
        console.log("Reject function not built yet.");
    };

    return (
        <Page title="Student Users" grid>
            <Card grid xs={12} md={4} title={""}>
            </Card>
            <Grid item xs={12}></Grid>
            <Grid xs={12} item>
                {false && <DataViewer
                    columns={["name", "title", "email", "uploadedBy", "status"]}
                    columnTemplate={{name: (name: string) => <strong>{name}</strong>, uploadedBy: (name: string) => <Chip label={capitaliseWords(name)}/>, status: (name: string) => <Chip variant="filled" color={name === "active" ? "success" : name === "public" ? "primary" : name === "requiresApproval" ? "warning" : undefined} label={capitaliseWords(camelCaseToNormal(name))}/>}}
                    viewRows
                    // onItemClick={(_, row) => setViewPlacementPopup(row as PlacementListing)}
                    hook={(e: any) => useProviderContactPaginator({...e, user: user})}
                    data={placementListingQuery}/>}
            </Grid>
            <DeletePopup open={Boolean(deletePlacementPopup)} onClose={() => setDeletePlacementPopup(false)} title={"placement"} onDelete={async () => await rejectPlacement()}/>
            {/* {false && < open={Boolean(viewPlacementPopup)} onClose={() => setViewPlacementPopup(false)} placement={viewPlacementContent} onApprove={async () => await approvePlacement()} onDeletePopupToggled={() => setDeletePlacementPopup(viewPlacementContent || false)}/>} */}
        </Page>
    );
}

function PlacementsMap() {
    return (
        <Page fullHeight noGutters>
            <PlacementMap fullscreen={false} title="Search placements" type="internal"/>
        </Page>);
}
