import "./NavBar.css";

// Canm customise these in other projects. No point being props as will always be the same.
import logo from "../../Images/logo.svg";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Alert, Box, Button, CardContent, Icon, List, ListItemButton, ListItemSecondaryAction, ListItemText, Popover, Stack, Typography, useTheme} from "@mui/material";
import {signOut} from "firebase/auth";
import {AccessTime, CheckCircle, DataUsage, Error, LogoutRounded} from "@mui/icons-material";
import {keyframes} from "@mui/system";
import React, {ReactElement, useContext, useEffect, useState} from "react";
import {arrayUnion, limit, orderBy, where} from "firebase/firestore";
import {UserContext} from "../../App";
import IconButtonPop from "../IconButtonPop";
import {INSTITUTE_COLOUR, Job, PRIMARY_COLOUR, PROVIDER_COLOUR, Products, STUDENT_COLOUR, auth, capitalise, setJobs, setMarkRead, useAppDispatch, useAppSelector} from "placementt-core";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {TitleLogo, TitleText} from "../../Util/styledComponents";
import {NavbarNotifications} from "../NavbarNotifications";
import {useCheckMobileScreen} from "../../Util/util";
import styled from "styled-components";
import {Popup} from "../Popup";

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

const RotatingJob = styled(DataUsage)`
  animation: ${rotate} 2s linear infinite;
`;

const BurgerMenu = styled(Box)<{open?: boolean}>(({open}) => ({
    "flex": 1,
    "margin-left": "20px",

    "@media only screen and (max-width: 1093px)": {
        "#burger": {
            display: "flex",
        },
        ".innerStack": {
            "flexDirection": "column",
            "margin": 0,
            "a": {
                "margin": 0,
                "marginTop": 5,
            },
            "button": {
                "margin": 0,
                "marginTop": 10,
            },
        },
        "#link-holder": {
            position: "absolute",
            background: "white",
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            fontSize: "1.2em",
            left: 0,
            transition: "all 400ms ease-in-out",
            transform: `translateX(${open ? 0 : "100%"})`,
            top: "49px",
            width: "100%",
            height: "45vh",
            boxShadow: "0px 4px 8px -4px rgba(163,163,163,0.59)",
            justifyContent: "space-around",
        },
    },
}));

const Burger = styled(Box)<{open?: boolean}>(({theme, open}) => ({
    "top": "5%",
    "float": "right",
    "display": "none",
    "flexDirection": "column",
    "justifyContent": "space-around",
    "width": "2rem",
    "height": "2rem",
    "background": "transparent",
    "border": "none",
    "cursor": "pointer",
    "padding": 0,
    "zIndex": 10,

    "&:focus": {
        outline: "none",
    },

    "div": {
        "width": "2rem",
        "height": "0.25rem",
        "backgroundColor": (theme as any).palette.primary.main,
        "borderRadius": "10px",
        "transition": "all 0.3s linear",
        "position": "relative",
        "transformOrigin": "1px",

        ":first-child": {
            transform: open ? "rotate(45deg)" : "rotate(0)",
        },

        ":nth-child(2)": {
            opacity: open ? "0" : "1",
            transform: open ? "translateX(20px)" : "translateX(0)",
        },

        ":nth-child(3)": {
            transform: open ? "rotate(-45deg)" : "rotate(0)",
        },
    },
}));

const NavBarLink = styled(Link)(({theme}) => ({
    "textDecoration": "none",
    "color": theme.palette.primary.main,
    "paddingLeft": theme.spacing(2),
    "paddingRight": theme.spacing(2),
    "fontWeight": "bold",
    "&:hover": {
        textDecoration: "underline",
    }}));

export const NAVBARHEIGHT = "60px";

export default function NavBar() {
    const navigate = useNavigate();
    const location = useLocation();
    const fSignOut = async () => {
        await signOut(auth); navigate("/login");
    };
    const path = location.pathname.split("/");
    const [menuOpen, setMenuOpen] = useState(false);
    const isMobile = useCheckMobileScreen();
    const user = useContext(UserContext);

    // const [anchorEl, setAnchorEl] = React.useState(null);

    // function handleClick(event: any) {
    //     if (anchorEl !== event.currentTarget) {
    //         setAnchorEl(event.currentTarget);
    //     }
    // }

    // function handleClose() {
    //     setAnchorEl(null);
    // }


    // All staff can see jobs, but maybe add as a user permission

    const renderLinks = () => {
        switch (user.id && path[1]) {
        case "providers":
            return (
                <Stack direction={"row"} justifyContent={"end"} spacing={1}>
                    {user?.userType === "Staff" && <Jobs/>}
                    <NavbarNotifications key={"notifications"}/>
                    <IconButtonPop responsive={false} title={"Sign out"} key={"sign-out"} sx={{marginLeft: "16px"}} onClick={fSignOut}><LogoutRounded/></IconButtonPop>
                </Stack>);
        case "institutes":
            return (
                <Stack direction={"row"} justifyContent={"end"} spacing={1}>
                    {user?.userType === "Staff" && <Jobs/>}
                    <NavbarNotifications key={"notifications"}/>
                    <IconButtonPop responsive={false} title={"Sign out"} key={"sign-out"} onClick={fSignOut}><LogoutRounded/></IconButtonPop>
                </Stack>
            );
        case "students":
            return (
                <Stack direction={"row"} justifyContent={"end"} spacing={1}>
                    {false && <NavbarNotifications key={"notifications"}/>}
                    <IconButtonPop responsive={false} title={"Sign out"} key={"sign-out"} onClick={fSignOut}><LogoutRounded/></IconButtonPop>
                </Stack>);
        case "admin":
            return (
                <Stack direction={"row"} justifyContent={"end"} spacing={1}>
                    {<Jobs/>}
                    <IconButtonPop responsive={false} title={"Sign out"} key={"sign-out"} onClick={fSignOut}><LogoutRounded/></IconButtonPop>
                </Stack>);
        default:
            return (
                [<Burger id={"burger"} open={menuOpen} onClick={() => setMenuOpen((m) => !m)}><div></div><div></div><div></div></Burger>,
                    <Stack direction={isMobile ? "column" : "row"} id={"link-holder"} flex={1} justifyContent={"space-between"} alignItems={"center"}>
                        <Stack className="innerStack" direction={"row"} flexWrap={"wrap"}>
                            {/* <NavBarLink onClick={() => setMenuOpen(false)} onMouseOver={(e) => handleClick(e)} style={{textDecoration: anchorEl ? "underline" : undefined, zIndex: 1500}} key={"educators"} to="/educators">Educators</NavBarLink>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                hideBackdrop={true}
                                disableScrollLock
                                disableEnforceFocus
                                MenuListProps={{onMouseLeave: handleClose}}
                                sx={{
                                    "pointerEvents": "none", // Prevent the entire menu from blocking
                                    "& .MuiPaper-root": {
                                        pointerEvents: "auto", // Allow interaction only with the menu items
                                        marginTop: "8px", // Adjust position so it doesn't overlap the button
                                    },
                                }}>
                                <Typography p={2} color={"primary"} fontWeight={"bold"}>Learn How We Support Educators</Typography>
                                <MenuItem onClick={() => {
                                    navigate("secondary-schools");
                                    handleClose();
                                }}>
                                    <Typography flex={1}>Secondary Schools</Typography>
                                    <ListItemIcon>
                                        <ArrowForward/>
                                    </ListItemIcon>
                                </MenuItem>
                                <MenuItem onClick={() => {
                                    navigate("multi-academy-trusts");
                                    handleClose();
                                }}>
                                    <Typography flex={1}>Multi-Academy Trusts</Typography>
                                    <ListItemIcon>
                                        <ArrowForward/>
                                    </ListItemIcon>
                                </MenuItem>
                                <MenuItem onClick={() => {
                                    navigate("universities");
                                    handleClose();
                                }}>
                                    <Typography flex={1}>Universities</Typography>
                                    <ListItemIcon>
                                        <ArrowForward/>
                                    </ListItemIcon>
                                </MenuItem>
                            </Menu> */}
                            <NavBarLink onClick={() => setMenuOpen(false)} key={"secondary-schools"} to="/secondary-schools">Secondary Schools</NavBarLink>
                            <NavBarLink onClick={() => setMenuOpen(false)} key={"multi-academy-trusts"} to="/multi-academy-trusts">Multi-Academy Trusts</NavBarLink>
                            <NavBarLink onClick={() => setMenuOpen(false)} key={"student"} to="/student">Students</NavBarLink>
                            <NavBarLink onClick={() => setMenuOpen(false)} key={"blogs"} to="/blogs">Blogs</NavBarLink>
                            <NavBarLink onClick={() => setMenuOpen(false)} key={"about-us"} to="/about-us">About Us</NavBarLink>
                            {/* <NavBarLink onClick={() => setMenuOpen(false)} key={"resources"} to="/resources">Resources</NavBarLink> */}
                        </Stack>
                        {user.id && user.email ? <Button sx={{alignSelf: "center"}} onClick={() => {
                            navigate(`/${user?.product || "admin"}/home`);
                        }}>Dashboard</Button> :
                            <Stack className="innerStack" direction={"row"} alignItems={"center"} flexWrap={"wrap"}>
                                <Button variant="contained" onClick={() => window.location.href = "mailto:tom@placementt.co.uk"}>Get In Touch</Button>
                                <Button onClick={() => {
                                    navigate("/login");
                                    setMenuOpen(false);
                                }}>Login</Button>
                            </Stack>}
                    </Stack>]
            );
        }
    };

    const renderTrailingTitle = (product:Products) => {
        if (!["providers", "institutes", "students", "admin"].includes(product)) return null;

        const title = product === "admin" ? "admin" : product === "providers" ? "Business" : product.substring(0, product.length - 1);
        const colors = {
            providers: PROVIDER_COLOUR,
            institutes: INSTITUTE_COLOUR,
            students: STUDENT_COLOUR,
            admin: PRIMARY_COLOUR,
        };

        return (<Typography fontSize={"1.2em"} fontWeight={"bold"} color={colors[product]} display={"inline"}>{isMobile || " - "}{capitalise(title)}</Typography>);
    };

    return (
        <>
            <div className="navbar" style={{height: NAVBARHEIGHT}}>
                <div className="inner">
                    <table onClick={() => {
                        navigate("/");
                        setMenuOpen(false);
                    }} style={{cursor: "pointer"}}>
                        <tbody>
                            <tr>
                                <td>
                                    <TitleLogo src={logo as any} alt="Placementt main logo, three rings inside each other, each a different shade of purple."/>
                                </td>
                                <td>
                                    {isMobile || <TitleText className="title">Placementt</TitleText>}{renderTrailingTitle(path[1] as Products)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <BurgerMenu open={menuOpen}>
                        {renderLinks()}
                    </BurgerMenu>
                </div>
            </div>
        </>
    );
}

const NotificationContainer = styled(CardContent)`
    padding: 10px;
    width: 500px;
    max-width: 100%;
`;

function Jobs() {
    const [open, setOpen] = useState<EventTarget|null>(null);
    const [icon, setIcon] = useState<ReactElement>(); // Jobs, Error, Progress, Success
    const [openJob, setOpenJob] = useState<Job>();
    const firebaseQuery = new FirebaseQuery();

    const jobs = useAppSelector((state) => state.jobs.values) as {[key: string]: Job};
    const dispatch = useAppDispatch();

    const user = useContext(UserContext);
    const theme = useTheme();

    useEffect(() => {
        firebaseQuery.getDocsWhere("jobs", [where("oId", "==", user.oId), where("product", "==", user.product), orderBy("updated", "desc"), limit(1)]).then((jobs) => {
            dispatch(setJobs(jobs));
            console.log("sff");
        });
    }, []);
    useEffect(() => {
        if (!open || Object.keys(jobs).length > 1) return;
        firebaseQuery.getDocsWhere("jobs", [where("oId", "==", user.oId), where("product", "==", user.product), orderBy("updated", "desc"), limit(5)]).then((jobs) => {
            dispatch(setJobs(jobs));
            console.log("sf");
        });
    }, [open]);
    useEffect(() => {
        if (Object.keys(jobs).length === 0) {
            return;
        }

        const unreadJobs = Object.entries(jobs).filter(([, job]) => (!job.viewedBy.includes(user.id))).length;
        if (unreadJobs === 0) {
            setIcon(<AccessTime/>);
            return;
        }

        const currentJob = Object.entries(jobs)[0][1];
        switch (currentJob.status) {
        case "processing":
            setIcon(<RotatingJob color={"warning"}/>);
            break;
        case "success":
            setIcon(<CheckCircle color={"success"}/>);
            break;
        case "error":
            setIcon(<Error color={"error"}/>);
            break;
        case "warning":
            setIcon(<Error color={"warning"}/>);
            break;
        default:
            setIcon(<AccessTime/>);
            break;
        }
    }, [jobs]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    if (Object.keys(jobs).length === 0) {
        return null;
    }

    const markAsRead = (id: string) => {
        dispatch(setMarkRead({
            jobId: id,
            userId: user.id,
        }));
        firebaseQuery.update(["jobs", id], {
            viewedBy: arrayUnion(user.id),
        });
    };

    const markAllAsRead = () => {
        Object.entries(jobs).filter(([, job]) => (!job.viewedBy.includes(user.id))).forEach(([id]) => {
            dispatch(setMarkRead({
                jobId: id,
                userId: user.id,
            }));
            firebaseQuery.update(["jobs", id], {
                viewedBy: arrayUnion(user.id),
            });
        });
    };


    return (
        <>
            <IconButtonPop disableRipple responsive={false} aria-describedby={"jobs-button"} title={"Jobs"} onClick={handleClick}>
                {icon as ReactElement}
            </IconButtonPop>
            <Popover
                open={Boolean(open)}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={{top: 40, left: window.innerWidth}}
                anchorEl={open as any}
                id={"jobs-popup"}
            >
                <NotificationContainer>
                    <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                        <Typography variant='h6' sx={{width: "max-content"}}>Jobs</Typography>
                    </Box>
                    {Object.keys(jobs).length > 0 && <List sx={{maxHeight: "500px", overflow: "auto"}}>
                        {Object.entries(jobs).map(([id, job]) => {
                            const read = job.viewedBy.includes(user.id);
                            let state;
                            switch (job.status) {
                            case "processing":
                                state = <RotatingJob color={"warning"}/>;
                                break;
                            case "success":
                                state = <CheckCircle color={"success"}/>;
                                break;
                            case "error":
                                state = <Error color={"error"}/>;
                                break;
                            case "warning":
                                state = <Error color={"warning"}/>;
                                break;
                            default:
                                break;
                            }

                            return (

                                <ListItemButton key={id} divider onClick={() => {
                                    markAsRead(id);
                                    setOpenJob(job);
                                }}
                                sx={{flexDirection: "column", alignItems: "flex-start", borderLeft: read ? undefined : `4px solid ${theme.palette.primary.main}`}}
                                >
                                    <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
                                        <Typography variant="body2">{job.title}</Typography>
                                    </Box>
                                    <ListItemText
                                        sx={{marginTop: "-5px"}}
                                        primary={job.description}
                                        secondary={job.outputMsg}
                                    />
                                    <Typography mt={-1} fontSize={"0.8em"} color={PRIMARY_COLOUR} sx={{textDecoration: "underline"}}>More info</Typography>
                                    <ListItemSecondaryAction sx={{top: "25px"}}>
                                        <Icon>
                                            {state}
                                        </Icon>
                                    </ListItemSecondaryAction>
                                </ListItemButton>

                            );
                        })}
                    </List>}
                    <Typography onClick={markAllAsRead} color={"primary"} sx={{textDecoration: "underline", cursor: "pointer"}} textAlign={"right"}>Mark all as read</Typography>
                    <Popup open={Boolean(openJob)} onClose={() => setOpenJob(undefined)} title={"View job: "+openJob?.title}>
                        <Stack>
                            <Typography variant="h6">Logs:</Typography>
                            {openJob?.logs?.map((log) => <Alert severity={log.severity as any || "warning"}>
                                <Typography fontWeight={"bold"}>{log.title}</Typography>
                                <Typography>{log.description}</Typography>
                            </Alert>)}
                        </Stack>
                    </Popup>
                </NotificationContainer>
            </Popover>
        </>
    );
}

