import {Box, Grid, Stack, Typography} from "@mui/material";
import Page from "../../Components/Page";
import {useParams} from "react-router-dom";
import {ChangeEvent, useEffect, useState} from "react";
import {executeCallable, PRIMARY_COLOUR} from "placementt-core";
import FadeInBox from "../../Components/FadeInBox";
import Preloader from "../../Components/Preloader";
import Form from "../../Components/Form";
import InputGroup from "../../Components/FormComponents/InputGroup";


export default function AlumniConversationsEntry() {
    const {oId, schoolId} = useParams();

    type AlumniConversationsInstituteData = {
        instituteName: string,
        primaryColor?: string,
        primaryImage?: string,
        domain: string
    }

    const [page, setPage] = useState<"loading"|"form"|"submitting"|"complete">("loading");
    const [instituteData, setInstituteData] = useState<AlumniConversationsInstituteData>();
    const [studentDetails, setStudentDetails] = useState<Partial<{forename: string, surname: string, email: string}>>();

    useEffect(() => {
        executeCallable("alumni-getAlumniConversationsData", {oId: oId, schoolId: schoolId}).then((data) => {
            setInstituteData(data.data as AlumniConversationsInstituteData);
            setPage("form");
        });
    }, [oId, schoolId]);

    const submitAlumniConversationSignUp = async () => {
        // Check email address domain
        if (studentDetails?.email?.split("@")[1] !== instituteData?.domain) {
            throw new Error("Invalid email used. Please use your school email.");
        }
        await executeCallable("alumni-submitAlumniConversationsData", {oId: oId, schoolId: schoolId, student: studentDetails});
        setPage("complete");
    };

    return (
        <Page metaTitle={"Alumni Conversations Sign Up | "+(instituteData?.instituteName || "Placementt")}>
            <FadeInBox card visible={page === "loading"}>
                <Typography variant="h4">Fetching school details</Typography>
                <Typography variant='h6'>This won't take long...</Typography>
                <Preloader visible/>
            </FadeInBox>
            <FadeInBox card visible={Boolean(page === "form" && instituteData)}>
                <Stack alignItems={"center"}>
                    {instituteData?.primaryImage && <Box component={"img"} sx={{width: "100px", height: "100px", mt: 2, mb: 2}} src={instituteData?.primaryImage}/>}
                    <Typography variant='h4' color={instituteData?.primaryColor || PRIMARY_COLOUR}>{instituteData?.instituteName}</Typography>
                    <Typography variant="h5">Interact with our alumni network</Typography>
                    <Typography variant="h6" textAlign={"center"}>Register here with your school email. We will send you a private access link to view alumni and send messages.</Typography>
                    <Form submitText="Register" onSubmit={submitAlumniConversationSignUp}>
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <InputGroup name={"forename"} value={studentDetails?.forename} onChange={(e: ChangeEvent<HTMLInputElement>) => setStudentDetails((a) => ({...a, forename: e.target.value}))} label={"Forename"} placeholder={"John"}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputGroup name={"surname"} value={studentDetails?.surname} onChange={(e: ChangeEvent<HTMLInputElement>) => setStudentDetails((a) => ({...a, surname: e.target.value}))} label={"Surname"} placeholder={"Smith"}/>
                            </Grid>
                            <Grid item xs={12}>
                                <InputGroup name={"email"} value={studentDetails?.email} onChange={(e: ChangeEvent<HTMLInputElement>) => setStudentDetails((a) => ({...a, email: e.target.value}))} required label={"Email"} type={"email"} placeholder={`user@${instituteData?.domain}`}/>
                            </Grid>
                        </Grid>
                    </Form>
                </Stack>
            </FadeInBox>
            <FadeInBox card visible={Boolean(page === "complete" && instituteData)}>
                <Typography>Thanks for signing up to Alumni Conversations. We will send your a custom link to access the platform. Use it whenever you need to access the Alumni Conversations platform on Placementt.</Typography>
            </FadeInBox>
        </Page>
    );
}
