import {Box, Card, CardContent, List, ListItemButton, ListItemSecondaryAction, ListItemText, Stack, Typography} from "@mui/material";
import {where} from "firebase/firestore";
import mapboxGl, {Marker} from "mapbox-gl";
import {PRIMARY_COLOUR, PlacementListing, UserData, average, decodeGeoHash} from "placementt-core";
import FirebaseQuery from "placementt-core/lib/firebase/firebaseQuery";
import {ReactNode, createRef, useContext, useEffect, useRef, useState} from "react";
import geoHash from "ngeohash";
import {AddCircleOutline, ArrowLeft} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {UserContext} from "../../App";
import IconButtonPop from "../../Components/IconButtonPop";
import styled from "styled-components";


const MapContainer = styled(Box)`
    background-color: white;
    width: 100vw;
    height: calc(100vh - 50px);
    position: absolute;
    top: ${(params:{fullscreen: boolean}) => params.fullscreen ? "50px" : 0};
    left: 0;
`;

const Drawer = styled(Card)<{open: boolean}>`
    position: fixed !important;
    height: 100%;
    width: 500px;
    max-width: calc(95vw - 35px);
    z-index: 1200;
    right: 0;
    transform: ${(params) => params.open ? "translate(0, 0)" : "translate(100%, 0)"};
    overflow: visible;
    transition: all 250ms ease-in-out;
`;

mapboxGl.accessToken = "pk.eyJ1IjoidGhvbWFzYmFydG9uMTAwIiwiYSI6ImNrd3dkNjE3MzAyZzcydnExdTZiZ256MXYifQ.B5V75w78TKOYqs8NjeHqUg";

type Params = {
    type?: "internal"|"external",
    title: string,
    secondary?: React.ReactNode,
    center?: string|[number, number],
    fullscreen?: boolean
};

export default function PlacementMap({type, title, secondary, center, fullscreen=true}:Params) {
    const [drawerOpen, setDrawerOpen] = useState(true);


    const mapContainer = useRef<any>(null);
    const map = useRef<mapboxgl.Map | null>(null);

    const navigate = useNavigate();

    const [lng, setLng] = useState<number>(center ? typeof center === "string" ? decodeGeoHash(center)?.longitude as number : center[1] : 1);
    const [lat, setLat] = useState<number>(center ? typeof center === "string" ? decodeGeoHash(center)?.latitude as number : center[0] : 54.7);
    const [zoom, setZoom] = useState<number>(center ? 10 : 5);
    const [placements, setPlacements] = useState<{[key: string]: PlacementListing}>();
    const [placementMarkers, _setPlacementMarkers] = useState<Marker[]>([]);
    const [renderedPlacements, setRenderedPlacements] = useState<ReactNode>();
    const [hoveredPlacement, setHoveredPlacement] = useState("");
    // const [selectedPlacement, setSelectedPlacement] = useState<PlacementListing>();
    // const [viewPlacementPopup, setViewPlacementPopup] = useState<PlacementListing|false>(false);

    // useEffect(() => {
    //     if (viewPlacementPopup) {
    //         setSelectedPlacement(viewPlacementPopup);
    //         return;
    //     }
    // }, [viewPlacementPopup]);

    const firebaseQuery = new FirebaseQuery();
    const scrollRefs = useRef<{[key:string]: React.MutableRefObject<any>}>({});

    const placementMarkersRef = useRef(placementMarkers);

    const setPlacementMarkers = (data:Marker[]) => {
        placementMarkersRef.current = data;
        _setPlacementMarkers(data);
    };

    const user = useContext(UserContext) as UserData;

    useEffect(() => {
        if (map.current) return; // initialize map only once


        map.current = new mapboxGl.Map({
            container: mapContainer.current,
            style: "mapbox://styles/mapbox/streets-v11",
            center: [lng, lat],
            zoom: zoom,
        });

        getMapPlacements(map.current.getBounds(), map.current.getZoom());

        map.current.on("move", () => {
            if (!map.current) return;

            setLng(parseFloat(map.current.getCenter().lng.toFixed(4)));
            setLat(parseFloat(map.current.getCenter().lat.toFixed(4)));
            setZoom(parseFloat(map.current.getZoom().toFixed(2)));
        });

        map.current.on("moveend", () => {
            if (!map.current) return;
            getMapPlacements(map.current.getBounds(), map.current.getZoom());
        });

        return () => {
            if (!map.current) return;
            map.current.off("moveend", () => {
                if (!map.current) return;
                getMapPlacements(map.current.getBounds(), map.current.getZoom());
            });
        };
    }, []);

    const getMapPlacements = async (bounds:mapboxGl.LngLatBounds, zoom:number) => {
        // let placementResults:{[key:string]: StudentPlacementData} = {};
        // let placementCounts:{[key:string]: {count:number, startCode:string, endCode: string}} = {};

        const geoHashLength = Math.ceil(zoom * (9/22));
        if (geoHashLength <= 1) return;

        const latUpper = bounds.getNorthEast().lat;
        const latLower = bounds.getSouthWest().lat;
        const lngUpper = bounds.getNorthEast().lng;
        const lngLower = bounds.getSouthWest().lng;

        let latCurrent = latLower;
        let lngCurrent = lngLower;

        const codes:string[] = [];

        let geoHashCode = geoHash.encode(latLower, lngLower, geoHashLength);
        let geoHashBox = geoHash.decode_bbox(geoHashCode);

        codes.push(geoHashCode);

        while (latCurrent < latUpper) {
            const leftmostRowHashCode = geoHashCode;

            lngCurrent = geoHashBox[3];

            while (lngCurrent < lngUpper) {
                geoHashCode = geoHash.neighbor(geoHashCode, [0, 1]);
                geoHashBox = geoHash.decode_bbox(geoHashCode);
                lngCurrent = geoHashBox[3];
                codes.push(geoHashCode);
            }

            latCurrent = geoHashBox[2];

            if (latCurrent < latUpper) {
                geoHashCode = geoHash.neighbor(leftmostRowHashCode, [1, 0]);
                geoHashBox = geoHash.decode_bbox(geoHashCode);
                codes.push(geoHashCode);
            }
        }

        if (type !== "internal") return;

        const savedPlacements = await firebaseQuery.getDocsWhere(["placementListings"], [where(`savedBy.${user.oId}.exists`, "==", true)]) as {[key:string]: PlacementListing};


        const placementListings = Object.fromEntries((await Promise.all(Object.entries(savedPlacements).map(async ([k, placement]) => {
            if (placement.concurrentPlacements !== undefined) {
                const concurrentPlacements = await firebaseQuery.getCount(["placements"], [where("placementId", "==", k), where("oId", "==", user.oId), where("inProgress", "==", true)]);

                console.log("max placements", placement.concurrentPlacements, "limit reached", placement.concurrentPlacements && (concurrentPlacements >= placement.concurrentPlacements));

                if (concurrentPlacements >= placement.concurrentPlacements) return false;
            }
            if (!placement.providerId) return false;


            return [k, placement];
        }))).filter((i) => i) as [string, PlacementListing][]);


        const placementCounts = [];
        let placementResults:{[key:string]: PlacementListing} = {};

        for (const code of codes) {
            const placementsInArea = Object.fromEntries(Object.entries(placementListings).filter(([, p]) => {
                return p.geoHash?.startsWith(code);
            }));
            const count = Object.keys(placementsInArea).length;

            if (!count) continue;

            if (count > 1) {
                const coords = Object.entries(placementsInArea).reduce((acc, [, placement]) => {
                    const geoHashCode = geoHash.decode(placement.geoHash as string);
                    acc.lat = [...acc.lat, geoHashCode.latitude];
                    acc.lng = [...acc.lng, geoHashCode.longitude];
                    return acc;
                }, {lat: [], lng: []} as {lat: number[], lng: number[]});
                placementCounts.push({lat: average(coords.lat), lng: average(coords.lng), count: count});
                continue;
            }

            placementResults = {...placementResults, ...placementsInArea};
        }

        const currentMarkers:Marker[] = [];

        placementMarkersRef.current.forEach((marker) => marker.remove());

        Object.entries(placementResults).forEach(([id, placement]) => {
            if (!map.current) return;

            const geoHashCode = geoHash.decode(placement.geoHash as string);


            const el = document.createElement("div");
            el.className = "marker";
            el.innerHTML = "<span><b>•</b></span>";
            el.addEventListener("click", () => {
                setHoveredPlacement(id);
            });
            // const popup = new Popup({offset: 25}).setHTML(`<div style={"borderRadius: 10px"}>
            //     {placement.name}
            // </div>`);

            const marker = new Marker(el)
                .setLngLat([geoHashCode.longitude, geoHashCode.latitude])
                // .setPopup(popup)
                .addTo(map.current);

            currentMarkers.push(marker);
        });

        Object.entries(placementCounts).forEach(([, {count, lat, lng}]) => {
            if (!map.current) return;

            const el = document.createElement("div");
            el.className = "marker";
            el.innerHTML = "<span><b>" + (count) + "</b></span>";


            const marker = new Marker(el)
                .setLngLat([lng, lat])
                .addTo(map.current);


            currentMarkers.push(marker);
        });
        setPlacements(placementListings);
        setPlacementMarkers(currentMarkers);
    };

    useEffect(() => {
        if (!hoveredPlacement) return;
        scrollRefs.current[hoveredPlacement].current.scrollIntoView({behavior: "smooth"});
    }, [hoveredPlacement]);

    useEffect(() => {
        const renderPlacements = async () => {
            if (!placements) return;

            if (!Object.keys(placements).length) {
                setRenderedPlacements(<Typography variant="h6" sx={{opacity: 0.5}}>No placements on this date.</Typography>);
                return;
            }

            scrollRefs.current = Object.fromEntries(Object.keys(placements).map(
                (k, i) => [k, scrollRefs.current[i] ?? createRef()]
            ));

            setRenderedPlacements(Object.entries(placements).map(([key, placement]) => {
                const proposePlacementData:{[key:string]: unknown} = {...placement, id: key};
                delete proposePlacementData.sector;
                delete proposePlacementData.subsector;
                delete proposePlacementData.savedBy;
                delete proposePlacementData.created;
                delete proposePlacementData.oId;
                console.log(placement);

                return (<ListItemButton onClick={() => {
                    setHoveredPlacement(key);
                    console.log("PP", proposePlacementData);
                    // setViewPlacementPopup(proposePlacementData as PlacementListing);
                }} key={key} divider ref={scrollRefs.current[key]} style={{borderLeft: hoveredPlacement === key ? `5px solid ${PRIMARY_COLOUR}` : "none", background: hoveredPlacement === key ? "linear-gradient(90deg, rgba(121,32,245,0.1) 0%, rgba(255,255,255,0) 50%)" : "none"}}>
                    <ListItemText sx={{mr: "130px"}} primary={<Typography><Typography fontWeight={"bold"} display={"inline"}>{placement.name}</Typography> - {placement.title}</Typography>} secondary={<Typography color={"grey"}>{placement["address-line1"]}, {placement["address-line2"] && `${placement["address-line2"]}, `}{placement.postal_code}</Typography>}/>
                    <ListItemSecondaryAction>
                        <Stack spacing={0} alignItems={"flex-end"} justifyContent={"flex-end"}>
                            {/* <Chip color={placement.status as any === "Accepted" ? "primary" : undefined} label={placement.status as any === "Accepted" ? "Verified by provider" : "Not verified"}/> */}
                            {user.userType === "Students" && <IconButtonPop sx={{alignSelf: "flex-end !important"}} responsive={false} title="Create placement" onClick={() => navigate(`/${user.product}/create`, {state: {placement: {...proposePlacementData, placementId: key, id: undefined}}})}><AddCircleOutline color="primary"/></IconButtonPop>}
                        </Stack>
                    </ListItemSecondaryAction>
                </ListItemButton>);
            }));
        };

        renderPlacements();
    }, [placements, hoveredPlacement]);


    return (
        <Box position={"relative"} overflow={"hidden"} flex={1}>
            <Drawer open={drawerOpen}>
                <Box sx={{position: "absolute", left: "-35px", top: fullscreen ? "90px" : "30px", background: "white", borderRadius: "15px 0 0 15px", clipPath: "inset(-5px 5px -10px -10px)"}} boxShadow={3}>
                    <IconButtonPop responsive={false} title="Open" onClick={() => setDrawerOpen((o) => !o)}>
                        <ArrowLeft sx={{transition: "all 250ms ease-in-out", transform: drawerOpen ? "rotate(180deg)" : "none"}}/>
                    </IconButtonPop>
                </Box>
                <CardContent>
                    <Stack height={"100%"}>
                        <Typography variant="h4">{title}</Typography>
                        {secondary}
                        <List sx={{overflowY: "auto"}}>
                            {renderedPlacements}
                        </List>
                    </Stack>
                </CardContent>
            </Drawer>
            <MapContainer fullscreen={fullscreen} ref={mapContainer}/>
            {/* <PlacementListingDetailsPopup open={Boolean(viewPlacementPopup)} onClose={() => setViewPlacementPopup(false)} placement={selectedPlacement}/> */}
        </Box>
    );
}
